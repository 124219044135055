export const outputData = {
  subTasks: [
    {
      aspect: "reception",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.23790345,
              0.5019721,
              0.08052626,
              0.28507406,
              0.053850368,
              0.12692423,
              0.19648525,
              0.18575032,
              0.11735097,
              0.5813959,
              0.030553482,
              0.28590775,
              1.0,
              0.036010236,
              0.8449176,
              0.14426956,
            ],
            lookAt: [
              0.023423394,
              0.039306227,
              0.01194456,
              0.0050548725,
              0.035900656,
              0.056409296,
              0.022268174,
              0.025783943,
              0.012368207,
              0.040944982,
              1.0,
              0.7999009,
              0.21593931,
              0.343528,
              0.5723029,
              0.1938196,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.14828296,
                0.0138246035,
                0.008446756,
                0.06747525,
                0.16844767,
                0.2644775,
                0.0806553,
                0.11805014,
                0.007869905,
                0.36182186,
                0.14905772,
                0.42996287,
                0.2159637,
                0.28228247,
                0.26142484,
                0.24749367,
              ],
            },
            {
              impact: -0.9627155,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.030378262,
                0.1055019,
                0.06390738,
                0.019947873,
                0.113884546,
                0.05794542,
                0.013056362,
                0.13949145,
                0.033085883,
                0.12352899,
                0.41189006,
                0.21866857,
                0.09427566,
                1.0,
                0.17895356,
                0.5310615,
              ],
            },
            {
              impact: 0.51689327,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.017321434,
                0.082024835,
                0.021969251,
                0.029898863,
                2.8409154e-4,
                0.0012305625,
                0.055320993,
                0.04401633,
                0.047432043,
                0.06599315,
                0.08405495,
                0.025821308,
                0.14746761,
                0.12627728,
                0.07243084,
                0.28431627,
              ],
            },
            {
              impact: 0.5143704,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.06641446,
                0.05721217,
                0.09129958,
                0.0076520997,
                0.113862395,
                0.026702031,
                0.0042737867,
                0.017875401,
                0.014141698,
                0.0031700514,
                0.35103908,
                0.065649755,
                0.36071333,
                0.16082394,
                0.01720967,
                0.27046275,
              ],
            },
          ],
          scores: [
            0.7859609127044678,
            0.03511014208197594,
            0.17892888188362122,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.27247292,
              0.5406396,
              0.13075756,
              0.14700457,
              0.1077025,
              0.09073106,
              0.093735285,
              0.10404773,
              1.0,
              0.116567984,
              0.13893512,
              0.06859053,
              0.2104619,
              0.13416085,
              0.39351302,
              0.11317215,
              0.19730063,
              0.30164444,
              0.1232904,
              0.36374027,
              0.27263954,
              0.50695443,
            ],
            lookAt: [
              0.087843716,
              0.10969011,
              0.20140845,
              0.042849872,
              0.5128177,
              0.3191508,
              0.7873177,
              0.49556118,
              0.39871877,
              0.088918306,
              0.0071200915,
              0.029353745,
              0.11893362,
              0.29345003,
              1.0,
              0.35715452,
              0.42785972,
              0.2194124,
              0.20262928,
              0.104848735,
              0.042233795,
              0.38813496,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.08390584,
                0.056575786,
                0.11623331,
                0.037191566,
                0.023456499,
                0.066324696,
                0.012076289,
                0.08617222,
                0.13044296,
                0.06798932,
                0.10951112,
                0.034646843,
                0.07357227,
                0.459661,
                0.40298417,
                0.6427063,
                0.95326555,
                0.10512087,
                0.056143265,
                0.11624711,
                0.23160736,
                0.34886718,
              ],
            },
            {
              impact: -0.75779486,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.032694593,
                0.10011861,
                0.18458568,
                0.058565654,
                0.018454194,
                0.060851686,
                0.010490416,
                0.020537833,
                0.16208401,
                0.2292898,
                0.13406959,
                0.024734182,
                0.073729016,
                0.17340347,
                0.012934103,
                0.019338196,
                0.108208574,
                0.4304734,
                0.006715807,
                0.02220772,
                0.012549074,
                0.01140615,
              ],
            },
            {
              impact: -0.7270441,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.067092545,
                1.0,
                0.20833878,
                0.19163585,
                0.5703802,
                0.97241867,
                0.740421,
                0.2521114,
                0.6710459,
                0.36036614,
                0.22930656,
                0.102305874,
                0.2002076,
                0.15485677,
                0.30005345,
                0.054709423,
                0.43223396,
                0.69117665,
                0.01770951,
                0.040476654,
                0.019027632,
                0.07676935,
              ],
            },
            {
              impact: -0.4353896,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.029678715,
                0.051958345,
                0.08029636,
                0.013264764,
                0.29247478,
                0.56572765,
                0.096875384,
                0.084378794,
                0.06811636,
                0.0410655,
                0.07478339,
                0.0014341469,
                0.032348305,
                0.03006174,
                0.062304243,
                0.008249988,
                0.027612438,
                0.09832484,
                0.018206337,
                0.010035804,
                0.009508611,
                0.03295189,
              ],
            },
            {
              impact: 0.32783327,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.06480079,
                0.04106853,
                0.014271545,
                0.079012446,
                0.11135714,
                0.025221454,
                0.013194277,
                0.023086334,
                0.08590057,
                0.01944575,
                0.03722697,
                0.024976645,
                0.0026448197,
                0.023323093,
                0.0061359787,
                0.001678771,
                0.027068594,
                0.0319903,
                0.009167714,
                0.033134222,
                0.08058118,
                0.04376098,
              ],
            },
            {
              impact: -0.32194433,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.018303515,
                0.015055561,
                0.025910333,
                0.01116209,
                0.027632955,
                0.0037494819,
                0.036747567,
                0.011186089,
                0.013887983,
                0.0798545,
                0.035752486,
                0.015826052,
                0.0287364,
                0.37614316,
                0.88909054,
                0.028265804,
                0.33207208,
                0.027961377,
                0.015985968,
                0.054191347,
                0.059831347,
                0.115992814,
              ],
            },
            {
              impact: 0.2838325,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.08593541,
                0.11356581,
                0.04467134,
                0.029869288,
                0.014133509,
                0.017620703,
                0.024032455,
                0.04563107,
                0.23272154,
                0.028657254,
                0.09044236,
                0.08496414,
                0.23833399,
                0.1791702,
                0.0022173037,
                0.059316766,
                0.024906633,
                0.043107387,
                0.0967567,
                0.013936459,
                0.030904837,
                0.14305037,
              ],
            },
            {
              impact: -0.24511185,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.042887393,
                0.452687,
                0.035828363,
                0.041058782,
                0.428974,
                0.08034954,
                0.2115403,
                0.11683474,
                0.021581892,
                0.026501946,
                0.05441091,
                0.015468097,
                0.031311564,
                0.11493126,
                0.028397301,
                0.015076256,
                0.057746027,
                0.07106976,
                0.012900695,
                0.011404934,
                0.0062163128,
                0.07561355,
              ],
            },
            {
              impact: 0.19794652,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.36975273,
                0.053247035,
                0.016942156,
                0.078831054,
                0.2742299,
                0.08605339,
                0.10315192,
                0.0235074,
                0.15809193,
                0.011013282,
                0.101257205,
                0.009371185,
                0.035617832,
                0.03582649,
                0.122242674,
                0.022292238,
                0.024422608,
                0.07817863,
                0.029125681,
                0.014280733,
                0.017947927,
                0.00935922,
              ],
            },
            {
              impact: 0.18854159,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.016019452,
                0.15981774,
                0.28492525,
                0.10766192,
                0.04851926,
                0.028136957,
                0.029966848,
                0.009454892,
                0.072981216,
                0.031027004,
                0.15981919,
                0.024085442,
                0.012799765,
                0.005861208,
                0.09198196,
                0.013885423,
                0.043066885,
                0.08225972,
                0.018064674,
                0.086456105,
                0.07987072,
                0.026503107,
              ],
            },
          ],
          scores: [
            0.5395088195800781,
            0.43840405344963074,
            0.022087207064032555,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              0.33599642,
              0.7306282,
              0.058682427,
              0.51696306,
              0.21135394,
              0.6029323,
              0.21186437,
              0.09003349,
              0.010402477,
              0.30930126,
              0.23891073,
              0.022669867,
              0.5337794,
              0.10453364,
              0.48933592,
              0.5032024,
              0.04953078,
              1.0,
              0.36762455,
            ],
            lookAt: [
              0.0044352114,
              0.025388345,
              0.06318855,
              0.1264709,
              1.0,
              0.7683347,
              0.072868735,
              0.17031561,
              0.19782637,
              0.008416215,
              0.14259827,
              0.14713009,
              0.1674527,
              0.20110725,
              0.027078798,
              0.30208966,
              0.05849838,
              0.34477496,
              0.21581987,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.035748392,
                0.0033449014,
                0.0599678,
                0.06448475,
                0.053615294,
                0.051901344,
                0.11314404,
                0.055227663,
                1.0,
                0.049193192,
                0.0024453024,
                0.0028498527,
                0.008158002,
                0.009056311,
                0.007862303,
                0.070179984,
                0.020252371,
                0.16881384,
                0.05818919,
              ],
            },
            {
              impact: -0.74955463,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.050656445,
                0.03918595,
                0.024571326,
                0.041441735,
                0.038282234,
                0.605741,
                0.16335098,
                0.029616099,
                0.25114977,
                0.050389495,
                0.020344684,
                0.017582545,
                0.02292623,
                0.0078228805,
                0.011255637,
                0.004367715,
                0.030483218,
                0.001810377,
                0.13338226,
              ],
            },
            {
              impact: -0.72897214,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.0674013,
                0.29493278,
                0.03126523,
                0.50205,
                0.5537138,
                0.25644204,
                0.13385482,
                0.021110894,
                0.007899481,
                0.06717336,
                0.001596086,
                0.0022557718,
                0.02735505,
                0.006251286,
                0.02205715,
                0.0934437,
                0.028412025,
                0.04221234,
                0.17442033,
              ],
            },
            {
              impact: 0.6357611,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.025143927,
                0.27214485,
                0.099537596,
                0.45558694,
                0.26456004,
                0.026910177,
                0.11241658,
                0.045895405,
                0.03326181,
                0.08304084,
                0.026387326,
                0.01030887,
                0.058055803,
                0.011909978,
                0.041904867,
                0.063716196,
                0.008962208,
                0.0071689133,
                0.03853822,
              ],
            },
            {
              impact: 0.31418043,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.0015766172,
                0.084042996,
                0.009016798,
                0.020549258,
                0.016216254,
                0.04824378,
                0.011154894,
                0.29475763,
                0.030101197,
                0.118407354,
                0.021698372,
                0.030465152,
                0.02424606,
                0.0031048907,
                0.0035605116,
                0.2409491,
                0.08879842,
                0.01914048,
                0.05216883,
              ],
            },
            {
              impact: 0.31352854,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.0500714,
                0.12907656,
                0.15807109,
                0.03207537,
                0.01200108,
                0.07453642,
                0.0026700792,
                0.016028563,
                0.0069243563,
                0.01509262,
                0.020708745,
                2.761869e-4,
                0.044715773,
                0.009161011,
                0.019051595,
                0.037894588,
                0.0035928315,
                0.04843033,
                0.009695782,
              ],
            },
            {
              impact: 0.31228656,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.009827328,
                0.0121227335,
                0.022491917,
                0.025986854,
                0.0064952434,
                0.01888561,
                0.089501515,
                0.15624051,
                0.14496084,
                0.028338304,
                0.106039524,
                0.08871282,
                0.041992694,
                0.009218129,
                0.010818137,
                0.0050939303,
                0.025195649,
                0.05241228,
                0.018334504,
              ],
            },
            {
              impact: 0.27040222,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.15900858,
                0.3929579,
                0.43956727,
                0.16229807,
                0.030224215,
                0.52852154,
                0.011991825,
                0.002321012,
                0.004180095,
                0.011818762,
                0.0067574284,
                0.049291506,
                0.099539,
                0.026623683,
                0.088464126,
                0.030959867,
                0.02092243,
                0.030727817,
                0.04061596,
              ],
            },
            {
              impact: 0.20921277,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.028179513,
                0.01805931,
                0.0220602,
                0.02381278,
                0.00224909,
                0.011374793,
                0.037997212,
                0.08083506,
                0.01068662,
                0.011313754,
                0.017583791,
                0.016441865,
                0.018943664,
                0.010757466,
                0.0062854383,
                0.0074774865,
                0.0067751617,
                0.040471014,
                0.010145368,
              ],
            },
          ],
          scores: [0.5360388159751892, 0.12856562435626984, 0.3353955149650574],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.011797256,
              0.29449067,
              0.0022621038,
              0.05968217,
              0.1163982,
              0.045654908,
              0.03307179,
              0.02543655,
              0.2659571,
              0.2292945,
              0.0025521144,
              0.09518092,
              1.0,
              0.09449506,
              0.011259875,
              0.1467961,
              0.04333342,
              0.0138612045,
              0.015871648,
            ],
            lookAt: [
              0.22142902,
              0.32305154,
              0.9193525,
              0.38138676,
              0.18654987,
              0.2679223,
              0.44104648,
              0.16742565,
              0.10346373,
              0.04972238,
              0.030714095,
              0.030023415,
              0.04062509,
              0.319155,
              0.15274362,
              0.4235421,
              0.077460065,
              0.3022592,
              1.0,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.57388496,
                0.52779293,
                0.044838343,
                0.6791695,
                0.74754745,
                0.03763215,
                0.11384407,
                0.02399465,
                0.002670394,
                0.01683546,
                0.017183436,
                0.03204564,
                0.020310575,
                0.3109179,
                0.01633881,
                0.23686726,
                0.04583028,
                0.013005254,
                0.10623263,
              ],
            },
            {
              impact: -0.42949313,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.18395779,
                0.31670007,
                0.020994704,
                0.027127683,
                0.070259854,
                0.26386464,
                0.10846691,
                0.028305503,
                0.10961955,
                0.09974998,
                0.34624675,
                0.021408318,
                0.01505809,
                0.6853584,
                0.023996087,
                0.21671177,
                0.033463467,
                0.0061062784,
                0.0099056205,
              ],
            },
            {
              impact: 0.4256514,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.030310381,
                0.018379685,
                0.012418645,
                0.017014341,
                0.015102052,
                0.02757441,
                0.02323992,
                0.02407334,
                0.0077100857,
                0.01921236,
                0.041347973,
                0.012158155,
                0.018081905,
                0.07265061,
                0.0067819403,
                0.07057083,
                0.006319727,
                0.05779083,
                0.08268873,
              ],
            },
            {
              impact: -0.32785144,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.005444393,
                0.08720302,
                0.14182469,
                0.071867876,
                0.120925464,
                0.04625999,
                0.25930393,
                0.028956557,
                0.07216248,
                0.036548506,
                0.13861409,
                0.009022897,
                0.054999206,
                0.6405899,
                0.23091711,
                0.657575,
                0.25187913,
                0.008483269,
                0.12921576,
              ],
            },
            {
              impact: 0.20916381,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.021636117,
                0.21935274,
                0.0063471626,
                0.019273387,
                0.03176065,
                0.0075992956,
                0.022008566,
                0.15100557,
                0.15856268,
                0.1496942,
                0.13183111,
                0.0298236,
                0.04205381,
                0.032317333,
                7.965574e-4,
                0.007905941,
                0.0023150865,
                0.053683735,
                0.026242139,
              ],
            },
            {
              impact: -0.19223729,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.020713212,
                0.13668425,
                0.10238058,
                0.10727552,
                0.6754455,
                0.22899434,
                0.039189804,
                0.029183831,
                0.02612265,
                0.061219648,
                2.130009e-4,
                0.018199272,
                0.03960723,
                0.032855634,
                0.0013042968,
                0.0016487683,
                0.004143728,
                3.082357e-4,
                0.00974935,
              ],
            },
            {
              impact: 0.1777373,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.007954591,
                0.0922457,
                0.008152878,
                0.016125185,
                0.0030815888,
                0.018987782,
                0.008834294,
                0.010678734,
                0.015487741,
                0.09353844,
                0.035158567,
                0.029008994,
                0.13626833,
                0.023832822,
                0.00675648,
                0.0018321206,
                0.0047020204,
                0.011035811,
                0.017323092,
              ],
            },
            {
              impact: 0.15812019,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.05252532,
                0.07267843,
                0.07476836,
                0.0041677663,
                0.10998761,
                0.0074522975,
                0.05090805,
                0.08660404,
                0.070435986,
                0.09774329,
                0.0910606,
                0.04766973,
                0.010034435,
                0.08968141,
                0.008441263,
                0.03507248,
                0.013735236,
                0.04444652,
                0.027540414,
              ],
            },
          ],
          scores: [0.55202716588974, 0.283583402633667, 0.16438943147659302],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.16598268,
              0.18521386,
              0.16908629,
              0.04167343,
              0.05726628,
              0.19571692,
              0.17246056,
              0.5077518,
              0.22578596,
              0.25582558,
              0.35007277,
              0.23017283,
              0.30610204,
              0.43637702,
              0.13851778,
              0.271975,
              0.2057135,
              0.023167316,
              0.45387417,
              0.25913316,
              0.31351006,
              0.40498108,
              1.0,
            ],
            lookAt: [
              0.17348282,
              0.15056197,
              0.017758919,
              9.550092e-4,
              0.033793993,
              0.01916097,
              0.083014585,
              0.098963305,
              0.10998093,
              0.24360825,
              0.24051666,
              0.6567698,
              0.047751542,
              0.0789245,
              0.031064795,
              0.7143338,
              0.7700187,
              0.08635421,
              0.2963153,
              0.33843276,
              0.19222084,
              0.54030484,
              1.0,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.05354427,
                0.03891697,
                0.012176523,
                0.026676463,
                0.004003275,
                0.029746186,
                0.06523334,
                0.04410169,
                0.033578373,
                0.027609913,
                0.04509527,
                0.051668752,
                0.053117838,
                0.006176575,
                0.045438178,
                0.031330563,
                0.14310578,
                0.012608672,
                0.16066648,
                0.0039200154,
                0.004085935,
                0.30216843,
                0.098558635,
              ],
            },
            {
              impact: 0.9464069,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.016710933,
                0.016083078,
                8.7227876e-4,
                0.008579873,
                0.023715938,
                0.0110759735,
                0.009781124,
                0.018396404,
                0.043367755,
                0.023150545,
                0.027519599,
                0.25397074,
                0.07397031,
                0.015759842,
                0.11304184,
                1.0,
                0.050778117,
                0.15048322,
                0.49792817,
                0.41352767,
                0.15822355,
                0.15137202,
                0.42238012,
              ],
            },
            {
              impact: 0.81321275,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.01413359,
                0.008282066,
                0.0054925676,
                0.0060868515,
                0.0069442927,
                0.005482617,
                0.0043957033,
                0.01556424,
                0.03995036,
                0.03626746,
                0.012182624,
                0.17138614,
                0.017789444,
                0.068736315,
                0.077967,
                0.27012372,
                0.025235007,
                0.11936808,
                0.2719049,
                0.28456628,
                0.037764616,
                0.051835034,
                0.07193213,
              ],
            },
            {
              impact: 0.75276655,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0036746364,
                0.03406222,
                0.005714576,
                0.005513747,
                0.005557957,
                0.0208627,
                0.011577362,
                0.16665082,
                7.0329907e-4,
                0.0122595625,
                0.2349583,
                0.059547752,
                0.062401842,
                0.10729173,
                0.067424394,
                0.023745235,
                0.12246689,
                0.006166873,
                0.046399083,
                0.037845474,
                0.10680566,
                0.10231334,
                0.14507961,
              ],
            },
            {
              impact: 0.7020327,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.04254816,
                0.038633928,
                0.019834934,
                0.006486145,
                0.002459954,
                0.012472905,
                0.016338741,
                0.0065463777,
                0.10866068,
                0.011275565,
                0.072362,
                0.5035157,
                0.08371322,
                0.013749079,
                0.28306067,
                0.05991664,
                0.37686616,
                0.1318695,
                0.46250755,
                0.05857415,
                0.089595005,
                0.11165981,
                0.29984716,
              ],
            },
            {
              impact: 0.45228192,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.013724649,
                0.009662796,
                0.0074276235,
                0.013038883,
                0.009447066,
                0.026222603,
                0.024457531,
                0.02824799,
                0.0036117146,
                0.008670695,
                0.05376792,
                0.1006312,
                0.019704377,
                0.019032707,
                0.013708608,
                0.07969231,
                0.14349595,
                0.03316426,
                0.1433084,
                0.018254267,
                0.24495386,
                0.055369664,
                0.27303025,
              ],
            },
            {
              impact: -0.34466404,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.041949864,
                0.005414214,
                0.016034005,
                0.02458607,
                0.011511217,
                0.013831002,
                0.011245071,
                0.056761384,
                0.24430926,
                0.040412493,
                0.20713474,
                0.07744104,
                0.02607678,
                0.048756544,
                0.08541914,
                0.051103234,
                0.02910627,
                4.4572217e-4,
                0.008964528,
                0.018433457,
                0.01413942,
                0.0073663364,
                0.01990711,
              ],
            },
          ],
          scores: [
            0.08862075209617615,
            0.9006284475326538,
            0.01075085811316967,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.29377154,
              0.09485029,
              0.032728724,
              0.10535765,
              0.08525166,
              0.14926374,
              0.13193168,
              0.33550623,
              0.31873366,
              0.14138512,
              0.07490832,
              0.22886825,
              0.11744919,
              0.333718,
              0.25325254,
              0.26869777,
              0.50929433,
              0.0020330027,
              0.27742428,
              0.19023742,
              0.38220778,
              0.12408323,
              0.014942606,
              1.0,
              0.42942375,
              0.141631,
              0.20905025,
              0.04508577,
              0.06930774,
              0.25908652,
              0.0050044325,
              0.07570995,
              0.17341779,
              0.006815306,
            ],
            lookAt: [
              0.07803721,
              0.029437186,
              0.009709495,
              0.165721,
              0.049736783,
              0.05686664,
              0.34493265,
              0.17985415,
              0.013318502,
              0.059378598,
              0.13192953,
              0.073453166,
              0.14681521,
              1.0,
              0.22824574,
              0.09021659,
              0.0032635462,
              0.080442764,
              0.012444076,
              0.07943336,
              0.06879462,
              6.5576336e-5,
              0.035848286,
              0.024591127,
              0.1285562,
              0.006649502,
              0.06746851,
              0.10927052,
              0.04517372,
              0.07514774,
              0.0529179,
              0.032306705,
              0.023364,
              0.07869169,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.015396259,
                0.012419393,
                0.267459,
                0.14468965,
                0.055330317,
                0.1426796,
                0.25076222,
                0.45163473,
                0.48054525,
                0.45896015,
                0.21519387,
                0.11713575,
                0.46613908,
                0.30612797,
                0.37054396,
                0.044939972,
                0.004425095,
                0.013870095,
                0.07525168,
                0.013705843,
                0.11887429,
                0.06797929,
                0.007534817,
                0.06080245,
                0.13038023,
                0.22314024,
                0.21822876,
                0.349912,
                0.056964464,
                0.2476302,
                0.05513726,
                0.0267379,
                0.06290852,
                0.3156494,
              ],
            },
            {
              impact: -0.41214868,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.046944413,
                0.42039764,
                0.2815587,
                0.081053615,
                0.08727656,
                0.120048285,
                0.12835608,
                0.18501633,
                0.602243,
                0.1757839,
                0.034616604,
                0.09309638,
                0.11652056,
                0.1400216,
                0.005366979,
                0.011493108,
                0.03169699,
                0.02063674,
                0.013041138,
                4.746466e-4,
                0.009821354,
                0.02526173,
                0.008752916,
                0.0122913,
                0.0656079,
                0.03608997,
                0.008370553,
                0.020576233,
                0.018070843,
                0.0036819093,
                0.016019395,
                0.0059832144,
                0.007938633,
                0.0658548,
              ],
            },
            {
              impact: 0.3093076,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.002548035,
                0.011814367,
                0.0033498786,
                0.010410421,
                0.0152592035,
                0.01581189,
                0.0016002026,
                0.0037061046,
                0.031534467,
                0.001166031,
                0.008465345,
                0.006234756,
                0.071557894,
                0.029715635,
                0.046905085,
                0.04037104,
                0.030858437,
                0.0011228696,
                9.93438e-4,
                0.0665883,
                0.06348178,
                0.04207294,
                0.014688824,
                0.076870315,
                0.02974577,
                0.006214298,
                0.11150518,
                0.06627753,
                0.098698154,
                0.14462,
                0.040256787,
                0.0040361006,
                0.0050428146,
                0.013085941,
              ],
            },
            {
              impact: 0.29374465,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.008669782,
                0.01267813,
                0.0048143403,
                0.01143,
                0.00799637,
                5.720839e-4,
                0.0089377565,
                0.0060562105,
                9.118536e-4,
                0.012025971,
                0.00252528,
                0.00690979,
                0.018912528,
                0.020609604,
                0.109030455,
                2.6118523e-4,
                0.011317361,
                0.037385426,
                0.021289175,
                0.053977326,
                0.090162404,
                0.10098879,
                0.03468205,
                0.031414818,
                0.025106158,
                0.036508445,
                0.26882026,
                0.3381901,
                0.031184815,
                0.055164658,
                0.018499546,
                0.1569631,
                0.31452805,
                0.055737082,
              ],
            },
            {
              impact: -0.22634971,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.13794464,
                0.19943118,
                0.13735744,
                0.116575465,
                0.070485815,
                0.012837807,
                0.037851617,
                0.04298077,
                0.04802162,
                0.018475983,
                0.10213588,
                0.052965738,
                0.05204288,
                0.003880839,
                0.025280273,
                0.0037731864,
                0.0054366663,
                0.0058009033,
                0.021885194,
                0.074248865,
                0.027485168,
                0.034801546,
                0.005159932,
                0.15225722,
                0.0240926,
                0.012113268,
                0.089977935,
                0.002555901,
                0.042462505,
                0.09350378,
                0.0071677505,
                0.0036785824,
                0.17955601,
                0.013959872,
              ],
            },
            {
              impact: 0.20841354,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.011402272,
                0.024346326,
                0.0030184519,
                0.02031196,
                0.0011037072,
                0.005874629,
                0.0068892282,
                0.005657934,
                0.014030831,
                0.011203564,
                0.005982003,
                0.028046932,
                0.01724869,
                0.02285238,
                0.021597758,
                0.14886667,
                0.29714093,
                0.0026245862,
                0.023629285,
                8.421063e-4,
                0.052374564,
                0.04882306,
                0.017025372,
                0.0516055,
                0.07937851,
                0.069989234,
                0.068485275,
                0.016991902,
                0.0012346479,
                0.04954166,
                0.0070723905,
                0.0016295969,
                0.026663618,
                0.0013969762,
              ],
            },
            {
              impact: -0.16528085,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0064900876,
                0.068838194,
                0.055205908,
                0.020045344,
                0.047890678,
                0.18560216,
                0.13776001,
                1.0,
                0.20241138,
                0.23154931,
                0.05831472,
                0.013431453,
                0.11840116,
                0.0019286057,
                0.05730945,
                0.0083046025,
                0.015066845,
                0.010424337,
                0.058222458,
                0.018504217,
                0.023780987,
                0.03766445,
                0.014008682,
                0.05675464,
                0.027301112,
                0.009529762,
                0.011535697,
                0.0037896726,
                0.025743708,
                0.027446924,
                0.042170946,
                0.0254889,
                0.01534084,
                0.009397209,
              ],
            },
            {
              impact: 0.16057764,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.010128194,
                0.16254278,
                0.5760593,
                0.48774418,
                0.35978848,
                0.18657456,
                0.018360578,
                0.045822922,
                0.039310943,
                0.061938718,
                0.057576466,
                0.6177079,
                0.12253933,
                0.032286275,
                0.004219343,
                0.007434361,
                2.5090837e-4,
                0.023677088,
                0.019039977,
                0.07688479,
                0.023233175,
                0.006037379,
                0.028339714,
                0.07306405,
                0.013636483,
                0.012837585,
                0.010999386,
                0.01696173,
                0.0195858,
                0.038726814,
                0.11132434,
                0.006898284,
                0.12100644,
                0.0133287,
              ],
            },
            {
              impact: -0.15167819,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.026603172,
                0.008535863,
                0.07650778,
                0.005879148,
                0.091022335,
                0.36252743,
                0.114733525,
                0.17702097,
                0.49091318,
                0.12497035,
                0.0051690843,
                0.018325591,
                0.029366491,
                0.044845223,
                0.03729055,
                0.001819955,
                1.9424815e-4,
                0.04576612,
                0.035614856,
                0.09738312,
                0.06252117,
                0.08740244,
                0.025373733,
                0.12909117,
                0.050696418,
                0.017903425,
                0.022935018,
                0.0016004419,
                0.014823458,
                0.028589945,
                0.013270557,
                0.048499823,
                0.021549998,
                0.011653801,
              ],
            },
            {
              impact: 0.12629168,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.002436836,
                0.04678519,
                0.0029222106,
                3.9253244e-4,
                0.0028658675,
                9.35764e-4,
                5.587967e-5,
                0.0094195595,
                0.0049510757,
                0.00136394,
                0.016995806,
                0.0094418265,
                0.03920832,
                0.0054630735,
                0.01228339,
                0.0034463096,
                0.0024371883,
                0.0063396916,
                0.00818406,
                0.04132697,
                0.025504107,
                0.02840618,
                0.020464895,
                0.052288476,
                0.06390938,
                0.0049405806,
                0.006236018,
                0.006557035,
                0.01256126,
                0.0029763132,
                0.029435415,
                0.0054644356,
                0.07072982,
                0.016651215,
              ],
            },
            {
              impact: 0.12448423,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.012021482,
                0.020414831,
                0.021301627,
                0.0010593319,
                0.0015313621,
                0.00495876,
                5.715445e-4,
                0.011144885,
                0.008388229,
                0.0042520994,
                0.003650494,
                0.006203143,
                0.0046767094,
                0.041073274,
                0.2622561,
                5.6970597e-4,
                0.033474807,
                0.024989009,
                0.023686474,
                0.017092971,
                0.018425515,
                0.0021790895,
                0.0036829005,
                0.01975729,
                0.0063942987,
                0.00936759,
                0.008976194,
                0.013225555,
                0.025242064,
                0.040042546,
                0.019499186,
                0.004292455,
                0.014102399,
                0.024084833,
              ],
            },
            {
              impact: -0.11696317,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.018835455,
                0.20931089,
                0.2722996,
                0.45525888,
                0.05828819,
                0.16519152,
                0.15451586,
                0.110087045,
                0.06491256,
                0.086180516,
                0.008533175,
                0.029734123,
                0.0507607,
                0.007998487,
                0.019379448,
                0.009541273,
                0.004805204,
                0.0021449449,
                0.03380983,
                0.010388758,
                0.021651577,
                0.026655704,
                0.002237942,
                0.020766595,
                0.022569735,
                0.0041578845,
                0.015373921,
                0.009168717,
                0.0015962508,
                0.007234445,
                0.005552952,
                0.0021531861,
                0.0038010937,
                0.02083762,
              ],
            },
            {
              impact: 0.093318105,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0024466254,
                0.0050537894,
                0.0049717296,
                0.0059975954,
                0.0038922813,
                0.014500227,
                0.0065199193,
                0.0029632417,
                0.0074267546,
                0.0012212751,
                0.0038053202,
                0.0044229208,
                0.00501517,
                0.003251891,
                0.01246946,
                0.0062191286,
                0.008037159,
                0.0102049345,
                0.0030350664,
                0.0013734625,
                0.018057335,
                0.014696951,
                0.012981381,
                0.0010368138,
                0.022909403,
                0.010469217,
                0.043797355,
                0.012994332,
                0.053640973,
                0.25965786,
                0.40461904,
                0.008183321,
                0.19244592,
                0.058022756,
              ],
            },
          ],
          scores: [
            0.5952849984169006,
            0.10172378271818161,
            0.30299124121665955,
          ],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "reception",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              0.30480334,
              0.010698859,
              0.15342575,
              0.026917005,
              0.1037188,
              0.28253543,
              0.11262749,
              0.009502421,
              0.012253457,
              0.039742555,
              0.032754835,
              0.06888647,
              0.056842294,
              0.28891313,
              1.0,
              0.16560632,
              0.020436231,
              0.11643005,
              0.054692965,
              0.23980662,
            ],
            lookAt: [
              0.56025547,
              0.40053183,
              0.16368271,
              0.38536876,
              0.030164696,
              0.29910186,
              0.036707323,
              0.11500498,
              0.15168586,
              0.040615022,
              0.047715228,
              0.020482987,
              0.121423006,
              0.0031324883,
              0.07183845,
              0.12005047,
              0.024828847,
              0.02454343,
              0.15094906,
              1.0,
            ],
          },
          aspectTokens: ["reception"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.4344598,
                0.12880076,
                0.1365745,
                0.07376333,
                0.07722706,
                0.20634875,
                0.043794747,
                0.13931097,
                0.14883569,
                0.0813004,
                0.18240209,
                0.13442129,
                0.32577398,
                0.17537805,
                0.38221115,
                0.20328547,
                0.12683365,
                0.2081765,
                0.14234012,
                1.0,
              ],
            },
            {
              impact: 0.4181175,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.060487427,
                0.11639136,
                0.12460243,
                0.09022905,
                0.21489108,
                0.16261795,
                0.014885572,
                0.17063817,
                0.18876995,
                0.015051084,
                0.03246711,
                0.0072787334,
                0.018536944,
                0.026488265,
                0.02495055,
                0.0074252035,
                0.002094178,
                0.005837201,
                4.393148e-4,
                0.008520199,
              ],
            },
            {
              impact: 0.38745558,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.12397543,
                0.17041533,
                0.107432365,
                0.5795731,
                0.02341632,
                0.08091517,
                0.0035984144,
                0.078800686,
                0.020164035,
                0.001200417,
                0.017042037,
                5.3490413e-4,
                0.0267047,
                4.6272518e-4,
                0.029937176,
                0.009535938,
                0.008163868,
                0.016229007,
                0.010154764,
                0.01136653,
              ],
            },
            {
              impact: 0.3107731,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.24743898,
                0.0062631983,
                0.15274322,
                0.3411391,
                0.07727329,
                0.045349993,
                0.0018322401,
                0.007436381,
                0.004730286,
                0.017509153,
                0.0057193367,
                0.006476696,
                0.00804018,
                0.0044194637,
                0.02345148,
                0.012320807,
                0.0130381975,
                0.035366505,
                0.029460205,
                0.016328515,
              ],
            },
            {
              impact: 0.20970108,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.30311212,
                0.20680961,
                0.13033912,
                0.076983504,
                0.053036693,
                0.007332157,
                0.008091864,
                0.022994151,
                0.0033376901,
                0.016904332,
                0.006736129,
                0.010728785,
                0.0055463635,
                0.0020051713,
                0.055530563,
                0.0013671303,
                0.011057675,
                0.007552605,
                0.009870832,
                0.034803666,
              ],
            },
            {
              impact: -0.1947467,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.56851065,
                0.10013591,
                0.19949564,
                0.33279866,
                0.107850075,
                0.31512645,
                0.052089524,
                0.1680926,
                0.07764028,
                0.06074288,
                0.123135395,
                0.021162385,
                0.01571563,
                0.0087236045,
                0.04894594,
                0.050022744,
                0.011705823,
                0.0056425305,
                0.006656969,
                0.01573758,
              ],
            },
            {
              impact: -0.17757252,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.018103797,
                0.028173165,
                0.02183745,
                0.027769094,
                0.025594378,
                0.004803524,
                0.038194634,
                0.016348818,
                0.14099751,
                0.053035982,
                0.36840728,
                0.10777877,
                0.39332393,
                0.2436613,
                0.2666904,
                0.10009878,
                0.18609932,
                0.028246468,
                0.072270274,
                0.30963376,
              ],
            },
          ],
          scores: [0.3739188611507416, 0.09898161888122559, 0.5270995497703552],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "reception",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.35504578315017765, 0.4068450826406371, 0.23810913420918522],
      sentiment: "negative",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
    {
      aspect: "bed",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.1688407,
              0.012956342,
              0.2867714,
              0.19111139,
              0.2545857,
              0.068394296,
              0.02752478,
              0.4739958,
              0.04782507,
              0.017742988,
              1.0,
              0.43977734,
              0.6517812,
              0.17614883,
              0.57390857,
              0.32042685,
            ],
            lookAt: [
              0.019555002,
              0.026762083,
              0.07956696,
              0.01743817,
              0.074138686,
              0.042900715,
              0.045806803,
              0.0022766266,
              0.0028231796,
              0.05801754,
              1.0,
              0.4070503,
              4.2598502e-4,
              5.82538e-4,
              0.14075443,
              0.09109693,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.06875453,
                0.10840499,
                0.19350268,
                0.16588749,
                0.39260584,
                0.30940154,
                0.20427611,
                0.2020094,
                0.114317685,
                0.62999403,
                0.112447284,
                0.49242628,
                0.27887294,
                0.26511154,
                0.6150251,
                0.72156763,
              ],
            },
            {
              impact: 0.22808413,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.03552281,
                0.06647374,
                0.12436347,
                0.020043716,
                0.17233594,
                0.0939863,
                0.06732413,
                0.07829355,
                0.089210056,
                0.12997672,
                0.0074032843,
                0.011680326,
                0.050819803,
                0.08470509,
                0.20356455,
                0.65429896,
              ],
            },
            {
              impact: -0.16030923,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.03293297,
                0.03684682,
                0.037998937,
                0.021301407,
                0.018802715,
                0.0036822017,
                0.009576223,
                0.0013804476,
                0.0629031,
                0.049652636,
                0.41952327,
                0.22211762,
                0.16149618,
                0.24795616,
                0.48899126,
                0.03792489,
              ],
            },
            {
              impact: 0.12367789,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.20492342,
                0.012414509,
                0.21218728,
                0.16902967,
                0.10489976,
                0.419102,
                0.12538606,
                0.11037364,
                0.048900258,
                0.30299458,
                0.10279517,
                0.06252716,
                0.044116594,
                0.09414876,
                0.02071174,
                0.10295825,
              ],
            },
          ],
          scores: [
            0.8241392970085144,
            0.05768682062625885,
            0.11817389726638794,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.24354681,
              0.08425796,
              0.019597499,
              0.43888897,
              0.18104573,
              0.045888435,
              0.21249218,
              0.17301597,
              1.0,
              0.093308404,
              0.7411026,
              0.18677099,
              0.33862868,
              0.27957994,
              0.096182205,
              0.070978366,
              0.3579246,
              0.09174207,
              0.06697408,
              0.515208,
              0.5364227,
              0.5740638,
            ],
            lookAt: [
              0.07358991,
              0.19576085,
              0.02457584,
              0.32116115,
              0.25675273,
              5.0845253e-4,
              0.5244273,
              0.14760548,
              1.0,
              0.017611686,
              0.23136276,
              0.100482985,
              0.08866695,
              0.20152582,
              0.0898883,
              0.16752799,
              0.36614007,
              0.35530496,
              0.07653822,
              0.14259729,
              0.22836083,
              0.10711322,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.031509895,
                0.92519283,
                0.40513086,
                0.23775625,
                0.34707874,
                0.524336,
                0.47545347,
                0.20035787,
                0.7226402,
                0.37081102,
                0.14396933,
                0.031294577,
                0.32026586,
                0.15492843,
                0.22744675,
                0.041725427,
                0.30280864,
                0.58434653,
                0.02512418,
                0.03591274,
                0.030923214,
                0.045441613,
              ],
            },
            {
              impact: -0.42905447,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.050328325,
                0.08764548,
                0.1687418,
                0.06422697,
                0.024486195,
                0.15831989,
                0.06361548,
                0.011798983,
                0.33458298,
                0.116181456,
                0.06947472,
                0.039781645,
                0.025476227,
                0.012717162,
                0.015498665,
                0.00216964,
                0.015778484,
                0.14350826,
                0.009284963,
                0.03445312,
                0.005058992,
                0.09899395,
              ],
            },
            {
              impact: -0.4093159,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.09294187,
                0.0442005,
                0.055725485,
                0.033090264,
                0.023081407,
                0.015558255,
                0.04747653,
                0.06223319,
                0.19270204,
                0.07746585,
                0.07344608,
                0.026644664,
                0.13506483,
                0.08501357,
                0.17628363,
                0.49988025,
                1.0,
                0.09217383,
                0.03147487,
                0.05396407,
                0.07974422,
                0.2715952,
              ],
            },
            {
              impact: -0.18975198,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.028670397,
                0.0776696,
                0.08097331,
                0.038406864,
                0.16258915,
                0.2677504,
                0.08115099,
                0.08898619,
                0.010942105,
                0.0045245956,
                0.0099555375,
                0.039596714,
                0.0053638034,
                0.032783624,
                0.010598839,
                0.0028374738,
                0.008767189,
                0.04791714,
                0.0058070756,
                0.0010670569,
                0.008523838,
                0.06978098,
              ],
            },
            {
              impact: 0.17847931,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.010804109,
                0.01673343,
                0.032368578,
                0.029500378,
                0.06401163,
                0.06376026,
                0.08396817,
                0.011715385,
                0.440498,
                0.082462706,
                0.06511166,
                0.069077134,
                0.1518004,
                0.025581941,
                0.060577102,
                0.012746128,
                0.058105625,
                0.016811486,
                0.04186616,
                0.01654914,
                0.012826465,
                0.04388541,
              ],
            },
            {
              impact: 0.17713258,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.1532371,
                0.26886335,
                0.16865453,
                0.057855025,
                0.13072594,
                0.0042064334,
                0.08890708,
                0.018073183,
                0.060338896,
                0.020301064,
                0.12165172,
                0.015626617,
                0.0045167906,
                0.004952081,
                0.017850004,
                0.012605487,
                0.008462297,
                0.12525181,
                0.020099971,
                0.058009226,
                0.035174016,
                0.085494936,
              ],
            },
            {
              impact: -0.17292097,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.026408464,
                0.02387872,
                0.02734629,
                0.02108003,
                0.0093120625,
                0.0022987728,
                0.01583695,
                0.0044868714,
                0.027812757,
                0.023086878,
                0.032029614,
                0.012354982,
                0.0064630387,
                0.2590591,
                0.5196373,
                0.016145233,
                0.3661015,
                0.03127399,
                0.014543418,
                0.0031267088,
                0.030358238,
                0.13333091,
              ],
            },
            {
              impact: 0.14369258,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.011199749,
                0.01721086,
                0.025643563,
                0.013930255,
                0.008664288,
                4.7194396e-4,
                0.0049046273,
                0.056394495,
                0.27098557,
                0.07718241,
                0.36798152,
                0.16380922,
                0.07015431,
                0.07080267,
                0.054744843,
                0.005263378,
                0.01341956,
                0.017846925,
                0.010796758,
                0.01286221,
                0.03588468,
                0.13634247,
              ],
            },
            {
              impact: -0.14139104,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.01616834,
                0.28195617,
                0.033929642,
                0.0033998683,
                0.35089394,
                0.030901888,
                0.17168097,
                0.10812443,
                0.11274663,
                0.01934048,
                0.015700106,
                0.019123461,
                0.025609108,
                0.07278918,
                0.0017053066,
                0.012169895,
                0.013773362,
                0.04305228,
                0.009353374,
                0.008368239,
                0.0018768715,
                0.057849284,
              ],
            },
          ],
          scores: [
            0.6802158355712891,
            0.29480111598968506,
            0.02498304471373558,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              0.3255932,
              1.0,
              0.09096098,
              0.04457138,
              0.09400243,
              0.11321601,
              0.11492665,
              0.10183885,
              0.044524893,
              0.054325808,
              0.3297405,
              0.11619349,
              0.23585902,
              0.12969334,
              0.34086725,
              0.30516788,
              0.06302547,
              0.025495593,
              0.7723146,
            ],
            lookAt: [
              0.23240048,
              0.71930975,
              0.059839632,
              0.25736466,
              0.3288099,
              0.56235486,
              0.020579787,
              0.22190265,
              0.12601857,
              0.069956504,
              0.3085471,
              0.6312907,
              0.79587555,
              0.6692548,
              0.30855426,
              1.0,
              0.1030624,
              0.31983644,
              0.117964305,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.1582144,
                0.017199589,
                0.17933635,
                0.07676971,
                0.0494285,
                0.4079268,
                0.27712083,
                0.6030113,
                0.3099329,
                0.26452532,
                0.14023483,
                0.06348782,
                0.09991697,
                0.038299054,
                0.053957466,
                0.23736191,
                0.07304868,
                0.16294415,
                0.12811713,
              ],
            },
            {
              impact: 0.7739401,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.14155048,
                0.21358676,
                0.20721842,
                0.08992274,
                0.01637606,
                0.28666994,
                0.043253962,
                0.049943004,
                0.009274143,
                0.069138475,
                0.036279634,
                0.0068631195,
                0.07139184,
                0.006855064,
                0.006856121,
                0.12306097,
                0.008958833,
                0.012805322,
                0.0636707,
              ],
            },
            {
              impact: 0.610139,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.27118018,
                0.31128898,
                0.22233354,
                0.14468959,
                0.09891206,
                0.8021294,
                0.029086547,
                0.0035764116,
                0.011466577,
                0.04165004,
                0.05997108,
                0.034429673,
                0.15660928,
                0.032486726,
                0.09903973,
                0.031401966,
                0.031648304,
                0.0833274,
                0.021794407,
              ],
            },
            {
              impact: -0.598337,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.02206119,
                0.0043236404,
                0.028866373,
                0.06174283,
                0.015308757,
                2.2298921e-4,
                0.16019814,
                8.5371174e-4,
                0.355061,
                0.024345566,
                0.061079063,
                0.0035472475,
                0.0016285125,
                0.0034094357,
                0.025490226,
                0.04007752,
                0.07908386,
                0.16070034,
                0.0062468327,
              ],
            },
            {
              impact: 0.39989805,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.009193927,
                0.16459589,
                0.02328482,
                0.006809781,
                0.014790981,
                0.036711,
                0.027090933,
                0.013995731,
                0.009182888,
                0.016438445,
                0.18106216,
                8.8247005e-4,
                0.102938496,
                0.0048100944,
                0.07338512,
                0.0017714474,
                0.008772712,
                0.0047396473,
                0.023846427,
              ],
            },
            {
              impact: -0.3069745,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.051472075,
                0.7219474,
                0.048655022,
                0.35969785,
                0.40802956,
                0.16990367,
                0.023360522,
                0.006887558,
                0.0018301154,
                0.053555824,
                0.010375183,
                0.004801966,
                0.016182343,
                0.004553389,
                0.023802834,
                0.048636295,
                0.025770536,
                0.018441489,
                0.059125897,
              ],
            },
            {
              impact: 0.3002579,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.034928184,
                0.014702113,
                0.04804431,
                0.055241052,
                0.009756887,
                0.00370804,
                0.040894218,
                0.16642015,
                0.011134529,
                0.0066991914,
                0.017352944,
                0.009871089,
                0.011014744,
                0.005681868,
                0.004284508,
                0.018564489,
                0.026676998,
                0.012791526,
                0.0034583916,
              ],
            },
            {
              impact: 0.29249936,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.0013002632,
                0.17955245,
                0.02720601,
                0.0028690945,
                0.0044471584,
                0.03374326,
                0.025208337,
                0.0022194602,
                0.014135257,
                0.06850021,
                0.086873434,
                0.010200969,
                0.05004426,
                0.0045259492,
                0.09750595,
                0.008432988,
                0.0010896411,
                0.0032715986,
                0.012173252,
              ],
            },
            {
              impact: 0.28060648,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.022335414,
                0.0029137547,
                0.002251132,
                0.011541914,
                0.0056744576,
                0.0074781487,
                0.026718082,
                0.24056663,
                0.035170436,
                0.3307401,
                0.1520961,
                0.014920705,
                0.22055359,
                0.029274173,
                0.11881021,
                0.0672058,
                6.903955e-4,
                0.03373762,
                0.02881517,
              ],
            },
          ],
          scores: [0.70008385181427, 0.1565588116645813, 0.1433572918176651],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.33124176,
              1.0,
              0.011088765,
              0.26401925,
              0.3701715,
              0.0778822,
              0.0015301955,
              0.0921259,
              0.8474873,
              0.7230029,
              0.03283017,
              0.29607365,
              0.36618918,
              0.10558994,
              0.06376142,
              0.09371984,
              0.2883522,
              0.10875823,
              0.4091882,
            ],
            lookAt: [
              0.01168482,
              0.67412037,
              0.056698937,
              0.0055222847,
              0.0070171724,
              0.00358274,
              0.0315171,
              0.07258078,
              0.43304276,
              1.0,
              0.0017175425,
              0.013783875,
              0.031099461,
              0.024596678,
              0.010215178,
              0.051188126,
              0.03285407,
              0.030736191,
              0.37033123,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.07786758,
                0.10849948,
                0.02787939,
                0.03750808,
                0.07745753,
                0.0705352,
                0.31379682,
                0.19755706,
                0.45188752,
                1.0,
                0.21977012,
                0.024916675,
                0.08718918,
                0.19763416,
                0.009324623,
                0.1914093,
                0.119800374,
                0.0152471755,
                0.04441565,
              ],
            },
            {
              impact: 0.651435,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0053021666,
                0.026078857,
                0.037449244,
                0.0019252447,
                0.077356495,
                0.008779073,
                0.035933096,
                0.064421065,
                0.1403082,
                0.5827499,
                0.062063396,
                0.020480484,
                0.050588403,
                0.43654996,
                0.18477593,
                0.36874583,
                0.08927579,
                0.11099861,
                0.16763864,
              ],
            },
            {
              impact: 0.37762454,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.030092604,
                0.08917233,
                0.109407514,
                0.020124014,
                0.2353582,
                0.0091207195,
                0.04644193,
                0.03426611,
                0.03186907,
                0.16888864,
                0.041427303,
                0.04118905,
                0.040299673,
                0.58592147,
                0.0040536914,
                0.10792852,
                0.039693143,
                0.01790473,
                0.0021593964,
              ],
            },
            {
              impact: 0.3206391,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0010841069,
                0.0014380126,
                0.0133507205,
                0.0019377898,
                0.04548136,
                7.1663596e-4,
                0.052497815,
                0.049822554,
                0.104134604,
                0.27821508,
                0.040512614,
                0.011866153,
                0.058099404,
                0.013649151,
                0.048557654,
                0.04310041,
                0.021642778,
                0.1445464,
                0.055237047,
              ],
            },
            {
              impact: -0.10903082,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.008909684,
                0.016148707,
                0.0012638198,
                7.997904e-4,
                0.016620615,
                0.00384884,
                0.14449385,
                0.0087712305,
                0.0085457545,
                0.06377179,
                0.022802627,
                0.012822709,
                0.01929354,
                0.014508727,
                4.4889335e-4,
                0.01942323,
                0.009046374,
                0.06081077,
                0.003549697,
              ],
            },
          ],
          scores: [
            0.37566468119621277,
            0.5877495408058167,
            0.03658577427268028,
          ],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.0681711,
              0.017846767,
              0.029893829,
              8.0904487e-4,
              0.005936776,
              0.014248073,
              0.007970954,
              0.009399442,
              0.023677101,
              0.011785222,
              0.038776886,
              0.0056437817,
              0.056107953,
              0.95460653,
              1.0,
              0.0052712783,
              0.04038833,
              0.0010374079,
              0.033444207,
              0.12186879,
              0.02207643,
              0.03259591,
              0.05680724,
            ],
            lookAt: [
              0.010997307,
              0.029264746,
              0.050803334,
              0.0047920183,
              0.0061833635,
              0.012878074,
              0.011639404,
              0.05806194,
              0.015519025,
              0.008516527,
              0.022440163,
              0.041740164,
              0.13105325,
              0.32389128,
              1.0,
              0.15827486,
              0.15455525,
              0.022742594,
              0.15534809,
              0.031441107,
              0.037094958,
              0.005683382,
              0.014370865,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.008471805,
                0.0029625995,
                0.0010887546,
                0.013352876,
                0.0153756,
                0.016176805,
                0.018763388,
                0.007737839,
                0.02301822,
                0.013771437,
                0.028980114,
                0.07946981,
                0.063896105,
                0.10343674,
                0.51198643,
                0.18410568,
                0.27149913,
                0.097104564,
                0.10752476,
                0.108245365,
                0.100948386,
                0.10310687,
                0.18614763,
              ],
            },
            {
              impact: 0.9422379,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.040592093,
                9.1779884e-4,
                0.008432491,
                0.014336296,
                0.0031950139,
                0.006652991,
                0.0055873357,
                0.16536517,
                0.011954751,
                0.011203193,
                0.066664524,
                0.13451527,
                0.22286296,
                0.0122738015,
                0.99011374,
                0.1484016,
                0.018055422,
                9.993121e-4,
                0.0023700309,
                0.107946776,
                0.046914786,
                0.06212001,
                0.12279281,
              ],
            },
            {
              impact: 0.9256064,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.02882598,
                0.005489338,
                0.0024930649,
                0.01753206,
                0.0018837538,
                0.017321609,
                0.010729273,
                0.003495506,
                0.04660481,
                0.030135946,
                0.04551068,
                0.27766046,
                0.16242088,
                0.10457288,
                1.0,
                0.2097926,
                0.07383924,
                0.06037592,
                0.23854,
                0.16565752,
                0.042827774,
                0.079275824,
                0.15282485,
              ],
            },
            {
              impact: 0.91489154,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.01413537,
                0.0015309395,
                0.011689198,
                0.00653314,
                0.004721161,
                0.008404392,
                0.018376531,
                0.05374868,
                0.020765357,
                0.0137138935,
                0.015794754,
                0.19697885,
                0.044187866,
                0.10515255,
                0.8540077,
                0.3648119,
                0.081296586,
                0.072531976,
                0.0021579636,
                0.15252209,
                0.050172813,
                0.09412335,
                0.116712645,
              ],
            },
            {
              impact: 0.6849177,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.02445812,
                0.02898022,
                0.002163939,
                0.021722598,
                0.0045552324,
                0.0131112505,
                0.031053305,
                0.013395042,
                0.002416565,
                0.005963411,
                0.016685627,
                0.043616734,
                0.09147356,
                0.0017317411,
                0.07831174,
                0.07212155,
                0.013883731,
                0.0022283196,
                0.01775179,
                0.020014001,
                0.026214179,
                0.14783728,
                0.027215032,
              ],
            },
            {
              impact: 0.34659788,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0077715404,
                0.01401451,
                0.005146861,
                0.011111646,
                0.010626215,
                0.018261028,
                0.020537589,
                0.028044812,
                0.0032093355,
                2.3420333e-4,
                0.042043246,
                0.017244874,
                0.013073272,
                0.0054481435,
                0.12611036,
                0.023990732,
                0.043921314,
                0.010785682,
                0.030472893,
                1.9836142e-4,
                0.17766482,
                0.06352116,
                0.25041384,
              ],
            },
          ],
          scores: [
            0.051211800426244736,
            0.9427804946899414,
            0.006007669027894735,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.1348612,
              0.451317,
              0.12261937,
              0.02486745,
              0.030569896,
              0.03894771,
              0.0806114,
              0.042811763,
              0.03272872,
              0.027457802,
              0.11578716,
              0.04498706,
              0.1074895,
              0.22794883,
              0.02999237,
              0.0858457,
              0.11787426,
              0.104461625,
              0.08378031,
              0.57664746,
              0.050190847,
              0.018429002,
              0.07747732,
              0.010820394,
              0.09120363,
              0.15194336,
              1.0,
              0.04399617,
              0.18118738,
              0.06230037,
              0.0034116404,
              0.0038080614,
              0.27559483,
              0.52708954,
            ],
            lookAt: [
              0.80541915,
              0.27703518,
              0.25202626,
              0.257092,
              0.10803351,
              0.049337912,
              0.3710642,
              0.04968318,
              0.20161593,
              0.09910392,
              0.092949435,
              0.02704212,
              0.11913334,
              1.0,
              0.1314369,
              0.14977035,
              0.015794972,
              0.063292064,
              0.014936945,
              0.037305925,
              0.0025375907,
              0.019198498,
              5.511967e-4,
              0.015299142,
              0.20089637,
              0.03195336,
              0.44622254,
              0.11735259,
              0.0053361584,
              0.0024208685,
              0.18008447,
              0.076824464,
              0.5223694,
              0.778102,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.06554179,
                0.030083342,
                0.010274526,
                0.016947227,
                0.018782625,
                0.064528696,
                5.0406106e-4,
                0.029934693,
                0.007021849,
                0.005039015,
                0.008987919,
                0.0036249093,
                0.037078194,
                0.017193152,
                0.09642381,
                3.1124838e-4,
                0.0049421405,
                0.019289616,
                0.01025013,
                0.014703216,
                0.0035340083,
                0.0048411614,
                0.0052633802,
                0.0015205473,
                0.1114183,
                0.01069566,
                0.037900597,
                0.04734711,
                0.060153935,
                0.042318147,
                0.21090123,
                0.03318136,
                0.030237924,
                0.1077624,
              ],
            },
            {
              impact: -0.7214572,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0047368496,
                0.049993187,
                0.013632491,
                0.029462967,
                0.020976666,
                0.010204355,
                0.011888113,
                0.024079138,
                7.648519e-4,
                0.022358088,
                0.024685033,
                0.10239397,
                0.15802425,
                0.00443354,
                0.0234486,
                0.006544534,
                0.02466235,
                0.027043981,
                0.06253278,
                0.09464519,
                0.053273756,
                0.012957418,
                0.024911162,
                0.033680737,
                0.064066686,
                0.03929243,
                0.14187388,
                0.0025793822,
                0.12405189,
                0.21795698,
                0.1354366,
                0.42355844,
                0.5147439,
                0.43746585,
              ],
            },
            {
              impact: -0.66720283,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.04672636,
                0.030609965,
                0.21041416,
                0.21274753,
                0.028541047,
                0.19647597,
                0.28113505,
                0.3778303,
                0.4125287,
                0.31634024,
                0.201962,
                0.23177102,
                0.25032005,
                0.6532679,
                0.35209715,
                0.10821684,
                0.08047484,
                0.080866545,
                0.007328952,
                0.040880065,
                0.12160675,
                0.08834643,
                0.033700295,
                0.1086016,
                0.24541587,
                0.24112765,
                0.18563828,
                0.28514752,
                0.10936395,
                0.21912551,
                0.042619996,
                0.020543832,
                0.06381732,
                0.38156107,
              ],
            },
            {
              impact: -0.5718934,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.09163155,
                0.6197308,
                0.42354506,
                0.045406062,
                0.05927972,
                0.107571185,
                0.13418894,
                0.12372528,
                0.33545434,
                0.047040902,
                0.030997917,
                0.11627569,
                0.04907443,
                0.07113512,
                0.0033636151,
                0.029944343,
                0.023174979,
                0.035743736,
                0.010041482,
                0.014793638,
                0.016677227,
                0.031915534,
                0.016735941,
                0.0031082209,
                0.07063195,
                0.043267358,
                0.0068738014,
                0.044980075,
                0.033303507,
                0.0073343627,
                0.022071682,
                0.004017677,
                0.015686957,
                0.08359124,
              ],
            },
            {
              impact: -0.56603897,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.40367112,
                0.5432988,
                0.40788683,
                0.09133652,
                0.022583542,
                0.082826234,
                0.07531358,
                0.1193579,
                0.022760956,
                0.04207895,
                0.24864528,
                0.14661507,
                0.018296333,
                0.17650597,
                0.066065334,
                0.02279808,
                0.0032781363,
                0.07826401,
                0.02065117,
                0.027332637,
                0.018542131,
                0.00949113,
                0.027574785,
                0.029022045,
                0.015718436,
                0.0032973632,
                0.098129325,
                0.07882727,
                0.010656575,
                0.066756554,
                0.002716621,
                0.0034378502,
                0.28059593,
                0.12696834,
              ],
            },
            {
              impact: 0.46632195,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.006534391,
                0.019518364,
                0.011483275,
                0.010855582,
                0.0049692057,
                0.0054636584,
                0.016490348,
                0.013510952,
                0.005673837,
                0.017040493,
                0.0024915568,
                0.0066041173,
                0.012398805,
                0.024350476,
                0.16082828,
                0.010740529,
                0.0490573,
                0.040182576,
                0.017851647,
                0.06686915,
                0.16905211,
                0.16387297,
                0.044803526,
                0.015225854,
                0.045055404,
                0.036740802,
                0.5162379,
                0.33827353,
                0.051146742,
                0.18368717,
                0.5336261,
                0.6022932,
                0.16988152,
                0.08668472,
              ],
            },
            {
              impact: 0.4200565,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0044810153,
                0.041380282,
                0.013699943,
                0.014808368,
                0.038929246,
                0.010722993,
                0.0017517385,
                0.0060450984,
                0.02130203,
                0.015044661,
                0.0063043246,
                0.01870715,
                0.10948563,
                0.04272935,
                0.039273225,
                0.030943919,
                0.008201003,
                0.002476622,
                0.0010507266,
                0.124621116,
                0.013016961,
                0.040865034,
                0.01789373,
                0.0785415,
                0.047626276,
                0.036700014,
                0.13046674,
                0.094673425,
                0.11318746,
                0.007864958,
                0.07542796,
                0.0067903837,
                0.12733907,
                0.005745352,
              ],
            },
            {
              impact: -0.35240248,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.036792196,
                0.39627287,
                0.19166641,
                0.12640506,
                0.09558236,
                0.08939885,
                0.07219736,
                0.013857525,
                0.036115345,
                0.039071906,
                0.105987504,
                0.0682224,
                0.09998556,
                0.04284212,
                0.056476403,
                0.008771124,
                0.009596148,
                0.025502443,
                0.016936993,
                0.1646191,
                0.034216892,
                0.017504543,
                0.020386519,
                0.22590558,
                0.051529966,
                0.011296813,
                0.16364995,
                0.0069036223,
                0.05780101,
                0.088141955,
                0.04883471,
                0.017455295,
                0.30756995,
                0.019353744,
              ],
            },
            {
              impact: -0.32768062,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.15945345,
                0.29430175,
                0.08710702,
                0.69299626,
                0.14726721,
                0.032215223,
                0.076624006,
                0.080011465,
                0.17239928,
                0.073541775,
                0.0028305096,
                0.04183894,
                0.051872764,
                0.066586256,
                0.016451055,
                0.0021573745,
                0.02258752,
                0.018481372,
                0.009981259,
                0.0042772577,
                0.0051079835,
                0.006617477,
                0.0041119675,
                0.005002702,
                8.693126e-4,
                0.02229462,
                0.050702624,
                0.014245877,
                0.048765842,
                0.03188821,
                0.020336896,
                0.0052178134,
                0.0155551275,
                0.03526145,
              ],
            },
            {
              impact: 0.26310563,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.009236548,
                6.918506e-4,
                0.007209968,
                0.0028430787,
                0.0026208612,
                0.021535518,
                0.005514752,
                0.008113587,
                0.002223627,
                0.0126162525,
                0.009909176,
                0.009267186,
                0.008586044,
                0.016544385,
                0.037469834,
                0.008083617,
                0.0027131755,
                0.035430767,
                0.007138457,
                2.0028309e-4,
                0.05448156,
                0.03345868,
                0.024447937,
                0.005827704,
                0.035906516,
                0.043271173,
                0.10344367,
                0.05184701,
                0.10328931,
                0.6828979,
                0.5142917,
                0.2267377,
                0.5011597,
                0.057438657,
              ],
            },
            {
              impact: 0.18503703,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.006572395,
                0.024437523,
                0.0017827002,
                0.029831748,
                0.0077019855,
                0.018594937,
                0.0112631945,
                0.019657915,
                0.0076834634,
                0.017519824,
                0.0015061351,
                0.023217078,
                0.0065260846,
                0.15784732,
                0.040135834,
                0.0844496,
                0.23271765,
                0.0065519237,
                0.02842024,
                0.015159304,
                0.043556467,
                0.035014063,
                0.0131199,
                0.057858367,
                0.063834086,
                0.051769976,
                0.088447124,
                0.010625185,
                0.01634353,
                0.025662065,
                0.015038796,
                0.011145751,
                0.014053979,
                0.03992666,
              ],
            },
            {
              impact: -0.15165788,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.02260094,
                0.10247566,
                0.031411696,
                0.013689914,
                0.002216746,
                0.16713905,
                0.11843504,
                1.0,
                0.13300265,
                0.070875436,
                0.04313026,
                0.007734429,
                0.12102142,
                0.0077242507,
                0.028274303,
                0.008224102,
                0.03489262,
                0.009941068,
                0.041368373,
                0.04324399,
                0.0014429113,
                0.02996263,
                0.01310507,
                0.021644209,
                0.018186029,
                0.008777077,
                0.01630376,
                0.010548303,
                0.01711319,
                0.02725517,
                0.032399323,
                0.019388374,
                0.015935585,
                0.009812021,
              ],
            },
          ],
          scores: [
            0.46152883768081665,
            0.15355545282363892,
            0.38491570949554443,
          ],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "bed",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              0.5248709,
              0.046794172,
              0.102462076,
              0.11297386,
              0.1976025,
              0.42053148,
              0.0049625635,
              0.18418671,
              0.12705332,
              8.4156904e-4,
              5.8506173e-4,
              0.09348389,
              0.040540464,
              0.31059375,
              1.0,
              0.06784592,
              0.092986725,
              0.1315474,
              0.33653462,
              0.6634097,
            ],
            lookAt: [
              0.190438,
              0.12496696,
              0.12675142,
              0.14840306,
              0.034973014,
              0.48885632,
              0.054780275,
              0.057794895,
              0.1215127,
              0.016721584,
              0.005763641,
              0.0061307508,
              0.06752271,
              0.054602824,
              0.22763285,
              0.061774366,
              0.014811242,
              0.00826543,
              0.040260438,
              1.0,
            ],
          },
          aspectTokens: ["bed"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.37019473,
                0.14402325,
                0.10655877,
                0.055321235,
                0.07206373,
                0.18439764,
                0.01380773,
                0.11508223,
                0.13626193,
                0.06912121,
                0.18978743,
                0.16064048,
                0.41464227,
                0.1791174,
                0.35564744,
                0.48766547,
                0.111388594,
                0.2763948,
                0.23452902,
                1.0,
              ],
            },
            {
              impact: 0.7218814,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.17488654,
                0.24471623,
                0.18781884,
                0.10506443,
                0.33968192,
                0.2995585,
                0.07966282,
                0.082645334,
                0.24490741,
                0.04339499,
                0.03997539,
                0.011762737,
                0.034889217,
                0.03411844,
                0.05601543,
                0.0057261284,
                4.7763335e-4,
                0.009678,
                0.010274782,
                0.026561165,
              ],
            },
            {
              impact: 0.4172331,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.14170898,
                0.13415824,
                0.1909154,
                0.8092046,
                0.091810666,
                0.09831559,
                0.0047566295,
                0.073998265,
                0.028845897,
                0.001069334,
                0.01268261,
                0.0031766347,
                0.022026423,
                4.527611e-4,
                0.016937142,
                0.015805159,
                0.009532965,
                0.014387434,
                0.0074291048,
                0.021104155,
              ],
            },
            {
              impact: -0.38853487,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.019699683,
                0.031472676,
                0.029879333,
                0.048826158,
                0.045329463,
                0.0012112156,
                0.04163427,
                0.036240242,
                0.34320357,
                0.09459471,
                0.5167982,
                0.15794376,
                0.56014556,
                0.37209386,
                0.4688086,
                0.25963354,
                0.20330505,
                0.027037378,
                0.06630799,
                0.41600296,
              ],
            },
            {
              impact: 0.37205887,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.0051473347,
                0.2522145,
                0.039890986,
                0.12211851,
                0.22401996,
                0.05870016,
                0.08099365,
                0.08551837,
                0.020752529,
                0.06935922,
                0.046603955,
                0.010268542,
                0.017772634,
                0.0071440116,
                0.014771566,
                0.017034894,
                8.1536477e-4,
                0.0016367444,
                0.00540503,
                0.17218393,
              ],
            },
            {
              impact: 0.3638914,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.3570384,
                0.03469098,
                0.1470138,
                0.3005936,
                0.17137897,
                0.068491295,
                0.0020182857,
                0.011112608,
                0.020363105,
                0.02418255,
                0.00825049,
                0.0049461094,
                0.01771051,
                0.005239151,
                0.048328694,
                0.015623133,
                0.018262357,
                0.036062572,
                0.026313469,
                0.015985752,
              ],
            },
            {
              impact: -0.19909397,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.035640266,
                0.07257878,
                0.0062726066,
                0.01941168,
                0.034779485,
                0.063771516,
                0.003954919,
                0.05054808,
                0.02547887,
                0.0731615,
                0.028165672,
                0.018079104,
                0.029171783,
                0.07474047,
                0.012248326,
                0.11319844,
                0.76678914,
                0.052969154,
                0.12448413,
                0.8420966,
              ],
            },
          ],
          scores: [
            0.30296480655670166,
            0.09020868688821793,
            0.6068264842033386,
          ],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "bed",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.3471892954083397, 0.3971698678418256, 0.2556408367498347],
      sentiment: "negative",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
    {
      aspect: "service",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.5634129,
              0.026589794,
              0.02085644,
              0.39155993,
              0.057623573,
              0.54539424,
              0.14008111,
              0.02943062,
              0.18807201,
              0.3676793,
              0.7132503,
              1.0,
              0.3816269,
              0.616774,
              0.7431962,
              0.9728466,
            ],
            lookAt: [
              0.14411947,
              0.051148936,
              0.07629191,
              0.021049006,
              0.07983105,
              0.0591,
              0.031813942,
              0.075226836,
              0.08217482,
              0.09392434,
              0.90219647,
              0.5338556,
              0.007789337,
              0.07005092,
              0.1794199,
              1.0,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.0022753505,
                0.023340156,
                0.0037465773,
                0.0016126217,
                0.006352624,
                0.05184571,
                0.045501895,
                0.020697506,
                2.3785743e-4,
                0.014416898,
                0.6638625,
                0.21507485,
                0.80060315,
                0.1286063,
                0.47744122,
                0.37187496,
              ],
            },
            {
              impact: 0.9853532,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.09998323,
                0.0031533989,
                0.0048130136,
                0.04510904,
                0.02928938,
                0.033483557,
                0.02654613,
                0.013390671,
                0.03198141,
                0.022385597,
                0.22096418,
                0.121257074,
                0.085856915,
                0.018692413,
                0.12213801,
                0.24075481,
              ],
            },
            {
              impact: -0.6475607,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.013058246,
                0.08577842,
                0.16366622,
                0.012282678,
                0.28953555,
                0.04127666,
                0.13024485,
                0.13238086,
                0.03593691,
                0.06602854,
                0.9358197,
                0.05840534,
                0.06922469,
                0.27061653,
                0.29978728,
                0.49600992,
              ],
            },
            {
              impact: -0.52889353,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.014150467,
                0.055240627,
                0.2694475,
                0.15949607,
                0.72720516,
                0.48105913,
                0.13797908,
                0.091883585,
                0.04089346,
                0.573818,
                0.36918342,
                0.59241825,
                0.08626706,
                0.16546775,
                0.54380125,
                0.22185563,
              ],
            },
          ],
          scores: [
            0.23976761102676392,
            0.04504711180925369,
            0.7151852250099182,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.071929716,
              0.41074476,
              0.1924602,
              0.24423298,
              0.304122,
              0.5942464,
              1.0,
              0.8682366,
              0.28294,
              0.1619378,
              0.0119501855,
              0.034217577,
              0.10204415,
              0.33430088,
              0.53371596,
              0.297888,
              0.04300873,
              0.25874767,
              0.21431488,
              0.24171259,
              0.10498435,
              0.74526435,
            ],
            lookAt: [
              0.02227763,
              0.021568036,
              0.15732612,
              0.0030425582,
              0.2610295,
              0.3622029,
              0.77603656,
              0.3261447,
              0.35529268,
              0.071572386,
              0.03275086,
              0.0034761203,
              0.024469145,
              0.22236975,
              1.0,
              0.31329438,
              0.2855584,
              0.10111642,
              0.1480314,
              0.094254486,
              0.07118852,
              0.6933516,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.052451074,
                0.066587865,
                0.12158179,
                0.055729605,
                0.010590051,
                0.06999281,
                0.045780525,
                0.103045315,
                0.050178967,
                0.11656232,
                0.16719837,
                0.04542501,
                0.05947451,
                0.44607782,
                0.48993134,
                0.8199468,
                0.44031453,
                0.35604823,
                0.0826103,
                0.10927438,
                0.34002972,
                0.22005753,
              ],
            },
            {
              impact: -0.31187016,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.05027945,
                0.15758659,
                0.022693986,
                9.817578e-4,
                0.19238585,
                0.2990444,
                0.2129585,
                0.11420593,
                0.4728975,
                0.05962958,
                0.12562747,
                0.050644267,
                0.044095725,
                0.0916319,
                0.10564998,
                0.01819623,
                0.086710416,
                0.25541374,
                0.0040578623,
                0.02940983,
                0.0018212494,
                7.954167e-5,
              ],
            },
            {
              impact: -0.29649672,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.08030817,
                0.05793049,
                0.040337585,
                0.109673455,
                0.11200739,
                0.015323586,
                0.010094158,
                0.01661934,
                0.051669285,
                0.0013984827,
                0.054540426,
                0.03358424,
                0.013489901,
                0.029030347,
                0.011553268,
                0.0012597766,
                0.025723746,
                0.06282864,
                0.011183251,
                0.02749237,
                0.0637263,
                0.026285736,
              ],
            },
            {
              impact: -0.26040474,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.08670242,
                0.007656986,
                0.03906139,
                0.18474765,
                0.08191194,
                0.051377844,
                0.06066158,
                0.078375965,
                0.48464185,
                0.20734458,
                0.54900795,
                0.08269069,
                0.0687422,
                0.005720581,
                0.8944514,
                0.42738903,
                0.06776873,
                0.24334002,
                0.5205287,
                0.69377,
                0.14384021,
                0.12074073,
              ],
            },
            {
              impact: -0.21369928,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.101571046,
                0.9004845,
                0.33635136,
                0.2881,
                0.45546135,
                1.0,
                0.79604834,
                0.21400839,
                0.39294073,
                0.3433055,
                0.2288436,
                0.10034894,
                0.30287918,
                0.104943976,
                0.25592494,
                0.046056885,
                0.33692372,
                0.5348855,
                0.012652804,
                0.039079472,
                0.020646986,
                0.117375344,
              ],
            },
            {
              impact: -0.19521917,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.2962287,
                0.040040314,
                0.007995508,
                0.055057246,
                0.37380496,
                0.05583739,
                0.08402137,
                0.0262841,
                0.08203344,
                6.6872075e-4,
                0.07852605,
                0.018262632,
                0.007059304,
                0.03812894,
                0.10922928,
                0.010083726,
                0.016470095,
                0.07773978,
                0.018726293,
                0.022340741,
                0.012868036,
                0.05106613,
              ],
            },
            {
              impact: 0.18213001,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.02660001,
                0.003707767,
                0.024061436,
                0.012755417,
                0.0337408,
                0.0122859655,
                0.046414427,
                0.009231299,
                0.008447292,
                0.13133438,
                0.04178469,
                0.013718139,
                0.050277565,
                0.35131922,
                0.82479584,
                0.051234804,
                0.25194666,
                0.03667545,
                0.014967244,
                0.066182144,
                0.08609045,
                0.08909945,
              ],
            },
            {
              impact: 0.15297137,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.01572301,
                0.007201304,
                0.012309082,
                0.03445745,
                0.06682769,
                0.020255033,
                0.020561267,
                0.042576175,
                0.09394392,
                0.009548594,
                0.0744585,
                0.03802993,
                0.053941283,
                0.058841404,
                0.06627231,
                0.03925661,
                0.02086209,
                0.043227997,
                0.05015108,
                0.030728258,
                0.033477094,
                0.2625802,
              ],
            },
            {
              impact: -0.13282505,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.028266076,
                0.23056313,
                0.22890334,
                0.081511274,
                0.21166185,
                0.0460517,
                0.04949254,
                0.007789165,
                0.11269753,
                0.03265035,
                0.068971254,
                0.012521877,
                0.03423499,
                0.003122003,
                0.058950968,
                0.0067945295,
                0.011112644,
                0.15586571,
                0.015529843,
                0.058156714,
                0.038971838,
                0.04010982,
              ],
            },
            {
              impact: -0.12211533,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0473991,
                0.0061907256,
                0.04720823,
                0.032014303,
                0.014033208,
                0.04103832,
                0.16969363,
                0.20545979,
                0.10005523,
                0.1568731,
                0.064381264,
                0.15177588,
                0.048834823,
                0.026761048,
                0.025683714,
                0.025612338,
                0.13120084,
                0.109751515,
                0.021602474,
                0.0790407,
                0.03768698,
                0.050418563,
              ],
            },
          ],
          scores: [0.445535272359848, 0.5334346294403076, 0.0210301261395216],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              0.12386084,
              0.5550648,
              0.32717934,
              0.016256677,
              1.0,
              0.268489,
              0.15058555,
              0.36813742,
              0.18551996,
              0.21535704,
              0.077166036,
              0.47771493,
              0.28059557,
              0.10328364,
              0.25631905,
              0.12580456,
              0.25483474,
              0.34348434,
              0.3411364,
            ],
            lookAt: [
              0.04894805,
              1.0,
              0.10458752,
              0.0056152656,
              0.76030046,
              0.38588732,
              0.106848925,
              0.5862086,
              0.09289784,
              0.029426226,
              0.057416245,
              0.080454774,
              0.10115874,
              0.15379626,
              0.012170782,
              0.26119715,
              0.110582836,
              0.28457028,
              0.19877958,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.064924754,
                0.32244393,
                0.011647981,
                0.48913175,
                0.08987127,
                0.09191919,
                0.11005859,
                0.070608415,
                0.009187993,
                0.23534061,
                0.0036965504,
                0.022003826,
                0.08667094,
                0.00427195,
                0.0598331,
                0.08120377,
                0.00912471,
                0.004620259,
                0.07865263,
              ],
            },
            {
              impact: 0.5057356,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.021432426,
                0.74732155,
                0.029999916,
                0.42132878,
                0.2168548,
                0.21548578,
                0.18821116,
                0.0039114696,
                0.029672835,
                0.00515776,
                0.013379721,
                0.003107801,
                0.026799524,
                0.012346116,
                0.023262434,
                0.027366074,
                0.0041481555,
                0.013747842,
                0.18084602,
              ],
            },
            {
              impact: 0.2935179,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.018903868,
                0.01996362,
                0.0062328014,
                0.017915545,
                0.004683929,
                0.0073174066,
                0.00598452,
                0.005705556,
                0.0077381143,
                0.011896027,
                0.004013732,
                0.0064256266,
                0.0076916567,
                0.0022826013,
                0.008345567,
                0.00886722,
                0.014960886,
                0.029171174,
                0.045439787,
              ],
            },
            {
              impact: -0.26074573,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                7.0106566e-5,
                0.025064183,
                0.0040378077,
                0.01614366,
                0.012499317,
                0.06250706,
                0.009463478,
                0.36300167,
                0.02564815,
                0.01624705,
                0.07191414,
                0.023553258,
                0.025612362,
                5.635734e-4,
                0.0069455197,
                0.2066314,
                0.16844073,
                0.03624854,
                0.005800236,
              ],
            },
            {
              impact: -0.21543583,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.06858325,
                0.007803178,
                0.08195587,
                0.006321972,
                0.05833776,
                0.29698926,
                0.037538342,
                0.56026244,
                0.0017543274,
                0.1100592,
                0.12311854,
                0.01215887,
                0.027115349,
                0.018411634,
                0.022451833,
                0.16508068,
                0.016080623,
                0.11389012,
                0.0383694,
              ],
            },
            {
              impact: 0.1848095,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.05095874,
                0.011016588,
                0.033082295,
                0.018198974,
                0.054733977,
                0.048589095,
                0.02264595,
                0.051678818,
                1.0,
                0.17250289,
                0.10744035,
                2.4662344e-4,
                0.0023041214,
                0.008669514,
                0.035837784,
                0.10302192,
                0.010274168,
                0.01928876,
                0.028122481,
              ],
            },
            {
              impact: -0.15609153,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.14523353,
                0.42508453,
                0.35285085,
                0.13501717,
                0.047210053,
                0.27495226,
                0.012606702,
                2.1906792e-4,
                0.0021002756,
                0.01634602,
                0.015622195,
                0.022551669,
                0.039428238,
                0.012156023,
                0.03144539,
                0.006091811,
                0.01894248,
                0.019118918,
                0.023351058,
              ],
            },
            {
              impact: -0.14445287,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.019398436,
                0.005650447,
                0.037560575,
                0.004642979,
                0.008739677,
                0.006069749,
                0.027450994,
                0.09104716,
                0.005238241,
                0.03600887,
                0.049329333,
                0.0377862,
                0.0367368,
                0.041805122,
                0.03149045,
                0.077024214,
                0.014912333,
                0.16447467,
                0.0032262735,
              ],
            },
          ],
          scores: [0.3802831172943115, 0.12737922370433807, 0.4923376142978668],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.39893588,
              0.29392216,
              1.0,
              0.17217483,
              0.0016382622,
              0.4447472,
              0.012346723,
              0.12610923,
              0.4030168,
              0.09722895,
              0.02088472,
              0.3500856,
              0.30441177,
              0.3723383,
              0.13578385,
              0.058163837,
              0.12140633,
              0.21421215,
              0.5098761,
            ],
            lookAt: [
              0.090121806,
              0.24909978,
              1.0,
              0.10505897,
              0.084286235,
              0.018974993,
              0.013420325,
              0.0070766704,
              0.05787075,
              0.11885381,
              0.023338212,
              0.007059878,
              0.03434959,
              0.058438227,
              0.01661759,
              0.0019294514,
              0.027717888,
              0.05115125,
              0.06528919,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.006239922,
                0.09116857,
                0.16057421,
                0.040539887,
                0.33577436,
                0.014091302,
                0.42856354,
                0.22744232,
                0.1640315,
                0.18886141,
                0.053754535,
                0.010189577,
                0.14523841,
                0.8576983,
                0.039367873,
                0.015804738,
                0.005437906,
                0.0019220058,
                0.059348445,
              ],
            },
            {
              impact: -0.8944874,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                2.6074768e-4,
                0.011345565,
                0.010218961,
                0.0258652,
                0.024399389,
                0.046498768,
                0.1785996,
                0.21462442,
                0.015060989,
                0.0149527285,
                0.05900426,
                0.026841149,
                0.1490998,
                0.6391087,
                0.17839684,
                0.47755963,
                0.11151273,
                0.3383769,
                0.4204766,
              ],
            },
            {
              impact: 0.7964614,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.20249514,
                0.5354903,
                0.08553522,
                1.0,
                0.7092908,
                0.019576369,
                0.14803353,
                0.03239517,
                0.017678738,
                0.027139505,
                0.018625539,
                0.025687542,
                0.009650848,
                0.25596663,
                0.0075220517,
                0.16432847,
                0.014276627,
                0.008864651,
                0.1689283,
              ],
            },
            {
              impact: -0.68365085,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.019447768,
                0.022808213,
                0.097876556,
                0.04863188,
                0.07296658,
                0.044382293,
                0.053236313,
                0.022060473,
                0.0074126166,
                0.0124071995,
                0.13639098,
                0.03575335,
                0.05920967,
                0.0860794,
                0.05661625,
                0.07062728,
                0.3057107,
                0.19920722,
                0.18339433,
              ],
            },
            {
              impact: 0.49150515,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0374515,
                0.011708334,
                0.006645405,
                0.016843934,
                0.006081464,
                0.022429317,
                0.013044511,
                0.030159876,
                0.02831559,
                0.02752082,
                0.0024245116,
                0.014552107,
                0.03932156,
                0.019195594,
                0.015794078,
                0.004321782,
                0.0016541998,
                0.06986579,
                0.1006101,
              ],
            },
            {
              impact: -0.47812322,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.10260834,
                0.29536438,
                0.12904792,
                0.0211029,
                0.024439007,
                0.2986494,
                0.05645984,
                0.028877357,
                0.05592809,
                0.06641805,
                0.02656698,
                0.015977306,
                0.022511167,
                0.022912221,
                0.018910209,
                0.08260357,
                0.010145239,
                0.016317645,
                0.112016186,
              ],
            },
            {
              impact: -0.26905602,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.024893574,
                0.018871797,
                0.037003398,
                0.18088096,
                0.04906303,
                0.31242815,
                0.055239327,
                0.023497373,
                0.0019966802,
                0.014789621,
                0.009613287,
                0.0031159827,
                0.0015554413,
                0.0042963796,
                0.017111456,
                0.03145235,
                0.0017207523,
                0.0017244924,
                0.047874138,
              ],
            },
          ],
          scores: [0.2868799567222595, 0.3511311113834381, 0.36198890209198],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.23502116,
              0.0642583,
              0.45761514,
              0.30523142,
              0.049385097,
              0.3943335,
              0.42427337,
              0.23864497,
              0.0032755358,
              0.13336784,
              0.39846227,
              0.0747724,
              0.178689,
              0.62991697,
              0.054103736,
              0.23134741,
              0.18617968,
              0.031952247,
              0.7104125,
              0.18791103,
              0.3108705,
              0.46474034,
              1.0,
            ],
            lookAt: [
              0.10719484,
              0.07091167,
              0.011345852,
              0.011932138,
              0.014260883,
              0.009179691,
              0.11992752,
              0.1315192,
              0.021949071,
              0.033498272,
              0.05838503,
              0.74721533,
              0.16408521,
              0.110837884,
              0.17393601,
              1.0,
              0.6072577,
              0.024290895,
              0.18190885,
              0.3396476,
              0.1230518,
              0.4496778,
              0.11271182,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.052532133,
                0.03359089,
                0.011726615,
                0.0071682897,
                0.002867471,
                0.023075627,
                0.046092205,
                0.07969516,
                0.03762989,
                0.052502524,
                0.036587723,
                0.17033239,
                0.07432078,
                0.011333762,
                0.006459666,
                0.22438686,
                0.33207986,
                0.020674571,
                0.31488645,
                0.033911347,
                0.1484786,
                0.060009144,
                8.5748796e-4,
              ],
            },
            {
              impact: 0.7772715,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.036794063,
                0.03274006,
                0.018629983,
                0.0030412434,
                0.0024597032,
                0.017037991,
                0.013360572,
                0.057620496,
                0.09549643,
                0.017865034,
                0.059310187,
                0.45910427,
                0.018126974,
                0.009059877,
                0.19221994,
                0.016237758,
                0.36390224,
                0.11191116,
                0.39951432,
                0.1557519,
                0.048412673,
                0.051599443,
                0.12898839,
              ],
            },
            {
              impact: 0.74177516,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0058691693,
                0.0101025,
                0.004128977,
                0.0044401004,
                0.019158222,
                0.006675664,
                6.967803e-4,
                0.022808056,
                0.05104963,
                0.025802795,
                0.015150336,
                0.22292805,
                0.074859045,
                0.038723838,
                0.19488145,
                1.0,
                0.12508303,
                0.14433862,
                0.4097984,
                0.47307557,
                0.10461659,
                0.08484339,
                0.2714941,
              ],
            },
            {
              impact: 0.70842576,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.011695248,
                0.02463343,
                0.003116366,
                0.0062917997,
                0.008609919,
                0.016535318,
                0.07063391,
                0.019575063,
                0.001489355,
                0.004628424,
                0.2438792,
                0.020692104,
                0.056150228,
                0.056238316,
                0.059390306,
                0.21249913,
                0.081948526,
                0.003757434,
                0.06357024,
                0.030905677,
                0.075571306,
                0.06464952,
                0.038302504,
              ],
            },
            {
              impact: 0.6809354,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.004985037,
                0.005705815,
                0.0019851236,
                0.0050180038,
                0.0057084984,
                0.0022928372,
                4.3710298e-4,
                0.0024953636,
                0.0325602,
                0.021495601,
                0.019692153,
                0.07788714,
                0.022132078,
                0.039296553,
                0.029050339,
                0.30332908,
                0.04490954,
                0.10602423,
                0.2671352,
                0.40138665,
                0.012024585,
                0.022249352,
                0.09653152,
              ],
            },
          ],
          scores: [
            0.05016413331031799,
            0.9342261552810669,
            0.01560976728796959,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.7424883,
              0.6816915,
              0.4559992,
              0.7781261,
              0.2612346,
              0.070324875,
              0.052479994,
              0.19641587,
              0.2816575,
              0.30850157,
              0.37907165,
              0.50429946,
              0.6068754,
              0.2370121,
              0.26592663,
              0.28972045,
              0.037143875,
              0.0765386,
              0.77569246,
              0.11421857,
              0.023843838,
              0.047057927,
              0.019519119,
              0.1327003,
              0.12405985,
              0.04979019,
              0.37742865,
              0.2522508,
              0.27155027,
              0.6549044,
              0.34814188,
              0.0065279813,
              0.25390124,
              1.0,
            ],
            lookAt: [
              0.19618031,
              0.004856001,
              0.14576003,
              0.06500568,
              0.076527536,
              0.12661442,
              0.34282437,
              0.2933641,
              0.030996768,
              0.056171764,
              0.013923448,
              0.005028729,
              0.07212083,
              1.0,
              0.23599531,
              0.16200995,
              0.025724579,
              0.047284883,
              0.008715598,
              0.021308247,
              0.0170223,
              0.0036119567,
              0.009425317,
              0.036263302,
              0.029062636,
              0.0067135873,
              0.08008106,
              0.07955653,
              0.043605182,
              0.039843246,
              0.032903787,
              0.018179908,
              0.09352974,
              0.28950015,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.051810343,
                0.032803014,
                0.3221222,
                0.18428467,
                0.21460354,
                0.107107244,
                0.16701654,
                0.3407522,
                0.41480753,
                0.874405,
                0.35404116,
                0.13044877,
                0.7939237,
                0.45957118,
                0.537333,
                0.0109215565,
                0.069632016,
                0.10427207,
                0.18489802,
                0.15433253,
                0.12800947,
                0.037064485,
                0.029932657,
                0.013817582,
                0.096358754,
                0.12325349,
                0.37401602,
                0.25646195,
                0.09090962,
                0.31458917,
                0.08319116,
                7.495964e-4,
                0.04715079,
                0.4494792,
              ],
            },
            {
              impact: -0.5308476,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.04422705,
                0.057797335,
                0.023006044,
                0.01059338,
                0.004518927,
                0.009646318,
                0.013882787,
                0.010427063,
                0.009531681,
                0.010363535,
                0.018647434,
                0.034132686,
                0.0050819456,
                0.18121776,
                0.029877905,
                0.6191473,
                0.6315921,
                0.066969,
                0.004537219,
                0.06612537,
                0.023906296,
                0.07945345,
                0.026508939,
                0.07776775,
                0.18699232,
                0.22180942,
                0.14307836,
                0.0046321047,
                0.01154609,
                0.078017205,
                0.022825936,
                0.00755767,
                0.070601225,
                0.048921593,
              ],
            },
            {
              impact: 0.5016451,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.038294274,
                0.010779764,
                0.011039577,
                0.005867884,
                0.010529795,
                0.044230487,
                0.010960576,
                0.011642219,
                0.005734895,
                0.0067769885,
                0.010718212,
                0.0047509656,
                0.0011207853,
                0.030733386,
                0.09729397,
                0.003368425,
                0.0030952818,
                0.008094173,
                0.007863367,
                0.023339864,
                0.010598844,
                0.0046519614,
                0.004523944,
                4.3075814e-4,
                0.0011662709,
                0.009416147,
                0.0048172865,
                0.031042272,
                0.029269017,
                0.010878581,
                0.09935883,
                0.02121449,
                0.0918218,
                0.033417515,
              ],
            },
            {
              impact: -0.27485785,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.056781977,
                0.17131828,
                0.102339506,
                0.16523732,
                0.07717872,
                0.028081888,
                0.042312738,
                0.089293025,
                0.06437078,
                0.074694306,
                0.0064624255,
                0.1200559,
                0.017915053,
                0.15999356,
                0.052468278,
                0.01520978,
                0.0022698506,
                0.050915662,
                0.0030741263,
                0.001978736,
                0.012465434,
                0.0035143336,
                0.009635867,
                0.010982545,
                0.020939192,
                0.006359181,
                0.040675666,
                0.009437779,
                0.079312295,
                0.027947051,
                0.019646386,
                7.4790814e-4,
                0.011155414,
                0.03609332,
              ],
            },
            {
              impact: -0.26071543,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.04319867,
                0.046138287,
                0.047670025,
                0.0029077549,
                0.0061460775,
                0.01141884,
                0.012061768,
                0.012168228,
                0.007546001,
                0.0072188317,
                0.0046816524,
                0.0111828195,
                0.0031776284,
                0.14062625,
                0.43809885,
                0.021305986,
                0.046963964,
                0.042354323,
                0.031307515,
                0.02646826,
                0.0067235674,
                0.0031407496,
                0.0050064917,
                0.03874692,
                0.013138181,
                0.04389247,
                0.0017602361,
                0.017959343,
                0.051061697,
                0.053562324,
                0.024650192,
                0.0040001934,
                0.032511584,
                0.061754595,
              ],
            },
            {
              impact: -0.2474312,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.010714657,
                0.028310198,
                0.0037126525,
                0.0135431485,
                0.0075527974,
                0.0023093622,
                0.0028118752,
                0.005428924,
                0.0037377663,
                0.013208975,
                0.007099185,
                0.013079662,
                0.020780941,
                0.0092571275,
                0.09083438,
                5.0813716e-4,
                0.008945522,
                0.040212892,
                0.0024526883,
                0.07601855,
                0.08742522,
                0.078264326,
                0.032625724,
                0.058053844,
                0.02960946,
                0.061386913,
                0.25271377,
                0.2634143,
                0.054027297,
                0.038807947,
                0.002251908,
                0.10059397,
                0.20267531,
                0.05358028,
              ],
            },
            {
              impact: -0.22591774,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0022096152,
                0.03201606,
                0.07253275,
                0.0025764613,
                0.0016755782,
                0.003956268,
                0.013142898,
                0.0202249,
                0.0727789,
                0.015185237,
                3.767962e-4,
                0.009777697,
                0.007066993,
                0.15865897,
                0.07273629,
                0.005392874,
                0.020092925,
                3.6812748e-4,
                0.0013727035,
                6.04518e-4,
                0.04386524,
                0.010562373,
                0.009783088,
                0.014801316,
                0.0052369335,
                0.09199548,
                0.041711923,
                0.021321883,
                0.11881163,
                0.4988853,
                0.024006221,
                0.019452553,
                0.024039615,
                0.03165789,
              ],
            },
            {
              impact: -0.19161476,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.003982033,
                0.04945576,
                0.01298684,
                0.009164669,
                0.029376728,
                0.013320134,
                2.7323727e-4,
                0.015969424,
                0.046383496,
                0.41977638,
                0.16468282,
                0.03136081,
                0.17427443,
                0.03034298,
                0.007598768,
                4.4639688e-4,
                0.0017694953,
                0.007268761,
                0.00797796,
                0.0040362426,
                0.007929582,
                0.028791705,
                0.008584503,
                0.01911103,
                0.0051688044,
                0.0025515775,
                0.009391557,
                0.00442589,
                0.020476382,
                0.0017437456,
                0.011165731,
                0.0014024668,
                0.006556487,
                0.011603388,
              ],
            },
            {
              impact: 0.16744281,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0017964509,
                0.1251224,
                0.009545138,
                0.15108402,
                0.09348482,
                0.24751608,
                0.08389547,
                0.20160319,
                0.7291969,
                0.0016894045,
                0.07027108,
                0.13789985,
                0.14560708,
                0.06891376,
                0.0068176403,
                0.0040227445,
                0.045174975,
                0.006095631,
                0.018486563,
                0.014608425,
                0.012671438,
                0.01775786,
                0.0010277556,
                0.025567736,
                0.04370238,
                0.0075773117,
                0.022880545,
                0.005500564,
                0.007647593,
                0.0055585606,
                0.02482999,
                0.009355114,
                0.053351264,
                0.02743424,
              ],
            },
            {
              impact: -0.16640072,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.01960532,
                0.1367419,
                0.33090135,
                0.3181552,
                0.30679777,
                0.17910834,
                0.042131342,
                0.081607066,
                0.035610136,
                0.038705036,
                0.043998234,
                0.35758322,
                0.10600115,
                0.020997824,
                0.00924603,
                6.9107866e-4,
                0.0057297326,
                0.008307511,
                0.039444704,
                0.028252335,
                0.0058851037,
                0.025516847,
                0.011288319,
                0.025369456,
                0.020717282,
                0.003752107,
                0.00831499,
                0.014770536,
                0.022997957,
                0.0189759,
                0.06475685,
                0.0021881752,
                0.065273866,
                0.034343638,
              ],
            },
            {
              impact: 0.16511138,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.025941968,
                0.037779804,
                0.099404804,
                0.03958543,
                0.08423323,
                0.22054309,
                0.22500685,
                1.0,
                0.15545595,
                0.5108517,
                0.061946757,
                0.013529137,
                0.119843036,
                0.039259564,
                0.08402062,
                0.02701398,
                0.019235648,
                0.013660911,
                0.06272345,
                0.0014564147,
                0.043291274,
                0.045336578,
                0.011935806,
                0.12053635,
                0.041169986,
                0.010556532,
                0.006906516,
                0.031532276,
                0.0064428123,
                0.025852073,
                0.03809883,
                0.040361993,
                0.028878173,
                2.0419575e-4,
              ],
            },
            {
              impact: -0.164821,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.040302064,
                0.03255156,
                0.15710928,
                0.011866493,
                0.0154465735,
                0.08622514,
                0.057295285,
                0.55346954,
                0.105323575,
                0.26806343,
                0.123690814,
                0.05772257,
                0.22160983,
                0.1383794,
                0.015487563,
                0.010837106,
                0.021571673,
                0.021746827,
                0.007149207,
                0.100460686,
                0.13559766,
                0.03632721,
                0.012015719,
                0.061585553,
                0.022111852,
                0.0066657807,
                0.08036974,
                0.0026001197,
                0.008328959,
                0.015606054,
                0.012149072,
                0.011115536,
                0.013971145,
                0.012361517,
              ],
            },
            {
              impact: 0.15718935,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.030326946,
                0.037408143,
                0.1150993,
                0.021503272,
                0.096899666,
                0.39617544,
                0.16173518,
                0.40599224,
                0.9155365,
                0.084910445,
                0.011729741,
                0.06584694,
                0.09003663,
                0.008303465,
                0.038167443,
                2.427821e-4,
                0.02008707,
                0.059677027,
                0.0567349,
                0.014302075,
                0.064129874,
                0.11761357,
                0.028959246,
                0.09918418,
                0.06451954,
                0.031136317,
                0.036766917,
                0.015681656,
                0.032207645,
                0.043050747,
                0.042593207,
                0.058253527,
                0.059568256,
                0.030987905,
              ],
            },
          ],
          scores: [0.31201881170272827, 0.09804356098175049, 0.589937686920166],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "service",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              0.3620063,
              0.6063235,
              0.24742246,
              1.0,
              0.11711481,
              0.4995308,
              0.06955387,
              0.29674408,
              0.037853003,
              0.068352945,
              0.03694971,
              0.056797627,
              0.012363484,
              0.04883124,
              0.050059605,
              0.16216654,
              0.028649276,
              0.17236626,
              0.36183566,
              0.29636726,
            ],
            lookAt: [
              0.20611618,
              0.23302752,
              0.36974692,
              0.0780454,
              0.12656245,
              0.34357685,
              0.065847054,
              0.008197474,
              0.10561334,
              0.021636846,
              0.024884986,
              0.0061136656,
              0.054139215,
              0.027472636,
              0.018173601,
              0.08082275,
              8.5620035e-4,
              0.018840626,
              0.107799955,
              1.0,
            ],
          },
          aspectTokens: ["service"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.35384116,
                0.1310662,
                0.114189364,
                0.0646784,
                0.038733736,
                0.18362434,
                0.025369013,
                0.097175255,
                0.13006856,
                0.06803462,
                0.1423679,
                0.11718044,
                0.24955392,
                0.15192415,
                0.23872264,
                0.43507966,
                0.13483602,
                0.14867938,
                0.16566247,
                0.7732321,
              ],
            },
            {
              impact: -0.93199885,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                1.0,
                0.3508653,
                0.633496,
                0.5245425,
                0.09563854,
                0.54758286,
                0.06783646,
                0.20037514,
                0.15238619,
                0.08875564,
                0.18335167,
                0.0285777,
                0.023255922,
                0.019327756,
                0.10043043,
                0.04390996,
                0.021636628,
                0.004469775,
                0.0072206925,
                0.0060976306,
              ],
            },
            {
              impact: 0.49738806,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.1723728,
                0.201799,
                0.026021864,
                0.35833082,
                0.021748768,
                0.07921934,
                0.0077508963,
                0.08275796,
                0.037182536,
                0.003309152,
                0.0136660645,
                0.005031738,
                0.025632815,
                0.0029541478,
                0.05932375,
                0.009741384,
                0.0055713793,
                0.020780796,
                0.025117038,
                0.02460226,
              ],
            },
            {
              impact: 0.4341014,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.05300744,
                0.08859079,
                0.12081811,
                0.08563289,
                0.17301254,
                0.11916729,
                0.06453366,
                0.13794136,
                0.1572755,
                0.004599199,
                0.035254873,
                0.002441195,
                0.0104195075,
                0.021584287,
                0.019968696,
                0.0030348722,
                0.0044985125,
                0.0013326308,
                0.0015493098,
                0.009016808,
              ],
            },
            {
              impact: 0.33999366,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.011837502,
                0.083853684,
                0.1318355,
                0.21070795,
                0.039175723,
                0.0223121,
                0.0036549966,
                0.018821279,
                0.020529414,
                0.0011284046,
                0.0043109427,
                0.003836677,
                0.008312586,
                0.0039269472,
                0.0042046513,
                0.010152582,
                0.004475874,
                0.017684551,
                0.01187513,
                0.011997432,
              ],
            },
            {
              impact: 0.22067246,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.16867416,
                0.21089388,
                0.11736194,
                0.033470683,
                0.03993479,
                0.017403286,
                0.003804464,
                0.0051283883,
                0.0037551885,
                0.012000601,
                0.004361636,
                0.0082038315,
                0.006491202,
                3.5896173e-4,
                0.012430002,
                0.0032972614,
                0.010977463,
                0.010867612,
                0.010191644,
                0.012461738,
              ],
            },
            {
              impact: -0.17028736,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.011614979,
                0.021139663,
                0.013677632,
                0.014510678,
                0.030337382,
                0.0034815664,
                0.020472037,
                0.013119629,
                0.15109356,
                0.018190628,
                0.20243657,
                0.057936203,
                0.20628408,
                0.11618383,
                0.14434363,
                0.12727985,
                0.17286809,
                0.013225014,
                0.028188815,
                0.13787481,
              ],
            },
          ],
          scores: [
            0.23755455017089844,
            0.09093558043241501,
            0.6715098023414612,
          ],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "service",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.21267141661869604, 0.4459426945783785, 0.3413858888029255],
      sentiment: "negative",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
    {
      aspect: "staff",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.0049138577,
              0.56652284,
              0.41333538,
              0.32700092,
              0.20064633,
              0.15265946,
              0.04883573,
              0.112061664,
              0.071573906,
              0.36743098,
              0.16659956,
              0.5524784,
              0.12572129,
              0.6132963,
              0.0326769,
              1.0,
            ],
            lookAt: [
              0.062622726,
              0.006975051,
              0.041093655,
              0.009594282,
              0.059971455,
              0.034191847,
              0.018094309,
              0.03376705,
              0.06059939,
              0.0064059063,
              0.23956169,
              0.19297405,
              0.01651057,
              0.0039919573,
              0.10958478,
              1.0,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.023931675,
                0.038421553,
                0.031994935,
                0.042108547,
                0.03250872,
                0.0036486485,
                0.032873064,
                0.03956366,
                0.057273023,
                0.05219678,
                0.44628298,
                0.095442645,
                0.074042566,
                0.04593489,
                0.103924505,
                0.27605057,
              ],
            },
            {
              impact: -0.6041948,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.0065528164,
                0.022252548,
                0.01993577,
                5.713808e-4,
                0.013328023,
                0.028300643,
                0.02726334,
                0.015937585,
                0.012061041,
                0.004383692,
                0.45178932,
                0.23089123,
                0.3748102,
                0.2007634,
                0.15450516,
                0.29502246,
              ],
            },
            {
              impact: -0.40445334,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.009539526,
                0.09582001,
                0.13238406,
                0.007347786,
                0.22750941,
                0.054259893,
                0.097654074,
                0.07251986,
                0.022364374,
                0.050128963,
                0.73831767,
                0.19898655,
                0.22469592,
                0.12706126,
                0.23515439,
                0.36869302,
              ],
            },
            {
              impact: -0.39620468,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.0077850437,
                0.13821997,
                0.25060675,
                0.15436424,
                0.68131894,
                0.3745696,
                0.15792216,
                0.17933436,
                0.03004457,
                0.4145714,
                0.12329164,
                0.55965525,
                0.03988134,
                0.06599501,
                0.5736458,
                0.024638088,
              ],
            },
            {
              impact: -0.10846469,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.12879013,
                0.109858975,
                0.18630713,
                0.19768274,
                0.10840345,
                0.36138967,
                0.05328628,
                0.048677567,
                0.012327618,
                0.23402736,
                0.2945214,
                0.101155795,
                0.023056863,
                0.060687054,
                0.014084935,
                0.06407941,
              ],
            },
          ],
          scores: [
            0.31729769706726074,
            0.048583127558231354,
            0.6341192126274109,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.4248595,
              0.4634555,
              0.16085784,
              0.343919,
              0.21007407,
              0.05826238,
              0.4963588,
              0.19455147,
              0.3309811,
              0.067360245,
              0.085960105,
              0.037141412,
              0.2115302,
              0.120845,
              4.644068e-4,
              0.18255724,
              0.055615492,
              0.1282573,
              0.20491172,
              0.25043765,
              0.058337715,
              1.0,
            ],
            lookAt: [
              0.07780795,
              0.07137415,
              0.077088706,
              0.087517746,
              0.15893474,
              0.24241683,
              0.7834641,
              0.3027873,
              0.47283718,
              0.07583173,
              0.01941588,
              0.003430789,
              0.015776908,
              0.13585031,
              1.0,
              0.26177529,
              0.24750748,
              0.2951167,
              0.06105784,
              0.029346682,
              0.048838872,
              0.87047404,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.03964239,
                0.04579078,
                0.13188297,
                0.07330098,
                0.019440984,
                0.041197855,
                5.5866933e-4,
                0.076037645,
                0.0582554,
                0.08171785,
                0.107252605,
                6.898462e-4,
                0.09153547,
                0.3865517,
                0.374978,
                0.58159184,
                0.38581616,
                0.28372103,
                0.07325799,
                0.08359881,
                0.21886826,
                0.18285987,
              ],
            },
            {
              impact: 0.25980335,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.009587238,
                0.0075701284,
                0.031271722,
                0.022408996,
                0.046949744,
                6.4402045e-4,
                0.029521821,
                0.004525685,
                0.0878133,
                0.097533844,
                0.023006322,
                0.0016844524,
                0.034308266,
                0.35172194,
                0.7994165,
                0.048861247,
                0.14366291,
                0.0212282,
                0.046741694,
                0.05096018,
                0.049852498,
                0.041938175,
              ],
            },
            {
              impact: -0.25806352,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.055827748,
                0.04911696,
                0.006409547,
                0.06212682,
                0.09672605,
                0.0048224446,
                0.021912146,
                0.031325676,
                0.030475555,
                0.0036094496,
                0.020769874,
                0.033910293,
                0.015195269,
                0.01621527,
                0.018837348,
                0.0019010596,
                0.012668792,
                0.016661996,
                0.00821994,
                0.017831353,
                0.05532155,
                0.033114467,
              ],
            },
            {
              impact: 0.21956639,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.2154533,
                1.0,
                0.2859221,
                0.32491568,
                0.4641053,
                0.81115216,
                0.6638854,
                0.22071426,
                0.47539783,
                0.34502006,
                0.19242676,
                0.067439385,
                0.26442918,
                0.096189044,
                0.22963367,
                0.05660673,
                0.2875732,
                0.50313336,
                0.011674945,
                0.033671137,
                0.009346295,
                0.12096611,
              ],
            },
            {
              impact: -0.2050022,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.1685473,
                0.06733637,
                0.08210285,
                0.04648328,
                0.26946676,
                0.065676324,
                0.061397694,
                0.011658553,
                0.07333333,
                0.006983244,
                0.031620983,
                0.016546238,
                0.010190852,
                0.02174519,
                0.05936401,
                0.0017231584,
                0.014625061,
                0.039747383,
                0.003511721,
                0.00891465,
                0.008127946,
                0.026218776,
              ],
            },
            {
              impact: 0.191921,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.07008446,
                0.038262684,
                0.13295992,
                0.030308342,
                0.05083049,
                0.043824866,
                0.10008055,
                0.11993214,
                0.021130824,
                0.1401998,
                0.09981011,
                0.028992416,
                0.0721166,
                0.07534994,
                0.0577091,
                0.041859295,
                0.09241217,
                0.4157093,
                0.003398607,
                0.0025292,
                0.01072113,
                0.0360339,
              ],
            },
            {
              impact: 0.17149249,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.008354518,
                0.053248297,
                0.007912129,
                0.06509962,
                0.067501195,
                0.017240778,
                0.035541043,
                0.013233016,
                0.13002189,
                0.12060139,
                0.039352562,
                0.009884241,
                0.084417224,
                0.30057186,
                0.6226235,
                0.19556017,
                0.15936683,
                0.13228174,
                0.13820241,
                0.0042593465,
                0.1970416,
                0.07054632,
              ],
            },
            {
              impact: -0.14423819,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.034732465,
                0.19043526,
                0.29294196,
                0.09618391,
                0.015628979,
                0.019656176,
                0.057765268,
                0.0072494815,
                0.050210834,
                0.036461283,
                0.11598937,
                0.032552462,
                0.03356731,
                1.0664472e-4,
                0.029079651,
                0.006682449,
                0.007524092,
                0.113009706,
                0.014539478,
                0.047837626,
                0.025761371,
                0.02424591,
              ],
            },
            {
              impact: 0.10921456,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.016289394,
                0.41006216,
                0.019082176,
                0.21581854,
                0.012643161,
                0.23762766,
                0.13383964,
                0.23378636,
                0.44533622,
                0.15244268,
                0.11334763,
                0.12936081,
                0.031204768,
                0.028804382,
                0.23226865,
                0.038230773,
                0.10889113,
                0.07659364,
                0.0109675005,
                0.039839156,
                0.053854275,
                0.09688391,
              ],
            },
          ],
          scores: [
            0.24401292204856873,
            0.7348375916481018,
            0.02114955522119999,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              1.0,
              0.7933552,
              0.56829876,
              0.09963291,
              0.50668865,
              0.42838013,
              0.27714345,
              0.18381408,
              0.14478287,
              0.1028052,
              0.30930975,
              0.069093995,
              0.59739786,
              0.06683517,
              0.2299244,
              0.18676473,
              0.1448627,
              0.0024160028,
              0.79280895,
            ],
            lookAt: [
              0.013538371,
              0.77638084,
              0.106683545,
              0.06635273,
              1.0,
              0.1214958,
              0.080540754,
              0.23562516,
              0.027955048,
              0.016746344,
              0.05898296,
              0.04784867,
              0.10573175,
              0.07783097,
              0.02560506,
              0.14886399,
              0.046056487,
              0.21211778,
              0.30797574,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.047862563,
                0.6752343,
                0.0024564685,
                0.5021561,
                0.31857657,
                0.19358289,
                0.20685755,
                0.008012763,
                0.026274966,
                0.0047116484,
                0.015743054,
                0.0044737374,
                0.03370143,
                0.009803787,
                0.029335074,
                0.023255851,
                0.0057272324,
                0.0040587746,
                0.21671957,
              ],
            },
            {
              impact: -0.89961076,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.05604516,
                0.2770869,
                0.12421606,
                0.36169082,
                0.126332,
                0.0073659155,
                0.115848325,
                0.06354236,
                0.024022514,
                0.1917248,
                0.010997437,
                0.020253433,
                0.09435789,
                0.007646732,
                0.054559324,
                0.0919846,
                0.009978721,
                0.011026569,
                0.06861899,
              ],
            },
            {
              impact: 0.6198474,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.020895049,
                0.02164908,
                0.0070070582,
                0.014886037,
                0.0026884042,
                0.0090577975,
                0.022848276,
                2.7085003e-4,
                0.013919132,
                0.017966673,
                0.011841997,
                0.010246433,
                0.006769051,
                0.0028008842,
                0.011946934,
                0.021559551,
                0.0070562144,
                0.044603195,
                0.030785391,
              ],
            },
            {
              impact: -0.31876448,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.031576253,
                0.014117082,
                0.03638474,
                0.006049354,
                0.008367172,
                0.018421609,
                0.02583676,
                0.12521857,
                0.016336976,
                0.07810306,
                0.07241107,
                0.03319178,
                0.04421796,
                0.03921407,
                0.02627724,
                0.06800983,
                0.0102149295,
                0.17911576,
                0.010134409,
              ],
            },
            {
              impact: -0.30825016,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.005926663,
                0.01444565,
                0.004215782,
                0.016870148,
                0.012480629,
                0.054699305,
                0.004373114,
                0.3489312,
                0.022932114,
                0.027264254,
                0.08124744,
                0.028408663,
                0.023844244,
                0.0023943267,
                0.007848684,
                0.18732448,
                0.17728184,
                0.024383917,
                0.013830981,
              ],
            },
            {
              impact: -0.29624966,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.2651152,
                0.41554165,
                0.46950862,
                0.14526401,
                0.044046097,
                0.38936517,
                0.012020546,
                0.001003906,
                0.008780178,
                0.009525081,
                0.030794848,
                0.017521065,
                0.03822017,
                0.010301635,
                0.048665095,
                0.023543099,
                0.020813728,
                0.021669492,
                0.02411977,
              ],
            },
            {
              impact: -0.22897582,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.06843408,
                0.013542673,
                0.07985372,
                0.025710082,
                0.037446756,
                0.27300146,
                0.024871198,
                0.61119777,
                0.026532026,
                0.13911964,
                0.12462391,
                0.012578762,
                0.026784683,
                0.018404089,
                0.01984472,
                0.1809984,
                0.007741006,
                0.11744871,
                0.03362273,
              ],
            },
            {
              impact: -0.22233911,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                1.3195456e-4,
                0.004877711,
                0.025478285,
                0.026756065,
                0.031415924,
                0.026485652,
                0.017551417,
                0.4507694,
                0.025084378,
                0.018073421,
                0.020463482,
                0.048981555,
                0.022554487,
                0.0013060375,
                0.005086799,
                0.08586637,
                0.089407444,
                0.00674014,
                0.0343207,
              ],
            },
          ],
          scores: [0.3375459909439087, 0.13204587996006012, 0.5304082036018372],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.7080223,
              0.65925354,
              0.8121893,
              0.6809708,
              0.2025271,
              0.48824596,
              0.3383162,
              0.3669173,
              1.0,
              0.005344792,
              0.12284569,
              0.30289215,
              0.30909547,
              0.18234329,
              0.030099625,
              0.45667017,
              0.14731982,
              0.37521604,
              0.3864342,
            ],
            lookAt: [
              0.08758797,
              0.030791577,
              1.0,
              0.05903737,
              0.0016985843,
              0.02657364,
              0.107587725,
              0.012727868,
              0.0072628763,
              0.05066296,
              0.007531426,
              0.026749648,
              2.0288158e-4,
              0.1246283,
              0.009401234,
              0.022923592,
              0.037505854,
              0.060086828,
              0.41610864,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.046029456,
                0.0040406953,
                0.4028103,
                0.006995945,
                0.25217947,
                0.045920808,
                0.43102145,
                0.20755379,
                0.19628987,
                0.2285864,
                0.088204496,
                0.01388323,
                0.15990178,
                0.9280932,
                0.04487308,
                0.10237248,
                0.005030966,
                0.0057016117,
                0.049396478,
              ],
            },
            {
              impact: 0.93719167,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                2.2005635e-4,
                0.0032217756,
                0.044882726,
                0.0041980017,
                0.026111929,
                0.045566376,
                0.26984623,
                0.31393898,
                0.07262975,
                0.09268624,
                0.04440321,
                0.034321755,
                0.1414088,
                0.84956944,
                0.26731822,
                0.72805494,
                0.01088664,
                0.38030362,
                0.46373802,
              ],
            },
            {
              impact: -0.9309509,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.13578767,
                0.2800185,
                0.21522172,
                1.0,
                0.3349002,
                0.01656461,
                0.03564741,
                0.06988025,
                0.034403313,
                0.04952136,
                0.0100415815,
                0.031013815,
                0.0184469,
                0.2035037,
                0.012157501,
                0.121834315,
                0.033993404,
                0.02940231,
                0.20644291,
              ],
            },
            {
              impact: 0.5783192,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.01354052,
                0.024188248,
                0.08060441,
                0.026174545,
                0.08372022,
                0.033960238,
                0.12258916,
                0.043036748,
                0.03381607,
                0.073994435,
                0.1878631,
                0.034457196,
                0.13785137,
                0.05429379,
                0.032306578,
                0.11308619,
                0.17359328,
                0.27548558,
                0.15397824,
              ],
            },
            {
              impact: -0.3440251,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.026946925,
                0.010063251,
                0.0026602652,
                0.014504162,
                0.0030163217,
                0.026776256,
                0.013116684,
                0.033143945,
                0.03634272,
                0.030117119,
                0.0063199075,
                0.007604263,
                0.008947676,
                5.8058416e-4,
                0.018434435,
                0.03133308,
                0.022760103,
                0.09284713,
                0.09265208,
              ],
            },
            {
              impact: -0.25186154,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.08289663,
                0.6640603,
                0.20850232,
                0.48585898,
                0.1564122,
                0.019398296,
                0.24927083,
                0.12541722,
                0.14342073,
                0.46921164,
                0.06058192,
                0.01828848,
                0.03815119,
                0.051346265,
                0.026753383,
                0.035015326,
                0.035563372,
                3.737956e-5,
                0.10743013,
              ],
            },
          ],
          scores: [
            0.24818578362464905,
            0.44769394397735596,
            0.3041203022003174,
          ],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.14304683,
              0.12456097,
              0.06532529,
              0.07010176,
              0.005176617,
              0.002134318,
              0.19924174,
              0.44888017,
              0.15405925,
              0.038923934,
              0.12195406,
              0.20987828,
              0.04245844,
              1.0,
              0.5058646,
              0.06100899,
              0.05297102,
              0.038905926,
              0.16194774,
              0.13271765,
              0.014265856,
              0.028963305,
              0.3673931,
            ],
            lookAt: [
              0.16300744,
              0.011915008,
              0.028561126,
              0.013416302,
              0.00622351,
              0.018294659,
              0.31417516,
              0.12236812,
              0.009945406,
              0.05563492,
              0.11743656,
              1.0,
              0.2563063,
              0.10471465,
              0.19256975,
              0.5209649,
              0.07912286,
              0.0034423573,
              0.034607664,
              0.077582866,
              0.12771931,
              0.22620998,
              0.24991488,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.061413337,
                0.033974428,
                0.013359301,
                0.017422797,
                2.1690952e-4,
                0.021815587,
                0.050107807,
                0.09320456,
                0.047003306,
                0.073300004,
                0.04487183,
                0.19573799,
                0.07392205,
                0.026271638,
                0.0021523146,
                0.26605865,
                0.3757776,
                0.03141214,
                0.34063494,
                0.04811483,
                0.10595864,
                0.13674456,
                0.019379904,
              ],
            },
            {
              impact: 0.9295869,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.040165737,
                0.03847423,
                0.016958714,
                5.0974668e-5,
                0.0027980807,
                0.020040773,
                0.01378726,
                0.059139118,
                0.11072964,
                0.011088175,
                0.06735097,
                0.5150076,
                0.07779592,
                0.0053092465,
                0.27459568,
                0.051031165,
                0.4353379,
                0.12694938,
                0.41316196,
                0.15292771,
                0.046159934,
                0.059937667,
                0.18259194,
              ],
            },
            {
              impact: 0.8768598,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                7.219838e-4,
                0.022221679,
                0.006008117,
                0.009330866,
                0.010305782,
                0.01920726,
                0.06869275,
                0.067952916,
                0.018538028,
                0.010839067,
                0.20542102,
                0.023196924,
                0.07176749,
                0.08076095,
                0.0664337,
                0.17595157,
                0.14098985,
                6.001511e-5,
                0.09928207,
                0.03849128,
                0.089118846,
                0.08081058,
                0.09544485,
              ],
            },
            {
              impact: 0.8102124,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.004747715,
                0.011102022,
                0.0018946783,
                0.0035995059,
                0.02007204,
                0.01089186,
                0.0026790665,
                0.028638678,
                0.05247799,
                0.028350987,
                0.029389601,
                0.19908524,
                0.089483455,
                0.006790559,
                0.10329007,
                1.0,
                0.107293375,
                0.15472929,
                0.41629964,
                0.50686467,
                0.11124129,
                0.095517166,
                0.30868435,
              ],
            },
            {
              impact: 0.69688064,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0053830165,
                0.005336862,
                0.0051753484,
                0.0057559423,
                0.006352812,
                0.004501347,
                0.0015058423,
                0.009639396,
                0.034325827,
                0.023769218,
                0.027342467,
                0.10180951,
                0.015375086,
                0.04042791,
                0.05611417,
                0.26718011,
                0.059196964,
                0.12424863,
                0.22941862,
                0.35449684,
                0.005144382,
                0.014067878,
                0.07118734,
              ],
            },
            {
              impact: 0.38392192,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0045163496,
                0.0052486416,
                0.0030338755,
                0.0020169846,
                0.00544375,
                0.010044448,
                0.006695505,
                0.024769144,
                2.3950358e-4,
                0.008046023,
                0.036627654,
                0.10405013,
                0.052739643,
                0.013264382,
                0.0023444432,
                0.15411177,
                0.21573772,
                0.035809502,
                0.21862678,
                0.00859908,
                0.12142368,
                0.06583081,
                0.28808293,
              ],
            },
          ],
          scores: [
            0.037863921374082565,
            0.9498821496963501,
            0.012253873981535435,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.18463337,
              0.1826734,
              0.25152785,
              0.3223365,
              0.07038698,
              0.043951657,
              0.025070382,
              0.10079951,
              0.2023238,
              0.21805803,
              0.23112617,
              0.23382178,
              0.37475467,
              0.11095471,
              0.11697797,
              0.21684891,
              0.09591686,
              0.10368786,
              0.0437235,
              0.12602524,
              0.3631374,
              0.0624699,
              8.1803184e-5,
              0.58104485,
              0.0366414,
              0.093774885,
              0.25837404,
              0.2115839,
              0.028183388,
              0.20863158,
              0.31473884,
              0.01994346,
              1.0,
              0.7782162,
            ],
            lookAt: [
              0.29874623,
              0.031507663,
              0.44000128,
              0.050480954,
              0.108155504,
              0.16016014,
              0.3066998,
              0.11171924,
              0.26094097,
              0.024172565,
              0.07582819,
              0.016055472,
              0.020838765,
              1.0,
              0.112452075,
              0.08458636,
              0.016046463,
              0.06187757,
              0.035190325,
              7.088547e-4,
              0.066239335,
              0.0040683094,
              0.024245124,
              0.06483131,
              0.0050942423,
              0.01678949,
              0.013309239,
              0.009899633,
              0.03992935,
              0.023380226,
              0.04535586,
              0.04771971,
              0.026943581,
              0.43162352,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.062223602,
                0.01753717,
                0.21518543,
                0.15895195,
                0.109491065,
                0.15512523,
                0.17239219,
                0.27433848,
                0.32605165,
                0.5778858,
                0.23357864,
                0.14518325,
                0.57171965,
                0.30265278,
                0.4606431,
                0.018345064,
                0.031910352,
                0.04837431,
                0.12274666,
                0.082563296,
                0.087799534,
                0.02202462,
                0.023080876,
                0.01360751,
                0.116193086,
                0.11737869,
                0.25011405,
                0.19268401,
                0.031422533,
                0.22816098,
                0.063691035,
                0.01679773,
                0.03590909,
                0.29963958,
              ],
            },
            {
              impact: 0.5322164,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.039955184,
                0.010315675,
                0.010338854,
                0.012845838,
                0.010258102,
                0.030558584,
                0.006341309,
                0.005832762,
                2.6772433e-4,
                0.0040954575,
                0.006015711,
                0.0097928755,
                0.0013628514,
                0.02499828,
                0.058356885,
                0.0021577056,
                0.0025884933,
                0.008122933,
                0.006796554,
                0.025225058,
                0.009997556,
                0.0019463445,
                1.9014129e-4,
                0.005615737,
                0.0019184509,
                9.5389354e-5,
                0.012876898,
                0.022778304,
                0.0048064035,
                0.026916802,
                0.11892587,
                0.037111685,
                0.06396299,
                0.006637749,
              ],
            },
            {
              impact: -0.4124746,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.02632258,
                0.038512632,
                0.015377196,
                0.010555502,
                7.0391846e-4,
                0.009901346,
                0.007995445,
                0.001721093,
                0.0025987385,
                0.010357244,
                0.013145937,
                0.02853543,
                0.0037943954,
                0.13521782,
                0.012260476,
                0.3420957,
                0.40983528,
                0.056177467,
                0.003545756,
                0.010202825,
                0.02391478,
                0.0499035,
                0.013500526,
                0.05287535,
                0.09537429,
                0.10277514,
                0.07603874,
                0.009933304,
                0.014078164,
                0.055578426,
                0.005798065,
                0.0022347774,
                0.03536751,
                0.030702632,
              ],
            },
            {
              impact: -0.33002046,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.012894318,
                0.04595138,
                0.0066107027,
                0.005744422,
                0.020624889,
                0.017880872,
                0.006039329,
                0.023271628,
                0.03789065,
                0.2501384,
                0.13327037,
                0.0073280707,
                0.19712448,
                0.054118995,
                0.0063173147,
                0.0013732895,
                0.0011539215,
                0.004167636,
                0.0111062685,
                0.0022931502,
                0.0075931535,
                0.023870269,
                0.005014066,
                0.026021408,
                0.004815563,
                0.0019000696,
                0.015223659,
                0.0048538446,
                0.01987678,
                0.004025582,
                0.011236991,
                0.0016284004,
                0.0020584837,
                0.02158866,
              ],
            },
            {
              impact: -0.28006968,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0022124925,
                0.017476453,
                0.062251896,
                0.0036710098,
                0.0027498328,
                0.0013559214,
                0.009515641,
                0.013825165,
                0.058326278,
                0.010271834,
                7.095277e-5,
                0.008880634,
                0.0074733235,
                0.11698822,
                0.031813987,
                0.012378835,
                0.019998856,
                6.5896934e-4,
                0.0017382259,
                0.001144248,
                0.028112527,
                0.009719397,
                0.007759794,
                0.01608266,
                1.3229494e-4,
                0.08400981,
                0.01979781,
                0.03043255,
                0.10617128,
                0.3967644,
                0.037960634,
                0.005061128,
                0.004052378,
                0.01455178,
              ],
            },
            {
              impact: -0.27786237,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.04761823,
                0.026613602,
                0.13396822,
                0.11853771,
                0.051264327,
                0.024722122,
                0.026929384,
                0.07809423,
                0.072497934,
                0.02378111,
                0.010313443,
                0.09364801,
                0.021697914,
                0.13484588,
                0.03376556,
                0.011501799,
                0.0086014215,
                0.026453258,
                0.0015574928,
                0.0031264897,
                0.015862603,
                0.001996818,
                0.0026464427,
                0.0058870097,
                0.011905594,
                0.009518453,
                0.016490873,
                0.00791615,
                0.047690134,
                0.014319894,
                0.01600052,
                0.001406858,
                0.032329477,
                0.03727584,
              ],
            },
            {
              impact: 0.2595463,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.018710632,
                0.13999695,
                0.0509644,
                0.12469378,
                0.076226875,
                0.20263447,
                0.09721064,
                0.16593398,
                0.5302411,
                0.07841143,
                0.053850632,
                0.1273421,
                0.12993892,
                0.05622942,
                4.4265902e-4,
                0.0062064896,
                0.043187816,
                0.010787042,
                0.017810274,
                0.009447614,
                0.011733502,
                0.016594524,
                0.0021265717,
                0.01696974,
                0.037362475,
                0.007196867,
                0.021813449,
                0.0062299883,
                6.031644e-4,
                0.006267701,
                0.022856357,
                0.006416063,
                0.062208723,
                0.027245864,
              ],
            },
            {
              impact: -0.2528597,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.033527944,
                0.030163495,
                0.036686715,
                0.0024159767,
                0.004908826,
                0.009948621,
                0.009797551,
                0.011780165,
                0.008533207,
                0.0033220432,
                0.0038011102,
                0.0018805431,
                0.0030145836,
                0.10155961,
                0.35558704,
                0.035386365,
                0.04083807,
                0.034365498,
                0.016282193,
                0.018831303,
                0.0071370555,
                0.003699536,
                0.005644517,
                0.01988174,
                0.013775314,
                0.029165031,
                0.007817472,
                0.017278207,
                0.03421823,
                0.049286745,
                0.02283099,
                0.0036729514,
                0.006327458,
                0.057573907,
              ],
            },
            {
              impact: 0.2522417,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.02184061,
                0.029642386,
                0.074977,
                0.027351825,
                0.06439676,
                0.19900523,
                0.18276107,
                1.0,
                0.1931579,
                0.40119645,
                0.0390586,
                0.0061841533,
                0.10508244,
                0.031995356,
                0.06333297,
                0.021166537,
                0.008330606,
                0.010714719,
                0.05629174,
                0.0071663484,
                0.034063507,
                0.03362664,
                0.008824086,
                0.08533648,
                0.028583901,
                0.004793703,
                7.1411143e-4,
                0.022080608,
                0.018153181,
                0.017341815,
                0.037972737,
                0.034630697,
                0.028154109,
                0.007618042,
              ],
            },
            {
              impact: -0.22415155,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.010229659,
                0.014045577,
                0.0013925285,
                0.0075579598,
                0.007050393,
                0.0019308975,
                0.0032992049,
                7.349398e-4,
                0.0011790901,
                0.005979229,
                0.00798133,
                0.007893355,
                0.010924772,
                0.006643259,
                0.074537575,
                0.0034128935,
                0.011632177,
                0.026668256,
                0.0054205484,
                0.04864701,
                0.05633859,
                0.06541273,
                0.025880832,
                0.038849358,
                0.022818157,
                0.054793864,
                0.19308013,
                0.20229732,
                0.033066,
                0.02969955,
                0.038093265,
                0.17833276,
                0.12218202,
                0.034015853,
              ],
            },
            {
              impact: -0.2179532,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.020004831,
                0.11799032,
                0.38878864,
                0.31015286,
                0.26411918,
                0.16285978,
                0.0442538,
                0.070148356,
                0.040805887,
                0.030430336,
                0.043774646,
                0.39961705,
                0.1026214,
                0.026093556,
                0.0085323155,
                8.94262e-4,
                0.0035489965,
                0.012101993,
                0.035432126,
                0.03316272,
                0.011838963,
                0.017058412,
                0.013621724,
                0.029849313,
                0.017594859,
                0.0065866606,
                0.009760789,
                0.01168832,
                0.0206068,
                0.024862995,
                0.07019201,
                0.0037294065,
                0.06732821,
                0.030630354,
              ],
            },
            {
              impact: 0.17566809,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.029952679,
                0.008124436,
                0.09368854,
                0.012959107,
                0.080892354,
                0.3272171,
                0.16020629,
                0.28245455,
                0.6820482,
                0.06466099,
                0.006197403,
                0.043617405,
                0.0823036,
                0.019914877,
                0.028065797,
                0.004498614,
                0.018794607,
                0.048212238,
                0.051096432,
                0.036407232,
                0.064824425,
                0.088068195,
                0.024299623,
                0.1359016,
                0.051195323,
                0.025632117,
                0.032023527,
                0.010778168,
                0.017926546,
                0.033755623,
                0.02830354,
                0.04897331,
                0.024877124,
                0.031097932,
              ],
            },
            {
              impact: -0.14646715,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.07248128,
                0.015003534,
                0.010180851,
                0.0014023685,
                0.003983675,
                0.002569435,
                9.1177e-5,
                0.009378458,
                0.012846458,
                0.003501885,
                0.0029723092,
                5.6380394e-4,
                0.0023035586,
                0.026124947,
                0.045894146,
                0.0035264438,
                0.020943657,
                0.015776487,
                4.1682017e-4,
                0.00507047,
                0.016913468,
                0.013810008,
                0.025821632,
                0.0042774966,
                0.050589275,
                0.011505901,
                0.05739152,
                0.115745194,
                0.13463974,
                0.022338955,
                0.02330641,
                0.005836111,
                0.013385874,
                0.02301062,
              ],
            },
          ],
          scores: [0.3489614427089691, 0.10820227116346359, 0.5428363084793091],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "staff",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              0.04952361,
              0.07810841,
              0.033991005,
              0.288853,
              0.32102504,
              0.064475305,
              0.15315261,
              0.2815762,
              0.004600177,
              0.14617805,
              0.053535044,
              0.0054726675,
              0.057306834,
              0.09552558,
              0.10217874,
              0.044238787,
              0.076721236,
              0.018003022,
              0.037337404,
              1.0,
            ],
            lookAt: [
              0.018171633,
              0.24874131,
              0.34744278,
              0.2822309,
              0.10614739,
              0.33788264,
              0.056238174,
              0.04345788,
              0.14081377,
              0.027827643,
              0.014493425,
              0.008807063,
              0.009911988,
              0.014757817,
              0.028391363,
              0.027098533,
              0.018349504,
              0.016962454,
              0.037876703,
              1.0,
            ],
          },
          aspectTokens: ["staff"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.43831685,
                0.18886566,
                0.13359283,
                0.081783876,
                0.0055153896,
                0.20976996,
                0.02167423,
                0.1108951,
                0.16340815,
                0.09844137,
                0.17530824,
                0.1285387,
                0.28945783,
                0.17155106,
                0.28349036,
                0.66714746,
                0.20603654,
                0.18262698,
                0.48311839,
                1.0,
              ],
            },
            {
              impact: -0.62400013,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.9232424,
                0.35868597,
                0.7280038,
                0.4561626,
                0.042856026,
                0.51991963,
                0.06715865,
                0.16749759,
                0.13759503,
                0.078987926,
                0.17222953,
                0.02545809,
                0.016979165,
                0.017960785,
                0.09257217,
                0.05613191,
                0.021024318,
                0.0055183647,
                0.009373471,
                0.013458746,
              ],
            },
            {
              impact: 0.41256323,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.16646835,
                0.18653572,
                0.06585221,
                0.41672668,
                0.00814607,
                0.07833853,
                0.008027018,
                0.08257869,
                0.035252746,
                0.0035314127,
                0.012634237,
                0.0046810303,
                0.027577901,
                0.003842565,
                0.054669138,
                0.008794445,
                0.003983451,
                0.019050315,
                0.02413617,
                0.018369183,
              ],
            },
            {
              impact: 0.4093405,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.08193738,
                0.08863957,
                0.12225627,
                0.08625214,
                0.17693105,
                0.12458704,
                0.07793961,
                0.12482687,
                0.1522924,
                0.002135238,
                0.032584872,
                6.737065e-4,
                0.010321824,
                0.024284407,
                0.022229077,
                0.0016615923,
                0.0037336051,
                9.36559e-4,
                7.2261906e-4,
                0.004068522,
              ],
            },
            {
              impact: 0.25473672,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.022216234,
                0.082628615,
                0.10480601,
                0.16604011,
                0.04950547,
                0.018320654,
                0.0021433297,
                0.027969895,
                0.025177453,
                0.014496115,
                0.0040234043,
                0.004962437,
                0.007987905,
                0.0019878852,
                0.0010061421,
                0.00883281,
                0.009664458,
                0.017025206,
                0.012105107,
                0.010463058,
              ],
            },
            {
              impact: 0.19251555,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.20995677,
                0.17522454,
                0.12427617,
                0.01419735,
                0.041132726,
                0.016738886,
                6.7421107e-4,
                0.0041360008,
                0.0058681583,
                0.010605284,
                0.004351912,
                0.007729006,
                0.008459482,
                0.0033660561,
                7.964203e-4,
                0.0023961684,
                0.009570444,
                0.008194678,
                0.011471348,
                0.017358107,
              ],
            },
          ],
          scores: [
            0.21628327667713165,
            0.09290172159671783,
            0.6908149719238281,
          ],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "staff",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.17538760115187318, 0.477409625312583, 0.3472027735355438],
      sentiment: "negative",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
    {
      aspect: "location",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.79636043,
              0.18637545,
              0.28431368,
              0.10803189,
              0.099151775,
              0.25078326,
              0.1255215,
              0.041278306,
              0.22077878,
              0.06349309,
              0.15723044,
              1.0,
              0.42981836,
              0.7036339,
              0.3282422,
              0.16473061,
            ],
            lookAt: [
              0.041645266,
              0.02506941,
              0.024972085,
              0.004357862,
              0.032286864,
              0.0057010567,
              0.017044896,
              0.03397383,
              0.02440894,
              0.032484345,
              0.06511959,
              0.38777864,
              0.045187812,
              0.0060685524,
              0.089862175,
              1.0,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.029180342,
                0.033174124,
                0.015659561,
                0.03992815,
                0.0060332245,
                0.015847262,
                0.030190587,
                0.050501447,
                0.05177082,
                0.032378465,
                0.5732313,
                0.014607705,
                0.16403566,
                0.049007162,
                0.09706181,
                0.12158432,
              ],
            },
            {
              impact: 0.7125443,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.17326254,
                0.122204915,
                0.1447526,
                0.16838588,
                0.4325405,
                0.3546025,
                0.16899465,
                0.22994961,
                0.11215575,
                0.40153724,
                0.16165109,
                0.75481355,
                0.042293288,
                0.4664325,
                0.6331289,
                0.50289285,
              ],
            },
            {
              impact: 0.39122245,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.054080166,
                0.07615608,
                0.050878596,
                0.011138776,
                0.06434569,
                0.0018555209,
                0.0022135996,
                0.008123552,
                0.019364197,
                0.013871676,
                0.44497487,
                0.16367634,
                0.12364707,
                0.040779084,
                0.09246263,
                0.35323274,
              ],
            },
            {
              impact: 0.3175943,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.04435052,
                0.15270741,
                0.018356027,
                0.049661204,
                0.036080275,
                0.040248036,
                0.106693834,
                0.14002961,
                0.1254175,
                0.047816984,
                0.19916157,
                0.082032874,
                0.39942983,
                0.31449246,
                0.25411278,
                0.76226157,
              ],
            },
            {
              impact: 0.18526216,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.07335249,
                0.019914249,
                0.11092579,
                0.073248886,
                0.21887983,
                0.14186229,
                0.04267029,
                0.052911326,
                0.09749058,
                0.060966175,
                0.31976336,
                0.0012138988,
                0.06950257,
                0.05783984,
                0.015103962,
                0.007798153,
              ],
            },
          ],
          scores: [
            0.48944491147994995,
            0.058728791773319244,
            0.451826274394989,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.39308614,
              0.13892134,
              0.09034325,
              0.3121765,
              0.007510552,
              0.146325,
              0.014575974,
              0.042225208,
              1.0,
              0.010306418,
              0.22851242,
              0.07170025,
              0.12646894,
              0.22653171,
              0.2009867,
              0.08342945,
              0.17902172,
              0.28727368,
              0.10375964,
              0.17465709,
              0.1027662,
              0.7759005,
            ],
            lookAt: [
              0.026892286,
              0.06886628,
              0.05112925,
              0.0074547334,
              0.33426937,
              0.11942245,
              0.42936525,
              0.18938467,
              0.074351184,
              0.035273943,
              0.024115974,
              0.0188576,
              0.004452682,
              0.070040055,
              0.4881839,
              0.13122982,
              0.06997618,
              0.035107475,
              0.02905318,
              0.010426581,
              0.019133562,
              1.0,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0787322,
                0.04586594,
                0.09791315,
                0.07424752,
                4.0861816e-4,
                0.016871313,
                0.029427141,
                0.065734036,
                0.08598115,
                0.075339794,
                0.10834259,
                0.020262767,
                0.08102882,
                0.12914161,
                0.28191963,
                0.5737781,
                0.58724976,
                0.14840637,
                0.06832354,
                0.051829327,
                0.16156505,
                0.24801226,
              ],
            },
            {
              impact: 0.78851515,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.059244726,
                0.04709614,
                0.17425019,
                0.025377523,
                0.018924763,
                0.09557945,
                0.049485143,
                0.0032918719,
                0.13935803,
                0.14715143,
                0.07072584,
                0.0078824675,
                0.06103323,
                0.08506856,
                0.07073602,
                0.02753884,
                0.09371322,
                0.37196758,
                0.005809052,
                0.011507899,
                0.008801578,
                0.02986689,
              ],
            },
            {
              impact: 0.696153,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.12306903,
                0.8079298,
                0.25919512,
                0.2181583,
                0.46246094,
                0.8885121,
                0.63860637,
                0.22030073,
                0.5513172,
                0.35338116,
                0.18543898,
                0.07365792,
                0.25787076,
                0.14171171,
                0.27600366,
                0.053928368,
                0.31485197,
                0.53299206,
                0.01510235,
                0.034122963,
                0.015788602,
                0.089341305,
              ],
            },
            {
              impact: 0.521033,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0053239847,
                0.047834147,
                0.08178109,
                0.017572412,
                0.24867988,
                0.3166267,
                0.09631719,
                0.08795068,
                0.27151442,
                0.021984523,
                0.045143273,
                0.003829195,
                0.011587364,
                0.0026368578,
                0.09786262,
                0.0028590017,
                0.02357833,
                0.10883165,
                0.009252182,
                0.012754609,
                0.004012301,
                0.059264913,
              ],
            },
            {
              impact: 0.34796917,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.019331492,
                0.005117857,
                0.027909813,
                0.025496561,
                0.039541822,
                0.0056970194,
                0.029417994,
                0.0051610414,
                0.0035552836,
                0.09239138,
                0.036997385,
                0.0067511145,
                0.030315846,
                0.34028026,
                1.0,
                0.04188374,
                0.09130483,
                0.052984077,
                0.011196096,
                0.064293675,
                0.0735915,
                0.07628701,
              ],
            },
            {
              impact: -0.3199575,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0077198455,
                0.14713748,
                0.16891892,
                0.028343877,
                0.14159577,
                0.005098074,
                0.06856105,
                0.0035678588,
                0.045657117,
                0.04017066,
                0.08840633,
                0.01604932,
                0.048183892,
                0.0049867677,
                0.030692887,
                0.009665734,
                0.008300578,
                0.13024658,
                0.019718548,
                0.045566097,
                0.03473818,
                0.017650716,
              ],
            },
            {
              impact: 0.29577413,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.05098166,
                0.30426857,
                0.012153867,
                0.021861013,
                0.3734047,
                0.060215566,
                0.17825949,
                0.09535255,
                0.08953412,
                7.320634e-4,
                0.038470186,
                0.00900396,
                0.028602142,
                0.061753485,
                0.05050776,
                0.019013403,
                0.033642694,
                0.07752686,
                0.006592496,
                0.002511317,
                0.0077137556,
                0.0644453,
              ],
            },
            {
              impact: -0.2904013,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.082707845,
                0.016179929,
                0.05224987,
                0.022470463,
                0.073901616,
                0.00550768,
                0.013637314,
                0.036273587,
                0.06824979,
                0.01167335,
                0.029312389,
                0.027088923,
                0.0136686945,
                0.012245603,
                0.019748254,
                0.001844467,
                0.0155297145,
                0.03388045,
                0.007994922,
                0.021407165,
                0.049721915,
                0.032526337,
              ],
            },
            {
              impact: -0.24000166,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.21144852,
                0.055223484,
                0.22646266,
                0.09133517,
                0.20958482,
                0.06320186,
                0.06314564,
                0.014627266,
                0.10505615,
                0.008944709,
                0.022007683,
                0.022936085,
                0.01669326,
                0.023455301,
                0.067530274,
                0.0032151574,
                0.009282521,
                0.045649022,
                0.009871876,
                0.010540137,
                0.005940334,
                0.036371935,
              ],
            },
            {
              impact: 0.18852144,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0031088078,
                0.5022049,
                0.045182638,
                0.20557387,
                0.023197645,
                0.3129662,
                0.2550727,
                0.3619037,
                0.29879564,
                0.16768678,
                0.15604886,
                0.04314774,
                0.065482736,
                0.017694611,
                0.22353129,
                0.054256313,
                0.15396647,
                0.094969064,
                0.0065418733,
                0.04028977,
                0.019650957,
                0.056928545,
              ],
            },
          ],
          scores: [0.2925611436367035, 0.6845653057098389, 0.02287360094487667],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              0.18337764,
              1.0,
              0.266849,
              0.04381026,
              0.0924035,
              0.43852007,
              0.2125931,
              0.04366096,
              0.050237417,
              0.099356286,
              0.090178974,
              0.013525489,
              0.44194505,
              0.28303865,
              0.023436736,
              0.6701067,
              0.057217717,
              0.37440655,
              0.5666676,
            ],
            lookAt: [
              0.07760009,
              1.0,
              0.4534257,
              0.112431236,
              0.22435808,
              0.1113827,
              0.13204718,
              0.15743351,
              0.022199951,
              0.040091198,
              0.11982663,
              0.03864562,
              0.019847546,
              0.05325682,
              0.077877566,
              0.04329787,
              0.004222196,
              0.032416936,
              0.9590808,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.061837036,
                0.5328735,
                0.077414535,
                0.34778967,
                0.46269223,
                0.23994438,
                0.22115257,
                0.014695734,
                0.013703658,
                0.01696463,
                3.9804445e-4,
                0.008519412,
                0.03225604,
                0.013157375,
                0.029419938,
                0.03239312,
                0.008771039,
                0.005540636,
                0.19284588,
              ],
            },
            {
              impact: 0.38256213,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.058140032,
                0.0022542581,
                0.022948315,
                0.0049065812,
                0.057681143,
                0.043603,
                6.3186674e-4,
                0.077408925,
                1.0,
                0.1737414,
                0.04636611,
                0.007988505,
                0.006388251,
                0.009376417,
                0.034473218,
                0.0572584,
                0.0025777705,
                0.04554127,
                0.018883178,
              ],
            },
            {
              impact: 0.35798356,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.22398433,
                0.44233897,
                0.11940909,
                0.147451,
                0.087163985,
                0.18118086,
                0.010928164,
                0.020802729,
                0.006824434,
                0.018447706,
                0.04824865,
                0.003286111,
                0.04789372,
                8.626852e-5,
                0.03346135,
                0.013757988,
                0.008698507,
                0.012142424,
                0.08259668,
              ],
            },
            {
              impact: 0.31426224,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.03413406,
                0.036740545,
                0.024397008,
                0.021442665,
                8.635431e-4,
                0.016246544,
                0.02981607,
                0.014173753,
                0.027411416,
                0.017493494,
                0.009528906,
                0.009027875,
                0.006596177,
                0.0026848544,
                0.01058453,
                0.011046838,
                0.012000844,
                0.05935409,
                0.01377648,
              ],
            },
            {
              impact: -0.31107038,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.043984808,
                0.05893572,
                0.048454426,
                0.020115541,
                0.084745474,
                0.1538395,
                0.041080832,
                0.9173074,
                0.034080684,
                0.25946486,
                0.13142724,
                0.010705234,
                0.016865341,
                0.01885486,
                0.015688436,
                0.19876961,
                0.019239863,
                0.18288419,
                0.030220008,
              ],
            },
            {
              impact: -0.18304724,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.00821753,
                7.044678e-4,
                0.008152494,
                0.013093876,
                0.0078673065,
                0.11443173,
                0.009782617,
                0.31867734,
                0.022687439,
                0.021995421,
                0.06905241,
                0.03042791,
                0.023470143,
                0.006508718,
                0.011876233,
                0.24762897,
                0.17099541,
                0.023171272,
                0.0076105995,
              ],
            },
            {
              impact: -0.18092903,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.03789505,
                0.031979144,
                0.048265312,
                0.004673643,
                0.0047863075,
                0.02608821,
                0.043165628,
                0.096721806,
                8.019168e-4,
                0.07362051,
                0.11596993,
                0.052622154,
                0.059911888,
                0.050984103,
                0.011292388,
                0.066931486,
                0.0038194864,
                0.1917826,
                0.035475638,
              ],
            },
          ],
          scores: [0.3111659288406372, 0.16597390174865723, 0.5228601098060608],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.20797753,
              1.0,
              0.07740579,
              0.042348888,
              0.05124142,
              0.011341383,
              0.049995564,
              0.03027483,
              0.085520506,
              0.0552912,
              0.010591181,
              0.091169715,
              0.023032626,
              0.041616652,
              0.014624805,
              0.05428815,
              0.011758678,
              0.01388076,
              0.080201276,
            ],
            lookAt: [
              0.094406106,
              0.42748618,
              1.0,
              0.013921704,
              0.013370481,
              0.021558609,
              0.13274321,
              0.032754015,
              0.269665,
              0.25339687,
              0.08602615,
              0.0046416544,
              0.08468372,
              0.25890562,
              0.04166693,
              0.20761894,
              0.007215482,
              0.24798673,
              0.5998614,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0022112457,
                0.009012715,
                0.36279956,
                0.07745319,
                0.05366724,
                0.14300005,
                0.37479976,
                0.17395829,
                0.18738307,
                0.24241489,
                0.2137699,
                0.005810272,
                0.1381735,
                1.0,
                0.04108712,
                0.079583034,
                0.017580582,
                1.04332976e-4,
                0.026117014,
              ],
            },
            {
              impact: 0.7170143,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0038224317,
                0.019581238,
                0.026512222,
                0.012308369,
                0.006481441,
                0.041275512,
                0.22055861,
                0.13729483,
                0.08413434,
                0.07318222,
                0.097436555,
                0.022498807,
                0.090717465,
                0.73722506,
                0.23949112,
                0.68263835,
                0.109183,
                0.3587762,
                0.44248435,
              ],
            },
            {
              impact: -0.7067382,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.010166125,
                0.21858402,
                0.284656,
                0.8557145,
                0.015120638,
                0.016601125,
                0.036932778,
                0.058632903,
                0.040740695,
                0.043787006,
                0.042645548,
                0.023168033,
                0.015063431,
                0.13995053,
                0.009029275,
                0.07328177,
                0.028088843,
                0.041021656,
                0.21210483,
              ],
            },
            {
              impact: 0.3536505,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.005145675,
                0.013246616,
                0.08031277,
                0.019833364,
                0.081100315,
                0.024663256,
                0.10116371,
                0.069843076,
                0.03497195,
                0.058226734,
                0.111993626,
                0.011397373,
                0.15927336,
                0.02564798,
                0.03514846,
                0.056025997,
                0.09509602,
                0.2461181,
                0.114416756,
              ],
            },
            {
              impact: -0.3076418,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.030682674,
                0.009068333,
                2.9919733e-4,
                0.007881299,
                0.012079988,
                0.017333794,
                0.027627861,
                0.025278287,
                0.037639912,
                0.042857,
                0.027434204,
                0.0028426028,
                0.0064527574,
                0.021763448,
                0.011864556,
                0.031394403,
                0.0027453974,
                0.06757174,
                0.09394709,
              ],
            },
            {
              impact: 0.17846152,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.061209485,
                0.13415523,
                0.11835782,
                0.033274535,
                0.27055198,
                0.046824645,
                0.017280778,
                0.019341713,
                0.017949423,
                0.026656467,
                0.013093676,
                0.005399605,
                0.006866151,
                0.00771467,
                0.013458461,
                0.06284913,
                0.004610231,
                0.0061100577,
                0.056684136,
              ],
            },
          ],
          scores: [
            0.38954824209213257,
            0.39839670062065125,
            0.21205507218837738,
          ],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.21959938,
              0.08397139,
              0.023382569,
              0.035558347,
              0.08234434,
              0.019265871,
              0.05203801,
              0.12667911,
              0.0028219777,
              0.018262032,
              0.073506676,
              0.080275506,
              0.07863993,
              1.0,
              0.97220874,
              0.12955981,
              0.07780502,
              0.010373673,
              0.09308232,
              0.07760849,
              0.018011034,
              0.037302986,
              0.2779518,
            ],
            lookAt: [
              0.07984486,
              0.038593438,
              0.021443946,
              0.007513994,
              0.030541323,
              0.0228112,
              0.12880668,
              0.050390624,
              0.04950433,
              0.08617503,
              0.14069062,
              1.0,
              0.09493576,
              0.27216268,
              0.08173967,
              0.76632243,
              0.5022242,
              0.03016859,
              0.23156606,
              0.054710317,
              0.15669669,
              0.23102264,
              0.5955556,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.06922293,
                0.03933896,
                0.013838125,
                0.01961609,
                0.005945768,
                0.021510659,
                0.061644275,
                0.062309247,
                0.026999352,
                0.034968894,
                0.059186097,
                0.09541557,
                0.015056355,
                0.005688078,
                0.019487895,
                0.12538086,
                0.23080258,
                0.021221986,
                0.20844632,
                0.017968327,
                0.10699105,
                0.19262834,
                0.04429765,
              ],
            },
            {
              impact: 0.904141,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0030200658,
                0.022986908,
                0.010361717,
                0.013200197,
                0.010790567,
                0.012296882,
                0.049946703,
                0.09305497,
                0.018285738,
                0.034350142,
                0.27156112,
                0.08971646,
                0.059747823,
                0.047777105,
                0.12335161,
                0.15846461,
                0.111542635,
                0.00702671,
                0.014091547,
                0.052975975,
                0.09943482,
                0.08867879,
                0.12387726,
              ],
            },
            {
              impact: 0.8903058,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.012795833,
                0.012072928,
                0.0019614233,
                0.00563148,
                0.02293923,
                0.009903066,
                0.0068599773,
                0.020827409,
                0.049201112,
                0.031450704,
                0.032634478,
                0.21664792,
                0.09178089,
                0.014561206,
                0.10539957,
                1.0,
                0.034318313,
                0.14730155,
                0.411584,
                0.4321706,
                0.14062949,
                0.12871991,
                0.3634719,
              ],
            },
            {
              impact: 0.8778701,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.038284242,
                0.02766141,
                0.020451162,
                1.9083732e-4,
                0.0022131072,
                0.018727448,
                0.013179951,
                0.042398963,
                0.11766332,
                3.8479888e-4,
                0.09132068,
                0.5854484,
                0.12807257,
                0.06028989,
                0.2915567,
                0.07301224,
                0.44405964,
                0.15845813,
                0.561252,
                0.07312772,
                0.09053359,
                0.11888653,
                0.31730098,
              ],
            },
            {
              impact: 0.74834937,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.007467829,
                0.007885415,
                0.0037573285,
                0.0055394773,
                0.0019807352,
                0.00900458,
                1.7673646e-4,
                0.030311987,
                0.036035877,
                0.029734671,
                0.00908425,
                0.15227681,
                0.008650552,
                0.056172244,
                0.07555026,
                0.24478878,
                0.021245275,
                0.13103065,
                0.25086185,
                0.28517908,
                0.052823145,
                0.06686333,
                0.08378673,
              ],
            },
            {
              impact: 0.37936276,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.008181447,
                0.0115593,
                0.0072109364,
                0.004880934,
                0.0040277396,
                0.020473462,
                0.01880201,
                0.030644966,
                9.4587175e-4,
                0.007804388,
                0.05483666,
                0.09582506,
                0.047022246,
                0.016734581,
                0.0014036888,
                0.091378406,
                0.16977553,
                0.033357732,
                0.1760198,
                0.003971232,
                0.1737028,
                0.09191701,
                0.2742396,
              ],
            },
          ],
          scores: [
            0.0653734877705574,
            0.9236366748809814,
            0.010989763773977757,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.12939556,
              0.98407394,
              0.05190182,
              0.059659716,
              0.21398811,
              0.13768855,
              0.048867945,
              0.28319973,
              0.07529871,
              0.08501044,
              0.09259162,
              0.09066902,
              0.22221224,
              0.04551659,
              0.033258952,
              0.071165554,
              0.083840795,
              0.1106003,
              0.043593634,
              0.22198045,
              0.10582826,
              0.0062986673,
              0.072455786,
              0.15628754,
              0.09031386,
              0.027707059,
              0.0044306186,
              0.008582891,
              0.042770714,
              0.008546523,
              0.379817,
              0.18211444,
              1.0,
              0.16263543,
            ],
            lookAt: [
              0.21920866,
              1.0,
              0.1501541,
              0.035954915,
              0.08856881,
              0.030138176,
              0.16149022,
              0.010167305,
              0.048589207,
              0.010435028,
              0.009869244,
              0.0039739884,
              3.3483954e-4,
              0.09284772,
              0.0018719574,
              0.0029740306,
              8.5096416e-4,
              0.006849529,
              0.0018362271,
              0.005549842,
              0.0055924966,
              0.0014540509,
              0.0032163695,
              0.012960379,
              2.6951238e-4,
              0.0033538349,
              0.008713162,
              0.006669755,
              0.0012378755,
              0.0025039634,
              0.0010043171,
              0.001751439,
              0.016169772,
              0.011958713,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0064894655,
                0.06114652,
                0.11994237,
                0.032939717,
                0.022408223,
                0.017893396,
                0.07496591,
                0.07948376,
                0.2581906,
                0.18453912,
                0.022503193,
                0.029555956,
                0.042418104,
                0.06949146,
                0.009298902,
                0.002092012,
                0.029971445,
                0.011996879,
                0.009521708,
                0.0031405112,
                0.0039004534,
                0.012033139,
                0.0014026422,
                0.005084716,
                0.010641918,
                0.004730013,
                0.005215613,
                0.0065559377,
                0.0023370485,
                0.008837378,
                0.005920517,
                0.0016171595,
                0.015889341,
                0.02469577,
              ],
            },
            {
              impact: 0.9325642,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.069794424,
                0.3595261,
                0.16053258,
                0.056350097,
                0.06247382,
                0.010643441,
                0.02608847,
                0.06295508,
                0.050391775,
                0.074839346,
                1.4255324e-4,
                0.03621028,
                0.029341271,
                0.06332092,
                0.03344643,
                0.0029386044,
                0.012255515,
                0.011024984,
                1.2828808e-4,
                0.0066890963,
                9.309288e-4,
                6.325069e-4,
                2.7588636e-4,
                0.0021845954,
                0.0027688106,
                0.027907064,
                0.021685978,
                0.033301946,
                0.016210781,
                0.037570164,
                0.027895575,
                0.0075902627,
                0.008155683,
                0.03133572,
              ],
            },
            {
              impact: 0.65088195,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.01692821,
                0.3763974,
                0.5272811,
                1.0,
                0.050666496,
                0.33766255,
                0.19273533,
                0.14929521,
                0.06510441,
                0.07434256,
                0.010715083,
                0.048815764,
                0.068675965,
                0.039880287,
                0.028532863,
                0.0012248799,
                0.019084236,
                0.0027383727,
                0.041699544,
                0.025007434,
                0.019714093,
                0.016885469,
                4.9774717e-6,
                0.017735042,
                0.006463662,
                0.005109028,
                0.0014163721,
                0.019687874,
                0.040590137,
                0.026285142,
                9.998666e-4,
                0.012406466,
                0.0038380441,
                0.009296585,
              ],
            },
            {
              impact: 0.63667643,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.012356905,
                0.02094817,
                0.05954658,
                0.04043423,
                0.018880608,
                0.020653678,
                0.03656279,
                0.08497509,
                0.074896835,
                0.11255712,
                0.041158717,
                0.022383457,
                0.14719108,
                0.027703669,
                0.19174507,
                0.024450744,
                0.008674941,
                6.380654e-4,
                0.012469167,
                0.005211274,
                0.03305471,
                0.02189143,
                0.0036131423,
                0.020837074,
                0.02572308,
                0.057494957,
                0.057092816,
                0.07551758,
                0.033631485,
                0.046200953,
                0.012289773,
                0.01214233,
                0.016453564,
                0.06900344,
              ],
            },
            {
              impact: 0.45009187,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.012067291,
                0.48168805,
                0.06843278,
                0.086203985,
                0.078314826,
                0.022370817,
                0.019395363,
                0.06771842,
                0.10940733,
                0.07515076,
                0.037708454,
                0.04034188,
                0.055006433,
                0.06913708,
                0.014320086,
                0.010579102,
                0.007730097,
                0.0064866403,
                0.024004463,
                0.092376396,
                0.027603982,
                0.058247577,
                0.019759698,
                0.05792529,
                0.014295121,
                0.011019779,
                0.16580546,
                0.012384717,
                0.020274842,
                0.022928514,
                0.020855593,
                0.0151481265,
                0.12356237,
                0.0015022603,
              ],
            },
            {
              impact: 0.36040536,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.353761,
                0.20561314,
                0.014454874,
                0.7910089,
                0.35601285,
                0.024756873,
                0.03486867,
                0.1472126,
                0.17887674,
                0.024458695,
                0.037480913,
                0.06090856,
                0.05402585,
                0.13298051,
                0.034359753,
                0.019944383,
                0.012534468,
                0.0020016402,
                0.009688048,
                0.0077836583,
                0.0032688475,
                0.0026071677,
                0.008986907,
                0.0028457437,
                0.0019237614,
                0.008712009,
                0.020195495,
                0.08488299,
                0.04439927,
                0.033537626,
                0.01728332,
                6.271755e-4,
                9.2520866e-5,
                0.010333858,
              ],
            },
            {
              impact: -0.35116854,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.03713322,
                0.12696189,
                0.20286697,
                0.1936613,
                0.10626069,
                0.06933933,
                0.030763082,
                0.014317978,
                0.05533333,
                8.2942937e-4,
                0.042309765,
                0.32063374,
                0.08656063,
                0.015056682,
                0.0144869005,
                0.007825471,
                0.00899489,
                0.014010716,
                0.03948279,
                0.055168994,
                0.025266025,
                5.1212363e-4,
                0.014605186,
                0.03687676,
                0.010044469,
                0.0060712583,
                0.011172178,
                0.0099133365,
                0.017059596,
                0.031400062,
                0.06939324,
                0.0040933094,
                0.073388375,
                0.016789164,
              ],
            },
            {
              impact: -0.2937055,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.003639234,
                0.020218173,
                0.0039394116,
                0.0028044651,
                4.4149975e-4,
                0.0038689836,
                0.0016207097,
                4.4678515e-4,
                7.3946756e-4,
                0.0030947146,
                0.0031366688,
                0.0067557367,
                0.004468076,
                0.026340183,
                0.024464132,
                0.11035938,
                0.119036846,
                0.011570858,
                0.0031170608,
                0.0028280788,
                0.022726368,
                0.011383108,
                0.0017177395,
                0.016918028,
                0.01046063,
                0.008929019,
                0.027499706,
                0.017252935,
                0.011512905,
                0.0060047186,
                7.2548696e-4,
                1.4993912e-4,
                0.008349226,
                0.0072394726,
              ],
            },
            {
              impact: 0.29041612,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.026713978,
                0.07293638,
                0.038045082,
                0.019929618,
                0.059155516,
                0.16148663,
                0.06902629,
                0.047932904,
                0.3768786,
                0.08726837,
                0.0017632673,
                0.015901577,
                0.05911348,
                0.0051563075,
                0.0119506875,
                0.0035308346,
                0.0067418613,
                0.025824033,
                0.025430191,
                0.031540096,
                0.025876058,
                0.029122423,
                0.0142448,
                0.065302566,
                0.025341678,
                0.015630895,
                0.008783571,
                0.0027889134,
                4.7239166e-5,
                0.009963634,
                0.016748942,
                0.02400974,
                0.016887216,
                0.02310129,
              ],
            },
            {
              impact: 0.24765526,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0028325133,
                0.014819332,
                0.037312247,
                0.022830801,
                0.002542603,
                0.0764937,
                0.057891577,
                0.45270157,
                0.121772744,
                0.036512643,
                0.038250756,
                0.021605592,
                0.059760544,
                0.006756529,
                0.03144361,
                0.0047582216,
                0.0014095786,
                0.011495389,
                0.035628304,
                0.009322949,
                0.0043180156,
                0.021368604,
                0.005620748,
                0.02668714,
                0.026424091,
                0.0045169396,
                0.003680298,
                0.011950556,
                0.007874404,
                0.014918948,
                0.023139594,
                0.0216042,
                0.0042897332,
                6.4950675e-4,
              ],
            },
            {
              impact: 0.22432111,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.009210036,
                7.2743e-4,
                6.2408764e-4,
                0.001758701,
                0.0023376946,
                7.220483e-4,
                6.978919e-4,
                0.006265176,
                0.0051115453,
                4.6697274e-4,
                8.5882173e-4,
                0.0010457337,
                0.003160228,
                2.111446e-4,
                0.006875291,
                0.0038982488,
                0.0019203395,
                0.0033552865,
                0.0023868915,
                0.0012146357,
                0.0011524814,
                8.6232024e-4,
                2.7639177e-4,
                0.003197327,
                0.0031738237,
                0.0019161396,
                1.7640034e-4,
                0.007600004,
                0.0010313647,
                0.008637836,
                0.03053318,
                0.003490663,
                0.031250462,
                0.0019247278,
              ],
            },
            {
              impact: -0.1710129,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.001290713,
                0.0045566056,
                1.6749228e-4,
                0.0013763438,
                0.0012829426,
                0.0017596728,
                0.0013190808,
                2.3154171e-4,
                2.1240878e-5,
                8.280881e-4,
                0.0017350112,
                0.00180241,
                0.0047468897,
                0.0022288787,
                0.022208033,
                5.3678243e-4,
                0.0027630467,
                0.009446463,
                0.0037123538,
                0.015672289,
                0.026466588,
                0.01726503,
                0.007425747,
                0.01509409,
                0.008454517,
                0.023606867,
                0.07319541,
                0.073075935,
                0.030505197,
                0.0034449543,
                0.0196236,
                0.037800804,
                0.063808724,
                0.0130189555,
              ],
            },
            {
              impact: -0.14671572,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.017878067,
                0.06156369,
                0.068931915,
                9.384963e-4,
                0.043023985,
                0.0185774,
                0.011252925,
                0.22977479,
                0.093817554,
                0.14301588,
                0.08864939,
                0.022286963,
                0.062920175,
                0.054028682,
                0.017641699,
                0.008635523,
                0.004441034,
                6.0887967e-4,
                0.005099811,
                0.06468409,
                0.09322599,
                0.051546946,
                0.0021602067,
                0.04260756,
                0.0103705395,
                0.0019246422,
                0.04290762,
                0.004773656,
                0.0042142128,
                0.013847012,
                9.914188e-4,
                0.004912352,
                7.089724e-4,
                0.006488176,
              ],
            },
          ],
          scores: [0.1689477562904358, 0.06792549788951874, 0.7631267309188843],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "location",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              0.22774833,
              0.32901013,
              1.0,
              0.7370907,
              0.1561575,
              0.49117574,
              0.3736899,
              0.5319858,
              0.3002356,
              0.14707935,
              0.09377482,
              0.02160995,
              0.011027153,
              0.05978619,
              0.19263996,
              0.33090967,
              0.13574398,
              0.11189082,
              0.19318299,
              0.14332299,
            ],
            lookAt: [
              0.46927804,
              0.4034434,
              0.37278342,
              0.7688899,
              0.45167068,
              1.0,
              0.11801737,
              0.019179113,
              0.11011449,
              0.009509122,
              0.04103777,
              0.002487168,
              0.028896222,
              0.017166894,
              0.22974159,
              0.026450202,
              0.03666714,
              0.0491049,
              0.5130216,
              0.27431273,
            ],
          },
          aspectTokens: ["location"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.37217337,
                0.14485331,
                0.11278254,
                0.06339397,
                0.022086099,
                0.18973584,
                0.034487862,
                0.116444014,
                0.14757627,
                0.072301134,
                0.17743464,
                0.12208262,
                0.2857153,
                0.124417186,
                0.33320758,
                0.50578237,
                0.1772959,
                0.20647576,
                0.51394445,
                1.0,
              ],
            },
            {
              impact: 0.58384305,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.15671894,
                0.14836018,
                0.13523795,
                0.11144864,
                0.21677485,
                0.16351338,
                0.049865812,
                0.17203261,
                0.20787911,
                0.023218602,
                0.036201775,
                0.0038280284,
                0.0144311115,
                0.031235958,
                0.037517488,
                0.016052393,
                0.0059880894,
                0.008098304,
                0.0022874088,
                0.013689136,
              ],
            },
            {
              impact: 0.5324179,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.15414481,
                0.21145952,
                0.107165396,
                0.5575259,
                0.022194704,
                0.091571845,
                0.006231965,
                0.060057893,
                0.036870245,
                0.0014723109,
                0.009581511,
                0.0046563116,
                0.021271806,
                9.721141e-4,
                0.03211033,
                0.010807563,
                0.0059992494,
                0.020491326,
                0.027198575,
                0.0083820075,
              ],
            },
            {
              impact: -0.38659817,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.785982,
                0.267562,
                0.5396636,
                0.43246934,
                0.05345129,
                0.44061282,
                0.06507212,
                0.16709332,
                0.08620787,
                0.07454938,
                0.15404497,
                0.02487183,
                0.009275015,
                0.018947264,
                0.06594936,
                0.053000357,
                0.016288998,
                0.0111567145,
                0.0020464086,
                0.04800481,
              ],
            },
            {
              impact: 0.36408126,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.10810503,
                0.122246295,
                0.14848337,
                0.1899556,
                0.07778528,
                0.053310696,
                0.009519278,
                0.026850477,
                0.02029572,
                0.024910623,
                0.0069426396,
                0.008473975,
                0.00683475,
                6.242319e-4,
                0.013298935,
                0.0065060393,
                0.013194626,
                0.022027228,
                0.020301936,
                0.018826013,
              ],
            },
            {
              impact: -0.27384916,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.014879459,
                0.035327397,
                0.018700179,
                0.02697475,
                0.012639833,
                0.007972413,
                0.026198432,
                0.02219726,
                0.23030983,
                0.056187652,
                0.32482585,
                0.093085766,
                0.32773677,
                0.1765304,
                0.23072541,
                0.13127843,
                0.15885763,
                0.016990878,
                0.08932428,
                0.22501016,
              ],
            },
            {
              impact: 0.24395107,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.19804023,
                0.22688928,
                0.09191148,
                0.009104242,
                0.03509107,
                0.011003538,
                9.410158e-4,
                0.023734504,
                0.0060482407,
                0.020320047,
                0.0070704175,
                0.011539927,
                0.0067177024,
                0.003999607,
                0.02715937,
                0.0015456284,
                0.017016806,
                0.013228244,
                0.010178186,
                0.017900195,
              ],
            },
          ],
          scores: [
            0.18450161814689636,
            0.09030785411596298,
            0.7251905202865601,
          ],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "location",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.22490719642673684, 0.42442475177904615, 0.350668051794217],
      sentiment: "negative",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
    {
      aspect: "public",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.37727875,
              0.060620937,
              0.10251878,
              0.510794,
              0.06427629,
              0.3772931,
              0.0043189744,
              0.2325583,
              0.3020061,
              0.3040428,
              0.07027222,
              0.24892284,
              0.022445591,
              0.041341417,
              0.007686434,
              1.0,
            ],
            lookAt: [
              0.025997773,
              0.017679362,
              0.0318799,
              0.026661146,
              0.023008853,
              0.05387246,
              0.025940746,
              0.0110594435,
              0.02469864,
              0.0051753013,
              0.26283944,
              0.50383097,
              0.11217404,
              0.19626963,
              0.29358444,
              1.0,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.12103942,
                0.12198055,
                0.06517466,
                0.13002054,
                0.327429,
                0.36996296,
                0.18871756,
                0.32636055,
                0.20613998,
                0.344298,
                0.23357093,
                1.0,
                0.22940241,
                0.50331056,
                0.618475,
                0.50940317,
              ],
            },
            {
              impact: 0.32494476,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.07842872,
                0.07644954,
                0.08086028,
                0.00934154,
                0.09837528,
                0.028649751,
                0.0030185052,
                0.01791876,
                0.021088153,
                0.008458201,
                0.4075319,
                0.23414631,
                0.2974992,
                0.18913132,
                0.48237875,
                0.3028817,
              ],
            },
            {
              impact: -0.29088256,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.01632817,
                0.007318493,
                0.015911281,
                0.008573342,
                0.096737385,
                0.04539372,
                0.023422487,
                0.027954783,
                0.003945591,
                0.046957303,
                0.2775939,
                0.077559404,
                0.17517528,
                0.19034293,
                0.44216883,
                0.31414348,
              ],
            },
            {
              impact: 0.26291287,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.04729532,
                0.15676674,
                0.02658474,
                0.060623497,
                0.051287737,
                0.016630236,
                0.035853136,
                0.091128126,
                0.20002982,
                0.17985846,
                0.14390111,
                0.18265088,
                0.07991696,
                0.20775032,
                0.20928235,
                0.48836654,
              ],
            },
            {
              impact: -0.21670415,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.029062714,
                0.0088145975,
                0.035372414,
                0.029324625,
                0.09345219,
                0.032997187,
                0.021294279,
                0.04616124,
                0.020187786,
                0.0645058,
                0.25206578,
                0.004814772,
                0.030660313,
                0.7058025,
                0.043900844,
                0.49466422,
              ],
            },
          ],
          scores: [
            0.721347987651825,
            0.060318976640701294,
            0.21833296120166779,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.105931304,
              0.719471,
              0.22200738,
              0.07947591,
              0.9951582,
              0.5272947,
              0.545875,
              0.1941675,
              0.2949967,
              0.076804355,
              0.7454208,
              0.029710505,
              0.64042455,
              0.2588594,
              0.1767476,
              0.03808738,
              0.055768136,
              0.7636603,
              0.10561317,
              0.24815649,
              1.0,
              0.078136384,
            ],
            lookAt: [
              0.023079948,
              0.09001189,
              0.05284234,
              0.042163074,
              0.058817282,
              0.3297824,
              0.9526762,
              0.5152907,
              0.81881166,
              0.027376091,
              0.06372636,
              0.031719483,
              0.15675381,
              0.26805037,
              1.0,
              0.28447664,
              0.2958944,
              0.16518368,
              0.07053942,
              0.04083441,
              0.02043672,
              0.0818903,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.020633599,
                0.10307724,
                0.1871721,
                0.031723976,
                0.068242215,
                0.19792299,
                0.0020742153,
                0.103003904,
                0.032764416,
                0.19264361,
                0.09323879,
                0.0061780666,
                0.045712978,
                0.09500329,
                0.030333623,
                0.007292342,
                0.062923044,
                0.29374304,
                0.012161369,
                0.030475948,
                0.023956463,
                0.060113776,
              ],
            },
            {
              impact: -0.7463418,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.016487876,
                0.945816,
                0.15829283,
                0.17107321,
                0.51381576,
                0.8483423,
                0.5999964,
                0.22512782,
                0.650753,
                0.32067713,
                0.21576962,
                0.09085535,
                0.1481403,
                0.097467646,
                0.32634363,
                0.053658746,
                0.3898929,
                0.57326055,
                0.013717067,
                0.031316716,
                0.0060727634,
                0.0807696,
              ],
            },
            {
              impact: -0.73696697,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.08748277,
                0.047033973,
                0.06427354,
                0.04214369,
                0.06858922,
                0.034434896,
                0.01380556,
                0.08326446,
                0.19585547,
                0.07354881,
                0.079061195,
                0.04072485,
                0.041950386,
                0.25074884,
                0.2915329,
                0.51078,
                1.0,
                0.0011328409,
                0.05573629,
                0.09219493,
                0.15478306,
                0.27149767,
              ],
            },
            {
              impact: -0.7136362,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.012983987,
                0.0015106966,
                0.10885897,
                0.019070683,
                0.20152412,
                0.429504,
                0.017327603,
                0.02550464,
                0.059877813,
                0.013440856,
                0.035463244,
                0.028540729,
                0.014756567,
                0.034965597,
                0.036072213,
                0.009236141,
                0.04841725,
                0.043341044,
                0.018644633,
                0.0013751822,
                0.016862009,
                0.081299864,
              ],
            },
            {
              impact: 0.4053054,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.015090423,
                0.048003104,
                0.037699856,
                0.0017542986,
                0.016783802,
                0.014568942,
                0.0037578128,
                0.020400014,
                0.16782174,
                0.018254898,
                0.055413358,
                0.06644647,
                0.14200838,
                0.14312002,
                0.008053288,
                0.049339194,
                0.004867187,
                0.043366637,
                0.079665475,
                0.009771869,
                0.036273383,
                0.064809166,
              ],
            },
            {
              impact: -0.34722784,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.02018737,
                0.30624068,
                0.040287714,
                0.0024646935,
                0.4601946,
                0.083484426,
                0.0018659856,
                0.08162728,
                0.032728884,
                0.020604556,
                0.06130768,
                0.0018410006,
                0.020434545,
                0.09552265,
                0.015149873,
                0.008648113,
                7.2962715e-4,
                0.044185385,
                0.005263425,
                0.0018151639,
                0.0034312718,
                0.08598642,
              ],
            },
            {
              impact: -0.26037925,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.024455858,
                0.023613617,
                0.023418162,
                0.020291768,
                0.013024218,
                0.0040168213,
                0.03365269,
                0.009964053,
                0.0033755437,
                0.06521166,
                0.023253156,
                0.008118316,
                0.02669906,
                0.30405244,
                0.72650695,
                0.031972606,
                0.25749198,
                0.03170405,
                0.001986816,
                0.023651166,
                0.054766014,
                0.11405437,
              ],
            },
            {
              impact: 0.2120143,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.03758872,
                0.0012953841,
                0.010361382,
                0.015706351,
                0.08293542,
                0.0010173176,
                0.10569594,
                0.0040738806,
                0.35094395,
                0.08162852,
                0.16548343,
                0.00964002,
                0.16630983,
                0.036825582,
                0.050085153,
                0.023193238,
                0.041204643,
                0.027324246,
                0.08873937,
                0.03610931,
                0.0012371817,
                0.009692165,
              ],
            },
            {
              impact: 0.20609652,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.04846221,
                0.02109038,
                0.017938906,
                0.034021657,
                0.08988823,
                0.022647453,
                0.009678732,
                0.018368417,
                0.065008834,
                0.015420209,
                0.04186472,
                0.03000191,
                0.016704522,
                0.006037427,
                0.01379266,
                0.008041528,
                0.02173195,
                0.02907113,
                0.0116366455,
                0.031626098,
                0.07492635,
                0.043487046,
              ],
            },
          ],
          scores: [
            0.5113584995269775,
            0.4647026062011719,
            0.023938873782753944,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              0.7847833,
              0.6535989,
              0.41251746,
              0.5966595,
              0.12066343,
              0.87685126,
              0.41442364,
              0.13445662,
              0.2684617,
              0.033510238,
              0.2346662,
              0.14901638,
              0.09145558,
              0.29902804,
              0.6707257,
              1.0,
              0.23766083,
              0.69283795,
              0.8269204,
            ],
            lookAt: [
              0.19923066,
              0.32770786,
              0.10124675,
              0.04833265,
              1.0,
              0.55376107,
              0.9309476,
              0.47103018,
              0.25084332,
              0.121022694,
              0.124123916,
              0.14250451,
              0.3339976,
              0.18281657,
              0.10633667,
              0.35417768,
              0.24141695,
              0.46714914,
              0.929535,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.13978685,
                0.008861816,
                0.033335682,
                0.40268663,
                0.5369168,
                0.19593962,
                0.13444917,
                0.00216781,
                0.0062043187,
                0.048592325,
                0.009371176,
                0.0060629104,
                0.032199405,
                0.012689913,
                0.022179905,
                0.07588152,
                0.029335152,
                0.030157225,
                0.17568673,
              ],
            },
            {
              impact: -0.8762321,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.022959981,
                0.013678215,
                0.04127087,
                0.049814835,
                0.031792972,
                0.017405944,
                0.09950182,
                0.023690643,
                1.0,
                0.042232722,
                0.036802493,
                0.006502785,
                0.0010633911,
                0.0066960175,
                0.010422943,
                0.03414159,
                0.031295665,
                0.13036555,
                0.044806868,
              ],
            },
            {
              impact: -0.68057644,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.0745203,
                0.054742374,
                0.07002994,
                0.028008701,
                0.02790128,
                0.38782617,
                0.17670125,
                0.14762244,
                0.27932277,
                0.019348716,
                0.005886595,
                0.023314282,
                0.038749423,
                0.0067515993,
                0.018064886,
                0.015290125,
                0.029300964,
                0.021731006,
                0.13914457,
              ],
            },
            {
              impact: 0.49047652,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.0030956806,
                0.047854465,
                0.016528318,
                0.0075544775,
                0.010283782,
                0.11435563,
                0.037272602,
                0.26233202,
                0.019846082,
                0.05403644,
                0.027962478,
                0.016908279,
                0.012794895,
                0.00631105,
                0.010095977,
                0.3318108,
                0.18327788,
                0.0977931,
                0.034063444,
              ],
            },
            {
              impact: 0.48662567,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.025376743,
                0.0031890091,
                0.01770648,
                0.03888237,
                0.0021872234,
                0.0030863076,
                0.052815504,
                0.08934361,
                0.040651314,
                0.010346017,
                0.041656286,
                0.018167095,
                0.022983577,
                0.009114435,
                0.0104535865,
                0.0639978,
                0.028523952,
                0.09477039,
                0.041924898,
              ],
            },
            {
              impact: 0.46217644,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.04548312,
                0.43283838,
                0.26832965,
                0.1365044,
                0.031138534,
                0.4783019,
                0.008750203,
                0.005207579,
                0.0036673015,
                0.026137514,
                0.038176395,
                0.040051356,
                0.0751917,
                0.044967737,
                0.08311028,
                0.031061413,
                0.017319627,
                0.05402367,
                0.040256426,
              ],
            },
            {
              impact: 0.37117904,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.017669719,
                0.020791637,
                0.0012928221,
                0.005635515,
                0.0070739496,
                0.015175244,
                0.0055006635,
                0.058213614,
                0.0014456494,
                0.07514251,
                0.23314169,
                0.05135425,
                0.016277421,
                0.06199163,
                0.011054835,
                0.0793024,
                0.06966947,
                0.04084219,
                0.03184611,
              ],
            },
            {
              impact: 0.3067372,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.09514196,
                0.26462075,
                0.17523192,
                0.32298422,
                0.23494592,
                0.12242308,
                0.055560477,
                0.0577713,
                0.021024795,
                0.00531284,
                0.023177747,
                0.002875978,
                0.036061425,
                0.016754003,
                0.014380545,
                0.053049523,
                0.0036076014,
                0.014735456,
                0.006633507,
              ],
            },
            {
              impact: 0.2999041,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.118137546,
                0.06615257,
                0.10205779,
                0.07073044,
                0.008364509,
                0.13382496,
                0.009176042,
                0.03910748,
                0.0041781315,
                0.04089589,
                0.03966644,
                0.013060605,
                0.023903705,
                0.011820316,
                0.041761998,
                0.03947659,
                0.004296463,
                0.025280252,
                0.03427736,
              ],
            },
          ],
          scores: [
            0.6140711307525635,
            0.14901915192604065,
            0.23690976202487946,
          ],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.13771115,
              0.9858662,
              0.22989364,
              0.27023008,
              0.44269985,
              0.100988016,
              0.2576196,
              0.3312858,
              0.04076026,
              0.2802778,
              0.14002119,
              0.15416816,
              0.49313402,
              0.27948666,
              0.056240708,
              0.23243843,
              1.0,
              0.29696718,
              0.71851856,
            ],
            lookAt: [
              0.015018345,
              0.14482126,
              0.15119205,
              0.11090396,
              0.017627325,
              0.0784206,
              0.26635468,
              0.048845816,
              0.016684862,
              0.0029828954,
              0.048961103,
              0.032227214,
              0.082174495,
              0.35911882,
              0.20781015,
              0.5152359,
              0.0524711,
              0.19490218,
              1.0,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.20321526,
                0.26765862,
                0.22949842,
                0.073297575,
                0.009486779,
                0.19384529,
                0.15373288,
                0.11577475,
                0.23619686,
                0.27196592,
                0.3026297,
                0.014084439,
                0.07221992,
                1.0,
                0.03426453,
                0.18499707,
                0.02771403,
                0.0051910034,
                0.011903244,
              ],
            },
            {
              impact: 0.5023134,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.017615134,
                0.006522566,
                0.0018919352,
                0.020059213,
                0.003321308,
                0.03849849,
                0.004011075,
                0.0015971117,
                4.3920605e-4,
                0.0053120186,
                0.034176916,
                0.0032606346,
                0.021475017,
                0.0225255,
                9.3251537e-4,
                0.07013826,
                0.05217697,
                0.10716971,
                0.027558796,
              ],
            },
            {
              impact: -0.46068987,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0034118749,
                0.043708272,
                0.08902929,
                0.020615956,
                0.07767126,
                0.00468742,
                0.2636963,
                0.046682436,
                0.10371747,
                0.04784716,
                0.120707184,
                0.0071509616,
                0.025196409,
                0.59936196,
                0.25860137,
                0.6330741,
                0.13235876,
                0.12544537,
                0.24865854,
              ],
            },
            {
              impact: -0.24201214,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0026942352,
                0.001255301,
                0.028079003,
                0.011543032,
                0.08120685,
                0.009459979,
                0.06135936,
                0.032036725,
                0.08245259,
                0.07558502,
                0.19280395,
                0.014625225,
                0.14985381,
                0.0035548229,
                0.06355394,
                0.026106073,
                0.14925826,
                0.21186765,
                0.06350665,
              ],
            },
            {
              impact: 0.20664923,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.399583,
                0.02098416,
                0.16982913,
                0.13521677,
                0.37877533,
                0.058298018,
                0.053312514,
                0.05439219,
                0.027878685,
                0.022111325,
                0.05364646,
                0.006408088,
                0.02374417,
                0.12627931,
                0.026043538,
                0.09957156,
                0.04210535,
                0.027183315,
                0.11466745,
              ],
            },
            {
              impact: -0.18856217,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.036607377,
                0.03612676,
                0.147374,
                0.08299625,
                0.55157447,
                0.038862597,
                0.0034910017,
                0.004447018,
                0.01698611,
                0.031793863,
                0.010466723,
                0.0067661842,
                0.017452072,
                0.018981487,
                0.003284811,
                0.0176047,
                0.0020079624,
                0.0038789285,
                0.030834714,
              ],
            },
          ],
          scores: [
            0.5166508555412292,
            0.35401004552841187,
            0.12933917343616486,
          ],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.08912537,
              0.12863085,
              0.11150713,
              0.08640813,
              0.08946022,
              0.004466701,
              0.20948444,
              0.05428369,
              0.1761348,
              0.19401146,
              1.0,
              0.5909197,
              0.13630275,
              0.27900288,
              0.48980576,
              0.15743645,
              0.23868825,
              0.10843412,
              0.33918598,
              0.043341722,
              0.008268576,
              0.012068806,
              0.12552464,
            ],
            lookAt: [
              0.017385107,
              0.07132194,
              0.040298145,
              0.0426353,
              0.014142612,
              0.03990087,
              0.565063,
              0.2704927,
              0.024383118,
              0.0061205807,
              0.12388898,
              1.0,
              0.28011483,
              0.18271871,
              0.11162419,
              0.5391496,
              0.48515362,
              0.08872177,
              0.26063713,
              0.0035022383,
              0.21299605,
              0.28320664,
              0.55294615,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0020724416,
                0.01499484,
                0.014009929,
                0.015736498,
                0.011304943,
                0.0136842355,
                0.08678716,
                0.002787727,
                0.070305355,
                0.052665137,
                0.21827237,
                0.09375605,
                0.03887798,
                0.065093994,
                0.091618896,
                0.12128229,
                0.09306939,
                0.0034219797,
                0.03197681,
                0.053467646,
                0.10244824,
                0.09863771,
                0.16945212,
              ],
            },
            {
              impact: 0.97871184,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.056858756,
                0.0298275,
                0.008607877,
                0.017518448,
                0.009791885,
                0.017726196,
                0.07632006,
                0.071798034,
                0.020650867,
                0.02171684,
                0.07320924,
                0.12255755,
                9.5218426e-4,
                0.017076004,
                0.0014701609,
                0.06693902,
                0.1871508,
                0.016276836,
                0.21241325,
                0.020883735,
                0.11825764,
                0.27111727,
                0.015432249,
              ],
            },
            {
              impact: 0.95875335,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.010666982,
                0.003682109,
                0.0037008852,
                0.005845482,
                0.009013382,
                0.009246692,
                0.0061242688,
                0.03527341,
                0.03516211,
                0.036954906,
                0.0097204875,
                0.15535207,
                8.989546e-4,
                0.06835702,
                0.044745687,
                0.31553504,
                0.022488298,
                0.121308126,
                0.23751155,
                0.3330347,
                0.048806768,
                0.067405336,
                0.16181046,
              ],
            },
            {
              impact: 0.8262991,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.012439194,
                0.011089716,
                9.1456866e-5,
                0.0075237723,
                0.021661995,
                0.013551322,
                0.009091512,
                0.013868549,
                0.045475513,
                0.020348696,
                0.036139045,
                0.21025825,
                0.104084685,
                0.019873673,
                0.1256416,
                1.0,
                0.08102413,
                0.15389456,
                0.42380565,
                0.4614293,
                0.14441033,
                0.14157018,
                0.4320744,
              ],
            },
            {
              impact: 0.6873942,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.03518992,
                0.026004892,
                0.019751206,
                0.0062291264,
                4.8541377e-4,
                0.012771166,
                0.013319408,
                0.009396068,
                0.115189716,
                0.0037181368,
                0.084037274,
                0.48285717,
                0.11961529,
                0.04716438,
                0.2622857,
                0.0781351,
                0.49493757,
                0.13813822,
                0.47286767,
                0.08075936,
                0.09415521,
                0.115747005,
                0.3456448,
              ],
            },
            {
              impact: 0.43025628,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.004801278,
                0.008941546,
                0.0071060346,
                0.0071465163,
                0.007112713,
                0.019654838,
                0.021105913,
                0.029019527,
                0.0025580737,
                0.007723155,
                0.059026662,
                0.087708265,
                0.033505082,
                0.018696867,
                8.7057555e-4,
                0.0898484,
                0.1588602,
                0.04032121,
                0.17638946,
                0.005347447,
                0.18502083,
                0.07758784,
                0.34211162,
              ],
            },
          ],
          scores: [0.0778912827372551, 0.9102718830108643, 0.01183677464723587],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "negative",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.2492136,
              0.23013562,
              0.010542594,
              0.08132493,
              0.067388736,
              0.18959479,
              0.2089855,
              0.08847214,
              0.018585708,
              6.7009294e-4,
              0.13851064,
              0.03335593,
              0.24402095,
              0.2270317,
              0.33497995,
              0.04200273,
              0.13437527,
              0.08847873,
              0.21448652,
              0.18737999,
              0.49175614,
              0.06800575,
              0.10387671,
              1.0,
              0.17997174,
              0.11528289,
              0.08236122,
              0.12921348,
              0.010526801,
              0.008871809,
              0.38346463,
              0.09051438,
              0.0542052,
              0.8396443,
            ],
            lookAt: [
              0.032475736,
              0.33417624,
              0.19997188,
              0.10207672,
              0.035773125,
              0.100702316,
              0.61703014,
              0.025767157,
              0.10639917,
              0.1615525,
              0.0902033,
              0.09948906,
              0.059246566,
              1.0,
              0.2627153,
              0.11498211,
              0.10199129,
              0.09561533,
              0.17344569,
              0.014064806,
              0.18956913,
              0.04472699,
              0.11012902,
              0.16264224,
              0.12522078,
              0.32686576,
              0.07256221,
              0.62610817,
              0.04886965,
              0.27773595,
              0.05026285,
              0.087420315,
              0.15994981,
              0.15800779,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.097323224,
                0.06797984,
                0.31131628,
                0.1610489,
                0.017492674,
                0.13758563,
                0.1907235,
                0.3479767,
                0.31462365,
                0.3959921,
                0.2057985,
                0.13583356,
                0.26397347,
                0.5323343,
                0.25965017,
                0.09109727,
                0.081523746,
                0.08037521,
                0.009340369,
                0.083391964,
                0.12334306,
                0.09886877,
                0.033381805,
                0.10405278,
                0.16497289,
                0.28012437,
                0.18662715,
                0.35294512,
                0.085002825,
                0.17765123,
                0.042986166,
                0.007605127,
                0.06922997,
                0.36195025,
              ],
            },
            {
              impact: -0.9253342,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.16697617,
                0.8853119,
                0.4824368,
                0.0037173957,
                0.058274984,
                0.08461654,
                0.19930623,
                0.18670891,
                0.5279719,
                0.2054225,
                0.013619802,
                0.068422936,
                0.09408869,
                0.11000229,
                0.036108162,
                0.028071625,
                0.004330167,
                0.025933636,
                0.014372417,
                0.013280824,
                0.008787786,
                0.03196553,
                0.0114059,
                0.023139657,
                0.09376693,
                0.06469283,
                0.0046386705,
                0.04888864,
                0.05069772,
                0.0032567324,
                0.021081952,
                0.005325968,
                0.012344385,
                0.12490118,
              ],
            },
            {
              impact: 0.6422375,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.057827152,
                0.019087994,
                0.019886307,
                0.013161873,
                0.0012425514,
                0.01496097,
                0.0050768484,
                0.048144266,
                0.018853227,
                0.008172445,
                0.009116513,
                0.008420226,
                0.0146020055,
                0.004173293,
                0.0673798,
                0.0075267246,
                0.004251862,
                0.02659987,
                0.030412804,
                0.015327686,
                0.002727722,
                0.0047544306,
                0.0015237033,
                0.01057573,
                0.0017889024,
                0.05793209,
                0.097878814,
                0.0055114822,
                0.091264464,
                0.041997075,
                0.12796703,
                0.17313296,
                0.15606713,
                0.039825276,
              ],
            },
            {
              impact: 0.48883453,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                4.9895776e-4,
                0.0010830249,
                0.007822119,
                0.0064640422,
                0.0014138247,
                0.02075928,
                0.0013134587,
                0.0049082222,
                0.0025894225,
                0.0046998803,
                0.0031318874,
                0.0111880815,
                0.008144654,
                0.0147855,
                0.03960417,
                1.20315475e-4,
                0.010887782,
                9.488162e-4,
                0.0014679484,
                0.009238136,
                0.035258453,
                0.016979186,
                0.013737704,
                0.008544483,
                0.016010161,
                0.024825454,
                0.091829464,
                0.03169949,
                0.10229297,
                0.27499595,
                0.5170993,
                0.013582434,
                0.17292804,
                0.10961235,
              ],
            },
            {
              impact: 0.45178303,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.010433216,
                0.00224957,
                0.012683199,
                0.011328282,
                0.0021502594,
                0.0024328437,
                0.008190135,
                0.002012141,
                2.2086097e-4,
                0.010225557,
                0.012373036,
                0.0050610085,
                7.3341234e-4,
                0.024437329,
                0.0922673,
                0.002735094,
                0.01663233,
                0.03605089,
                0.031833388,
                0.04037537,
                0.06961972,
                0.114593446,
                0.044065796,
                0.022452645,
                0.033011597,
                0.014212191,
                0.15007189,
                0.34146512,
                0.10496198,
                0.07363947,
                0.22497404,
                0.21255462,
                0.43555242,
                0.065791234,
              ],
            },
            {
              impact: -0.42996687,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.00717161,
                0.1179666,
                0.044770844,
                0.006774754,
                1.5485354e-4,
                0.012903743,
                0.01469701,
                0.011939614,
                0.01597448,
                0.002142447,
                0.017105503,
                0.0011503585,
                0.019042771,
                0.0268742,
                0.13419917,
                0.024145966,
                0.068145595,
                0.052704606,
                0.0013198633,
                0.042613782,
                0.0159762,
                0.00459698,
                0.022370733,
                0.011433715,
                0.027442498,
                0.057534233,
                0.19272973,
                0.17299072,
                0.102015704,
                0.0078160595,
                0.032391377,
                0.0024416498,
                0.03703155,
                0.07012571,
              ],
            },
            {
              impact: 0.3466345,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.011764719,
                0.03140377,
                0.0014216217,
                0.0043159323,
                0.05027099,
                0.008106172,
                0.008760338,
                0.009038827,
                0.021655718,
                0.015338875,
                0.0020688854,
                0.005319584,
                0.054594968,
                0.021232232,
                0.020927252,
                0.008917154,
                0.030689405,
                0.006751689,
                0.021456813,
                0.2994925,
                0.17185551,
                0.05713069,
                0.012913498,
                0.0072761336,
                0.022426954,
                0.05740936,
                0.22841533,
                0.30062643,
                0.1677106,
                0.06759288,
                0.016380731,
                0.0095083825,
                0.00644571,
                0.032015234,
              ],
            },
            {
              impact: -0.2959052,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.06669283,
                0.18700299,
                0.17305207,
                0.018858965,
                0.05378709,
                0.1776726,
                0.09276144,
                0.018002408,
                0.16544281,
                0.04728097,
                0.15500951,
                0.016688824,
                0.21001731,
                0.1848596,
                0.0426457,
                0.015806131,
                0.026311338,
                0.005810612,
                0.008210214,
                0.1063022,
                0.1767415,
                0.15068042,
                0.01019345,
                0.070566796,
                3.0362373e-4,
                0.0044012405,
                0.033102933,
                0.013068578,
                0.0016852082,
                0.0056921938,
                6.595556e-4,
                0.005214881,
                0.015002838,
                0.01159644,
              ],
            },
            {
              impact: 0.2936978,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0029376189,
                0.028925858,
                0.002679506,
                0.027705155,
                0.003225832,
                0.008765459,
                0.0023184738,
                0.044673216,
                0.0057516997,
                0.006660527,
                0.015505599,
                0.03203666,
                0.021667007,
                0.00320684,
                0.0026840144,
                0.0051509575,
                0.0062322356,
                3.183862e-4,
                0.005674017,
                0.087640665,
                0.0221389,
                0.017157594,
                0.0154768005,
                0.007340619,
                0.091880865,
                3.755544e-4,
                0.026756056,
                0.0052135205,
                0.0060798447,
                0.011096926,
                0.019289559,
                3.9561867e-4,
                0.051521372,
                0.0099191405,
              ],
            },
            {
              impact: -0.28340012,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.01420231,
                0.13968468,
                0.018204682,
                0.028856734,
                0.020087104,
                0.14624347,
                0.109146744,
                1.0,
                0.20311609,
                0.16955331,
                0.043400444,
                0.0263323,
                0.08804103,
                0.008788979,
                0.032643083,
                0.019110909,
                0.020653747,
                0.01073782,
                0.05975117,
                0.025076328,
                0.0138304625,
                0.029483972,
                0.011357742,
                0.028325412,
                0.013752888,
                0.012730899,
                0.0218148,
                0.00954776,
                0.022706546,
                0.02441748,
                0.040106643,
                0.011108503,
                0.009632843,
                0.01971557,
              ],
            },
            {
              impact: -0.25544506,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.018651599,
                0.15954895,
                0.28682083,
                0.73141044,
                0.03724208,
                0.15500163,
                0.189222,
                0.06814535,
                0.040934686,
                0.08992817,
                0.0010797092,
                0.06975871,
                0.08328805,
                0.0080437185,
                0.007808352,
                0.008520321,
                0.002069779,
                0.010271282,
                0.036348935,
                0.026600752,
                0.017112633,
                0.03201216,
                0.0074105444,
                0.017177051,
                0.017638177,
                8.715768e-5,
                0.009792,
                0.009644136,
                0.0049259965,
                0.006902996,
                0.005944522,
                0.0045021013,
                0.0023325842,
                0.0014158129,
              ],
            },
            {
              impact: -0.22286452,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0026540433,
                0.045284107,
                0.070689745,
                0.0026132984,
                0.0055680494,
                0.0012364208,
                0.005026947,
                0.022447035,
                0.08141841,
                0.017283648,
                0.008559529,
                0.01216311,
                0.010458485,
                0.032530893,
                0.030938081,
                0.010501015,
                0.016195409,
                0.017385714,
                0.01719284,
                0.010090168,
                0.0063031656,
                0.018147286,
                4.301244e-4,
                0.011778009,
                0.03173471,
                0.026772503,
                0.15321907,
                0.09849691,
                0.1750673,
                0.45562643,
                0.07905271,
                0.019291993,
                0.017731743,
                0.05504161,
              ],
            },
            {
              impact: 0.21569721,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.003344807,
                0.09915132,
                0.65273625,
                0.28069538,
                0.12467571,
                0.12787022,
                0.01728787,
                0.01900722,
                0.04926271,
                0.04181459,
                0.043691512,
                0.3801348,
                0.11365485,
                0.03641101,
                0.0059744357,
                2.9723835e-4,
                0.0023444444,
                0.017775945,
                0.009830833,
                0.06475788,
                0.03379345,
                0.0014258989,
                0.021912329,
                0.07940936,
                0.021535337,
                0.005245191,
                1.6150622e-6,
                0.0032728366,
                0.006041343,
                0.04036149,
                0.09429079,
                0.0027469164,
                0.08109534,
                0.011756017,
              ],
            },
            {
              impact: 0.21275838,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0057911435,
                0.004830166,
                0.008009448,
                0.0016809774,
                0.0030517762,
                0.054767475,
                0.007588429,
                0.013248119,
                0.013486174,
                0.008445311,
                0.006599486,
                0.016932169,
                0.0033098233,
                0.027554993,
                0.16554211,
                0.020422813,
                0.036745664,
                0.009843911,
                0.0077765486,
                0.021460768,
                0.034363672,
                0.009367192,
                0.017903741,
                0.0013156815,
                0.11717012,
                0.15415117,
                0.0035087098,
                0.03015372,
                0.06057526,
                0.02178895,
                0.013396917,
                0.007220176,
                5.480162e-4,
                0.001804891,
              ],
            },
            {
              impact: 0.21212679,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0010377318,
                0.008557652,
                0.0022937956,
                0.0018953651,
                0.009560512,
                0.0055853813,
                0.0028727555,
                0.01984149,
                0.044969544,
                0.0030103566,
                0.0027594373,
                0.012969717,
                0.004197252,
                0.008067981,
                0.030660063,
                0.004713368,
                0.011822743,
                0.022810217,
                0.023618685,
                0.008264645,
                0.037754048,
                0.020927044,
                0.004373118,
                0.06256554,
                0.019842237,
                0.055488735,
                0.044445377,
                0.004074597,
                0.0065147807,
                0.006508035,
                0.00575716,
                0.009001298,
                0.07053528,
                0.0011837877,
              ],
            },
            {
              impact: 0.18254024,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.0013315952,
                0.010804064,
                0.022843426,
                0.040418025,
                0.021687282,
                0.040001996,
                0.015395713,
                0.010438796,
                0.0018665724,
                0.008248944,
                0.0120259365,
                0.042701945,
                0.042938974,
                0.027633356,
                0.030864278,
                0.27774146,
                0.19951537,
                6.1509816e-4,
                0.016268335,
                0.013740625,
                0.09683685,
                0.047604375,
                0.011487379,
                0.050289724,
                0.097232714,
                0.064065084,
                0.03962854,
                0.023194185,
                0.019032933,
                0.0018646467,
                0.018107323,
                0.0141721,
                0.028148517,
                0.013030522,
              ],
            },
          ],
          scores: [0.5766926407814026, 0.1362062394618988, 0.2871011197566986],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "public",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              0.9211759,
              0.7100668,
              0.38321495,
              0.1877724,
              0.21346268,
              0.26267713,
              0.1438192,
              0.3434768,
              0.11081635,
              0.0958329,
              0.40582427,
              0.04701031,
              0.2517639,
              0.4479163,
              0.6777101,
              0.08664311,
              0.17917237,
              0.18718366,
              1.0,
              0.9574785,
            ],
            lookAt: [
              0.108498946,
              0.0054090554,
              0.026943646,
              0.04044461,
              0.026411526,
              0.025029808,
              0.008291023,
              0.05338845,
              0.009359796,
              0.017747916,
              0.0017435605,
              0.010221115,
              0.016459089,
              0.0016549322,
              0.07526436,
              0.057378627,
              0.008749696,
              0.018787563,
              2.895136e-4,
              1.0,
            ],
          },
          aspectTokens: ["public"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.38269883,
                0.13296784,
                0.11002057,
                0.080459625,
                0.020806026,
                0.17077155,
                0.033139285,
                0.106500074,
                0.12664233,
                0.070625275,
                0.14480427,
                0.116428204,
                0.26586065,
                0.12161914,
                0.2941547,
                0.13322319,
                0.10844816,
                0.17278157,
                0.12869455,
                1.0,
              ],
            },
            {
              impact: -0.27998847,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.05413225,
                0.07862464,
                0.050854973,
                0.038686123,
                0.09584296,
                0.06840841,
                0.009232323,
                0.0951593,
                0.076552965,
                0.017385377,
                0.018512111,
                0.005112154,
                0.0075035966,
                0.0145641,
                0.009329215,
                0.0024508296,
                9.197339e-4,
                9.39115e-4,
                3.804878e-4,
                0.0066560335,
              ],
            },
            {
              impact: -0.18282853,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.08293982,
                0.07657082,
                0.046171844,
                0.1878411,
                0.02928812,
                0.021412151,
                0.005027577,
                0.031662147,
                0.0036366135,
                5.9158873e-4,
                0.008621275,
                0.0034459576,
                0.013341916,
                8.690564e-4,
                0.008564907,
                0.011181001,
                0.003960565,
                0.004395731,
                0.0044302195,
                0.012794931,
              ],
            },
            {
              impact: -0.106069386,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.10804612,
                0.005196069,
                0.026158847,
                0.059250582,
                0.03506785,
                0.0074726213,
                0.0031663943,
                0.0026237099,
                8.254302e-4,
                0.0092055,
                0.002071977,
                2.4296614e-4,
                0.0047338973,
                3.960212e-4,
                0.010567731,
                0.013291464,
                0.0068851295,
                0.011131642,
                0.010873259,
                0.009111782,
              ],
            },
            {
              impact: 0.09696345,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.014417997,
                0.017712645,
                0.011719632,
                0.0143060535,
                0.015983427,
                0.004054988,
                0.022644058,
                0.015016592,
                0.11696818,
                0.023033744,
                0.21242371,
                0.060137495,
                0.23305602,
                0.13869393,
                0.15395522,
                0.046117373,
                0.10668244,
                0.016263809,
                0.079999134,
                0.16695331,
              ],
            },
            {
              impact: 0.093037166,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.019357398,
                0.050037526,
                0.0013781685,
                0.008793939,
                0.025716638,
                0.025209893,
                0.0017414766,
                0.014541952,
                0.0054415874,
                0.016118743,
                0.0010150027,
                0.0031178456,
                0.011127238,
                0.022145461,
                0.003213381,
                0.14759082,
                0.3097799,
                0.08113115,
                0.09079494,
                0.43649185,
              ],
            },
            {
              impact: -0.07903383,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.10089445,
                0.09938511,
                0.06715802,
                0.038454957,
                0.027030807,
                0.0072367373,
                0.004386911,
                0.012505689,
                0.006837402,
                0.0045151594,
                0.0021372642,
                0.0056727473,
                0.0042860513,
                8.7870656e-5,
                0.01301284,
                0.0033420376,
                0.0033950729,
                0.0055841063,
                0.005283797,
                0.010557357,
              ],
            },
          ],
          scores: [
            0.4904079735279083,
            0.12418073415756226,
            0.38541123270988464,
          ],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "public",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.3576285890910141, 0.45129293320705144, 0.19107847770193448],
      sentiment: "negative",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
    {
      aspect: "breakfast",
      examples: [
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "my",
              "wife",
              "and",
              "i",
              "and",
              "our",
              "4",
              "year",
              "old",
              "daughter",
              "stopped",
              "here",
              "friday",
              "-",
              "sunday",
              ".",
            ],
            comeFrom: [
              0.016668908,
              0.013529133,
              0.14460368,
              0.0049266135,
              0.11370353,
              0.032631326,
              0.09702311,
              0.050666675,
              0.06350124,
              0.13526177,
              0.43690363,
              0.059215203,
              0.621468,
              0.73742974,
              0.32442284,
              1.0,
            ],
            lookAt: [
              0.003408375,
              0.040240835,
              0.0062486203,
              0.012247315,
              0.008057692,
              7.037091e-4,
              0.0058062105,
              0.015216498,
              0.15765846,
              0.2086348,
              0.15000904,
              0.025026195,
              0.042853832,
              0.08797005,
              1.0,
              0.0022712995,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.1709002,
                0.048455656,
                0.08191007,
                0.07044234,
                0.30147347,
                0.34728798,
                0.10602978,
                0.22216341,
                0.10050701,
                0.7616672,
                0.38827726,
                0.7353411,
                0.21671364,
                0.46893713,
                0.33980316,
                0.5721504,
              ],
            },
            {
              impact: 0.45284858,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.09271223,
                0.05881176,
                0.037140563,
                0.07797408,
                0.08283122,
                0.05178137,
                0.09791422,
                0.21662426,
                0.44218192,
                0.37342003,
                0.35917407,
                0.16204628,
                0.27484515,
                0.15806846,
                0.6949062,
                0.53438133,
              ],
            },
            {
              impact: 0.34246412,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.04864543,
                0.044488724,
                0.0624277,
                0.002883519,
                0.0996148,
                0.011808993,
                0.018748656,
                0.013818394,
                0.01494614,
                0.014657834,
                0.36921173,
                0.16742325,
                0.6233567,
                0.07840248,
                0.34055743,
                0.31264699,
              ],
            },
            {
              impact: -0.18818465,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.009646677,
                0.021260234,
                0.038060296,
                0.012441012,
                0.09703616,
                0.036123082,
                0.046021435,
                0.21614018,
                0.044969205,
                0.08673893,
                0.19933598,
                0.1617876,
                0.40447977,
                0.0024409124,
                0.08671425,
                0.26606956,
              ],
            },
            {
              impact: -0.10400272,
              tokens: [
                "my",
                "wife",
                "and",
                "i",
                "and",
                "our",
                "4",
                "year",
                "old",
                "daughter",
                "stopped",
                "here",
                "friday",
                "-",
                "sunday",
                ".",
              ],
              weights: [
                0.1278579,
                0.062217206,
                0.1729373,
                0.111582436,
                0.16618243,
                0.092973374,
                0.04679405,
                0.015733417,
                0.04006873,
                0.3728235,
                0.014666864,
                0.05299605,
                0.051225644,
                0.004302484,
                0.025851026,
                0.06326915,
              ],
            },
          ],
          scores: [
            0.8492311239242554,
            0.029750477522611618,
            0.1210184171795845,
          ],
          subtokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
          ],
          tokens: [
            "[CLS]",
            "my",
            "wife",
            "and",
            "i",
            "and",
            "our",
            "4",
            "year",
            "old",
            "daughter",
            "stopped",
            "here",
            "friday",
            "-",
            "sunday",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "we",
              "arrived",
              "about",
              "midday",
              "and",
              "there",
              "was",
              "a",
              "queue",
              "to",
              "check",
              "in",
              ",",
              "which",
              "seemed",
              "to",
              "take",
              "ages",
              "to",
              "go",
              "down",
              ".",
            ],
            comeFrom: [
              0.07801294,
              0.071043216,
              0.0060413936,
              0.1629496,
              0.07588765,
              0.0030222281,
              0.2835173,
              0.03240795,
              0.06328728,
              0.011665844,
              0.35140175,
              0.0110033,
              0.16046545,
              0.18432632,
              0.08121443,
              0.03198497,
              0.022597129,
              0.27311796,
              0.044479277,
              0.020360943,
              0.14775068,
              1.0,
            ],
            lookAt: [
              0.056737166,
              0.076798946,
              0.018999849,
              0.58411473,
              0.24173304,
              0.09393755,
              0.007700865,
              0.0010564734,
              0.40586013,
              0.0033865042,
              0.3451934,
              0.02534276,
              0.035358958,
              0.089156255,
              0.07060922,
              0.14642335,
              0.20832238,
              0.13627875,
              0.01739864,
              0.047901124,
              0.09603368,
              1.0,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.023411026,
                0.97595775,
                0.4340225,
                0.38649204,
                0.44065073,
                0.5556082,
                0.56226987,
                0.28141886,
                1.0,
                0.37705815,
                0.15344957,
                0.045575287,
                0.2929259,
                0.15325989,
                0.1945059,
                0.03139106,
                0.36478713,
                0.6001742,
                0.028926086,
                0.046546575,
                0.046983164,
                0.0023336117,
              ],
            },
            {
              impact: -0.4709193,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.08267849,
                0.044825796,
                0.02006117,
                0.015971804,
                0.005394988,
                0.020325327,
                0.0184775,
                0.061674573,
                0.07995951,
                0.080303416,
                0.09332868,
                0.012020925,
                0.07383523,
                0.18082725,
                0.22871147,
                0.43449727,
                0.95949924,
                0.049272265,
                0.032394767,
                0.096562244,
                0.12933014,
                0.21600097,
              ],
            },
            {
              impact: -0.4375263,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0343475,
                0.10041225,
                0.14215301,
                0.063748725,
                0.05223674,
                0.015118428,
                0.08197786,
                0.08305303,
                0.077327214,
                0.14024904,
                0.08493923,
                0.033230755,
                0.0506365,
                0.026674164,
                0.025858434,
                0.006486782,
                0.06425174,
                0.21360551,
                0.004395124,
                0.024123015,
                0.0072363648,
                0.08976451,
              ],
            },
            {
              impact: -0.20906514,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.0025259226,
                0.06081684,
                0.055818792,
                0.050938692,
                0.14280486,
                0.28670052,
                0.062949374,
                0.12586327,
                0.064699866,
                4.8066824e-4,
                0.011137099,
                0.034264043,
                0.016047647,
                0.027843144,
                0.0045560217,
                0.007472683,
                0.046350095,
                0.06489109,
                0.002217142,
                8.8104216e-4,
                0.0098539805,
                0.06322744,
              ],
            },
            {
              impact: -0.20795777,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.02541605,
                0.020779176,
                0.023553498,
                0.03454176,
                0.016439773,
                0.0011884457,
                0.024906015,
                0.009266138,
                0.036971852,
                0.06530522,
                0.022856025,
                2.4360004e-4,
                0.034904923,
                0.31830165,
                0.7418252,
                0.017378952,
                0.26954225,
                0.105108626,
                0.002253277,
                0.021401502,
                0.037335705,
                0.12120146,
              ],
            },
            {
              impact: -0.20281263,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.038637243,
                0.100225925,
                0.024987748,
                0.10021937,
                0.00813307,
                0.013945725,
                0.015893899,
                0.012446147,
                0.1026693,
                0.1171435,
                0.35663986,
                0.08491148,
                0.03876333,
                0.21227306,
                0.61052036,
                0.22997655,
                0.36869177,
                0.30889833,
                0.014102084,
                0.72511894,
                0.0961597,
                0.06013929,
              ],
            },
            {
              impact: -0.17563878,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.01486227,
                0.044849843,
                0.0077718906,
                0.061189882,
                0.0802684,
                0.037227105,
                0.04541786,
                0.018446302,
                0.1280819,
                0.05791105,
                0.015968302,
                0.0014287853,
                0.06396241,
                0.0017341628,
                0.5176505,
                0.12726639,
                0.08889728,
                0.25687435,
                0.2581901,
                0.014770914,
                0.12700336,
                0.105804235,
              ],
            },
            {
              impact: -0.16109274,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.033517115,
                0.0057573123,
                0.027081605,
                0.05765913,
                0.04300043,
                0.046864342,
                0.20711128,
                0.16756082,
                0.02332536,
                0.21892597,
                6.807393e-4,
                0.12983385,
                0.062920965,
                0.04798676,
                0.41463917,
                0.04443178,
                0.17611024,
                0.13359505,
                0.044897433,
                0.10547815,
                0.023803893,
                0.027045975,
              ],
            },
            {
              impact: 0.15996873,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.030859457,
                0.08833973,
                0.022229303,
                0.050912518,
                0.006213154,
                3.476949e-4,
                0.003818436,
                0.06699052,
                0.34877965,
                0.04668451,
                0.3145851,
                0.5597148,
                0.08579849,
                0.05484559,
                0.025714818,
                0.0020054718,
                0.011473925,
                0.077131405,
                0.019575031,
                0.040716104,
                0.0074581443,
                0.049740825,
              ],
            },
            {
              impact: -0.1276079,
              tokens: [
                "we",
                "arrived",
                "about",
                "midday",
                "and",
                "there",
                "was",
                "a",
                "queue",
                "to",
                "check",
                "in",
                ",",
                "which",
                "seemed",
                "to",
                "take",
                "ages",
                "to",
                "go",
                "down",
                ".",
              ],
              weights: [
                0.04677415,
                0.29458305,
                0.011936943,
                0.008032994,
                0.38938063,
                0.047417726,
                0.120079845,
                0.170149,
                0.0025844069,
                0.03472976,
                0.0127129555,
                0.037450064,
                0.015882954,
                0.056345705,
                0.0074032783,
                0.010993043,
                0.030865764,
                0.06438874,
                0.0123028075,
                0.00983054,
                0.00837995,
                0.07668897,
              ],
            },
          ],
          scores: [
            0.7238220572471619,
            0.2483256608247757,
            0.027852382510900497,
          ],
          subtokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
          ],
          tokens: [
            "[CLS]",
            "we",
            "arrived",
            "about",
            "midday",
            "and",
            "there",
            "was",
            "a",
            "queue",
            "to",
            "check",
            "in",
            ",",
            "which",
            "seemed",
            "to",
            "take",
            "ages",
            "to",
            "go",
            "down",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            "We arrived about midday and there was a queue to check in, which seemed to take ages to go down.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "our",
              "check",
              "is",
              "aws",
              "fairly",
              "quick",
              "but",
              "not",
              "sure",
              "why",
              "others",
              "in",
              "front",
              "of",
              "us",
              "took",
              "so",
              "long",
              ".",
            ],
            comeFrom: [
              0.2173344,
              1.0,
              0.06167618,
              0.081955574,
              0.03894827,
              0.09120732,
              0.0035674532,
              0.010112209,
              0.02371411,
              0.013889293,
              0.20672297,
              0.0443469,
              0.017097937,
              0.004000873,
              0.26256996,
              0.108541586,
              0.014276596,
              0.013869012,
              0.15427886,
            ],
            lookAt: [
              0.1908047,
              1.0,
              0.5214061,
              0.2550409,
              0.090597436,
              0.020985914,
              0.43178436,
              0.09210992,
              0.0153802335,
              0.021807257,
              0.38509378,
              0.3299231,
              0.18232512,
              0.28054246,
              0.03720262,
              0.6604974,
              0.12841289,
              0.5609813,
              0.4047434,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.022266034,
                0.005662268,
                0.043108124,
                0.037966248,
                0.05665369,
                0.02885568,
                0.1030771,
                0.0768457,
                1.0,
                0.11755213,
                0.03558493,
                0.008478332,
                0.011216878,
                0.007843682,
                0.006829417,
                0.08405818,
                0.058178857,
                0.14008595,
                0.059818767,
              ],
            },
            {
              impact: -0.6401447,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.047933552,
                0.069553114,
                0.035262853,
                0.03204985,
                0.029607119,
                0.391824,
                0.15620695,
                0.3409296,
                0.19417842,
                0.029947072,
                0.010451416,
                0.029808512,
                0.034400497,
                0.0052377656,
                0.01720509,
                0.0030310198,
                0.033787616,
                0.05347609,
                0.14101589,
              ],
            },
            {
              impact: -0.5685963,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.04121924,
                0.058977917,
                0.019595414,
                0.27577737,
                0.3374969,
                0.15884933,
                0.21171421,
                0.024560323,
                9.313738e-4,
                0.037774608,
                0.0032548893,
                0.009173661,
                0.010174694,
                0.009134104,
                0.018002339,
                0.06478401,
                0.019388082,
                0.0011852067,
                0.10115626,
              ],
            },
            {
              impact: 0.38412347,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                9.38139e-4,
                0.029611262,
                0.0042383024,
                0.0047163363,
                0.009189618,
                0.21785907,
                0.018699942,
                0.2420715,
                0.027801177,
                0.029708227,
                0.022665353,
                0.027587103,
                0.031338006,
                0.005022025,
                0.011396392,
                0.23899569,
                0.12958518,
                0.045128718,
                0.06125134,
              ],
            },
            {
              impact: 0.33359262,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.026780635,
                0.49775845,
                0.24199562,
                0.17484088,
                0.081788905,
                0.375102,
                0.01563157,
                0.001450811,
                0.011119453,
                0.032850333,
                0.04144203,
                4.330387e-4,
                0.060134243,
                0.029234253,
                0.055573545,
                0.023724657,
                0.02780256,
                0.057222646,
                0.03911315,
              ],
            },
            {
              impact: -0.31340036,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.21465662,
                0.5886439,
                0.21542577,
                0.15259774,
                0.115302816,
                0.015416996,
                0.027898118,
                0.028183255,
                0.02728918,
                0.038934935,
                0.044770222,
                0.007331576,
                0.07802316,
                0.002070578,
                0.023724737,
                0.031976834,
                0.009292129,
                0.038495243,
                0.07588372,
              ],
            },
            {
              impact: 0.30190477,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.14299667,
                0.29699975,
                0.17619826,
                0.26994604,
                0.35157594,
                0.15500028,
                0.086224064,
                0.03615323,
                0.046126727,
                0.026838245,
                0.002814992,
                0.0029967485,
                0.056244723,
                0.014544321,
                0.014613818,
                0.063963115,
                0.0021848283,
                0.0090561295,
                0.029862756,
              ],
            },
            {
              impact: 0.25154552,
              tokens: [
                "our",
                "check",
                "is",
                "aws",
                "fairly",
                "quick",
                "but",
                "not",
                "sure",
                "why",
                "others",
                "in",
                "front",
                "of",
                "us",
                "took",
                "so",
                "long",
                ".",
              ],
              weights: [
                0.074435346,
                0.12493016,
                0.27579707,
                0.04752326,
                0.047629368,
                0.1433392,
                0.011955489,
                0.03508452,
                0.009857404,
                0.023647908,
                0.044642568,
                0.0062853247,
                0.041969147,
                0.005133865,
                0.04152596,
                0.015692085,
                0.0026629316,
                0.028482419,
                0.022439312,
              ],
            },
          ],
          scores: [0.6738105416297913, 0.09741438925266266, 0.2287750244140625],
          subtokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aw",
            "##s",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "check",
            "is",
            "aws",
            "fairly",
            "quick",
            "but",
            "not",
            "sure",
            "why",
            "others",
            "in",
            "front",
            "of",
            "us",
            "took",
            "so",
            "long",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            "Our check is aws fairly quick but not sure why others in front of us took so long.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7, 8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "our",
              "room",
              "was",
              '"',
              "ready",
              '"',
              "althought",
              "the",
              "sofa",
              "bed",
              "for",
              "our",
              "daughter",
              "hadn",
              "'",
              "t",
              "been",
              "made",
              ".",
            ],
            comeFrom: [
              0.012189669,
              0.12045878,
              0.2221798,
              0.0073842797,
              0.03197585,
              0.102791704,
              0.1549731,
              0.248038,
              1.0,
              0.07562435,
              0.08007059,
              0.014011939,
              0.3645271,
              0.08467662,
              0.11676388,
              0.028971856,
              0.0060823974,
              0.06516105,
              0.5845516,
            ],
            lookAt: [
              0.061992954,
              0.29229203,
              0.77350473,
              0.56378263,
              0.018630607,
              0.37048298,
              0.10838085,
              0.36829603,
              1.0,
              0.18800913,
              0.1726606,
              0.062806524,
              0.082961686,
              0.20525351,
              0.11604348,
              0.24610811,
              0.25642192,
              0.22165719,
              0.9085669,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.101687014,
                0.2807651,
                0.10076311,
                0.09178059,
                0.18994437,
                0.280924,
                0.07036131,
                0.04064465,
                0.22643864,
                0.2811706,
                0.43065292,
                9.621621e-4,
                0.0053485413,
                0.98627585,
                0.039767846,
                0.30385217,
                0.042098347,
                0.00896944,
                0.09449259,
              ],
            },
            {
              impact: -0.85665506,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.6013766,
                0.5051798,
                0.123723105,
                0.27835822,
                0.5077468,
                0.030401176,
                0.07096687,
                0.005722242,
                0.0079501765,
                0.023885231,
                0.005631913,
                0.036731306,
                0.020319032,
                0.20240577,
                0.023156531,
                0.12994322,
                0.045144584,
                0.009481214,
                0.042998083,
              ],
            },
            {
              impact: 0.46040508,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.0054546595,
                0.033018354,
                0.030353006,
                0.022554254,
                0.035276815,
                0.024133448,
                0.0030563946,
                0.027790423,
                0.006563893,
                0.041326087,
                0.02773504,
                0.008958174,
                0.045376632,
                0.07345649,
                8.39672e-4,
                0.09901171,
                0.013157953,
                0.011549372,
                0.09206573,
              ],
            },
            {
              impact: 0.23104797,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.123441376,
                0.362752,
                0.05370787,
                0.027253915,
                0.09282634,
                0.06106847,
                0.011504175,
                0.06221475,
                0.04929281,
                0.01643936,
                0.021314867,
                0.125091,
                0.10484338,
                0.051658083,
                2.6183258e-4,
                0.0032932458,
                0.016108409,
                0.0019850149,
                0.0077199545,
              ],
            },
            {
              impact: 0.22561912,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.43220276,
                0.2110854,
                0.022114497,
                0.009035192,
                0.09205779,
                0.12672247,
                0.051031012,
                0.11097103,
                0.08627987,
                0.24874339,
                0.09365764,
                0.06900352,
                0.13312426,
                0.02431582,
                0.002814643,
                0.0028535158,
                0.02394237,
                0.039823644,
                0.026463985,
              ],
            },
            {
              impact: 0.22155888,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.032746654,
                0.10415759,
                0.047444023,
                0.004090333,
                0.07193622,
                0.022669028,
                0.006954314,
                0.06139776,
                0.085988626,
                0.022500537,
                0.10719298,
                0.039139517,
                0.0032176136,
                0.09110662,
                0.0013696635,
                0.013062091,
                0.010959558,
                0.052133486,
                0.055524245,
              ],
            },
            {
              impact: 0.21826185,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.023929648,
                0.12948371,
                0.0078049735,
                0.041449476,
                0.0079051,
                0.019913072,
                0.025517102,
                0.14005429,
                0.10518947,
                0.19681963,
                0.092583425,
                0.01924336,
                0.0016881607,
                0.017125197,
                0.0024952462,
                0.009582357,
                0.0053798086,
                0.07345588,
                0.03264005,
              ],
            },
            {
              impact: 0.19134532,
              tokens: [
                "our",
                "room",
                "was",
                '"',
                "ready",
                '"',
                "althought",
                "the",
                "sofa",
                "bed",
                "for",
                "our",
                "daughter",
                "hadn",
                "'",
                "t",
                "been",
                "made",
                ".",
              ],
              weights: [
                0.013452682,
                0.06875929,
                0.05794705,
                0.01925563,
                0.04233328,
                0.101985574,
                0.06702866,
                0.05135601,
                0.020781724,
                0.0142025305,
                0.0016381447,
                0.012506435,
                0.009910534,
                0.021281928,
                0.031670257,
                0.0053354064,
                0.009034425,
                0.0047437134,
                0.04087502,
              ],
            },
          ],
          scores: [0.7115121483802795, 0.182562455534935, 0.10592545568943024],
          subtokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "although",
            "##t",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
          ],
          tokens: [
            "[CLS]",
            "our",
            "room",
            "was",
            '"',
            "ready",
            '"',
            "althought",
            "the",
            "sofa",
            "bed",
            "for",
            "our",
            "daughter",
            "hadn",
            "'",
            "t",
            "been",
            "made",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            'Our room was "ready" althought the sofa bed for our daughter hadn\'t been made.',
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11, 12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "we",
              "went",
              "out",
              "for",
              "the",
              "day",
              "and",
              "arrived",
              "back",
              "about",
              "7pm",
              "and",
              "the",
              "sofa",
              "bed",
              "still",
              "wasn",
              "'",
              "t",
              "made",
              "!",
              "!",
              "!",
            ],
            comeFrom: [
              0.023606423,
              0.009551917,
              0.007720844,
              0.11115979,
              0.0836392,
              0.009302991,
              0.05149819,
              0.018872961,
              0.010354076,
              0.008559645,
              0.42573497,
              0.012334519,
              0.009207216,
              1.0,
              0.15794717,
              0.0039832625,
              0.160545,
              0.0025631604,
              0.005281975,
              0.055481195,
              0.010773169,
              0.012742339,
              0.14208122,
            ],
            lookAt: [
              0.024345297,
              0.14957279,
              0.022274056,
              0.03318585,
              0.042793334,
              0.12803164,
              0.054158643,
              0.025871675,
              0.009905926,
              0.03901714,
              0.5104276,
              0.154211,
              0.2760848,
              1.0,
              0.14617781,
              0.19852653,
              0.31896687,
              0.05480463,
              0.3122467,
              0.06296361,
              0.0679102,
              0.06221504,
              0.56369,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.037723366,
                0.047149695,
                0.018866498,
                0.04533738,
                0.010406697,
                0.026037028,
                0.055597324,
                0.08668058,
                0.05270314,
                0.04286369,
                0.04229697,
                0.033086173,
                0.10625235,
                0.0031615398,
                0.027653085,
                0.081933714,
                0.03992862,
                0.005545242,
                0.073766425,
                0.013402147,
                0.0039234143,
                0.25504836,
                0.03742042,
              ],
            },
            {
              impact: -0.94990873,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.04376519,
                0.0019439795,
                0.0013135107,
                0.006273267,
                0.015553369,
                0.022958178,
                0.008275398,
                0.027477331,
                0.037556652,
                0.035609998,
                0.02299725,
                0.16287614,
                0.07982254,
                0.009410204,
                0.1303066,
                0.41507596,
                0.06309119,
                0.14320518,
                0.45535108,
                0.2235623,
                0.012395398,
                0.06834075,
                0.1823727,
              ],
            },
            {
              impact: -0.90401584,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.069293275,
                0.040997606,
                0.009849332,
                0.01650588,
                0.018952915,
                0.012293142,
                0.08613996,
                0.10952869,
                0.06905283,
                0.004831135,
                0.3094335,
                0.1886924,
                0.0018558112,
                0.053438485,
                0.1624189,
                0.13800544,
                0.02181478,
                0.01437169,
                0.025988983,
                0.07424849,
                0.099998616,
                0.096007414,
                0.12919556,
              ],
            },
            {
              impact: -0.7615125,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.035946675,
                0.021802694,
                0.006175131,
                0.012285953,
                0.024464961,
                0.0016005944,
                0.011678273,
                0.0026025337,
                0.022162452,
                0.014392645,
                0.02468305,
                0.1908503,
                0.078689605,
                0.046690583,
                0.32091975,
                1.0,
                0.1734017,
                0.08371622,
                0.44214913,
                0.23332648,
                0.1344601,
                0.15418051,
                0.42381477,
              ],
            },
            {
              impact: -0.5154632,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.036170796,
                0.027633844,
                0.014409454,
                0.016966939,
                0.005627741,
                0.011939766,
                0.02818687,
                0.012662395,
                0.09157396,
                0.007117028,
                0.07991981,
                0.45316276,
                0.17217858,
                0.013483962,
                0.16663933,
                0.09167638,
                0.43549636,
                0.13733192,
                0.3739003,
                0.09007416,
                0.11612749,
                0.12842539,
                0.33162665,
              ],
            },
            {
              impact: 0.386655,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.03537961,
                0.030752746,
                0.010505527,
                0.013614412,
                0.0010815237,
                0.0260663,
                0.12621218,
                0.10406837,
                0.2243436,
                0.031357028,
                0.19946209,
                0.13756338,
                0.022066038,
                0.04732286,
                0.08016591,
                0.04194696,
                0.053297553,
                0.0035079124,
                3.776868e-4,
                0.01933821,
                0.020098994,
                0.008689589,
                0.022079078,
              ],
            },
            {
              impact: -0.30810636,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0036494473,
                0.015581535,
                0.009558999,
                0.016755959,
                0.007931871,
                0.022408588,
                0.019394936,
                0.03860661,
                0.0051778513,
                0.0010601492,
                0.04756135,
                0.043851968,
                0.008953226,
                0.020007854,
                0.014190954,
                0.010135155,
                0.055332154,
                0.015761185,
                0.064139746,
                0.014491302,
                0.04507835,
                0.07312283,
                0.28812906,
              ],
            },
            {
              impact: -0.18998675,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.0074338536,
                0.00729986,
                0.006294913,
                0.011381737,
                0.013907074,
                0.01443258,
                0.020562561,
                0.018421696,
                0.004613333,
                0.011234751,
                0.037779663,
                0.04235172,
                0.00825547,
                0.019102257,
                8.4279716e-4,
                0.033930197,
                0.019158874,
                0.007192108,
                0.010264565,
                0.0024149811,
                0.06179837,
                0.04113277,
                0.023099191,
              ],
            },
            {
              impact: 0.17557965,
              tokens: [
                "we",
                "went",
                "out",
                "for",
                "the",
                "day",
                "and",
                "arrived",
                "back",
                "about",
                "7pm",
                "and",
                "the",
                "sofa",
                "bed",
                "still",
                "wasn",
                "'",
                "t",
                "made",
                "!",
                "!",
                "!",
              ],
              weights: [
                0.091577,
                0.08067727,
                0.021459818,
                0.011958252,
                0.0042904234,
                0.05542387,
                0.037408892,
                0.031058649,
                0.27717075,
                0.07115799,
                0.055995747,
                0.18393713,
                0.025110539,
                0.019922445,
                0.026357953,
                0.0437376,
                0.013272254,
                0.012093399,
                0.036169596,
                0.01875371,
                0.048295308,
                0.048834633,
                0.021321766,
              ],
            },
          ],
          scores: [0.5115891098976135, 0.4675746560096741, 0.02083621360361576],
          subtokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7",
            "##pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
          ],
          tokens: [
            "[CLS]",
            "we",
            "went",
            "out",
            "for",
            "the",
            "day",
            "and",
            "arrived",
            "back",
            "about",
            "7pm",
            "and",
            "the",
            "sofa",
            "bed",
            "still",
            "wasn",
            "'",
            "t",
            "made",
            "!",
            "!",
            "!",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            "We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!!",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4, 5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13, 14, 15],
            [16],
            [17],
            [18],
            [19],
            [20],
            [21],
            [22],
            [23],
            [24],
            [25],
            [26],
            [27],
            [28],
            [29],
            [30],
            [31],
            [32],
            [33],
            [34],
            [35],
            [36],
            [37],
            [38],
            [39],
            [40],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "the",
              "location",
              "is",
              "sop",
              "on",
              ",",
              "a",
              "stones",
              "throw",
              "from",
              "the",
              "eiffel",
              "tower",
              "and",
              "if",
              "you",
              "get",
              "the",
              "le",
              "bus",
              "direct",
              "from",
              "the",
              "airport",
              ",",
              "the",
              "stop",
              "is",
              "also",
              "between",
              "tower",
              "and",
              "hotel",
              ".",
            ],
            comeFrom: [
              0.0168583,
              0.13581845,
              0.00645958,
              0.5803946,
              0.04607217,
              0.033995967,
              0.010944161,
              0.01757322,
              0.023972929,
              0.09437752,
              0.07167181,
              1.0,
              0.2562875,
              0.035802983,
              0.02063864,
              0.011213493,
              0.012336458,
              0.017072039,
              0.20485248,
              0.2540889,
              0.090075515,
              0.023890924,
              0.016006295,
              0.162972,
              0.031096574,
              0.028972866,
              0.21952529,
              0.049158603,
              0.0960997,
              0.103231676,
              0.30800557,
              0.116874024,
              0.12431858,
              0.24668185,
            ],
            lookAt: [
              0.7019352,
              0.24715564,
              0.19270287,
              0.58308876,
              0.15562727,
              0.14973183,
              0.5025072,
              0.45508188,
              0.06662167,
              0.05771541,
              0.2281046,
              0.07434329,
              0.19539745,
              1.0,
              0.11453196,
              0.040322673,
              6.97334e-4,
              0.06563873,
              0.021461343,
              0.028637169,
              0.09347386,
              0.025897572,
              0.009657287,
              0.02419496,
              0.15758152,
              0.072949015,
              0.08465812,
              0.07778569,
              0.12292109,
              0.005295361,
              0.24748822,
              0.040811963,
              0.17398156,
              0.8353148,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: -1.0,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.014083435,
                0.020941665,
                0.21891597,
                0.12837192,
                0.005980868,
                0.19963288,
                0.26699832,
                0.37559757,
                0.4065641,
                0.40921962,
                0.23990445,
                0.1502959,
                0.37328488,
                0.2612977,
                0.24755801,
                0.045008216,
                0.013227136,
                0.05834543,
                0.008876825,
                0.017254645,
                0.115379445,
                0.044555385,
                0.0072135883,
                0.054836523,
                0.1324666,
                0.16304247,
                0.1894645,
                0.29390252,
                0.023187902,
                0.21549927,
                0.058436204,
                0.016856289,
                0.063157134,
                0.22197796,
              ],
            },
            {
              impact: 0.3975868,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.026317703,
                0.18287109,
                0.79628754,
                0.48234448,
                0.671826,
                0.24849759,
                0.034376908,
                0.08767521,
                0.039980028,
                0.07145846,
                0.047164258,
                0.36879402,
                0.108326405,
                0.04068953,
                0.0016503624,
                0.005143363,
                0.008903832,
                0.013545877,
                0.002525555,
                0.07748117,
                0.013775283,
                0.015608054,
                0.019455818,
                0.084289454,
                0.03737194,
                0.0070391283,
                0.013290011,
                0.01869414,
                0.01961302,
                0.030655947,
                0.094427675,
                0.012985424,
                0.124207176,
                0.036012504,
              ],
            },
            {
              impact: -0.34613466,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.07911816,
                0.013112922,
                0.029415779,
                0.011518571,
                0.02088508,
                0.028752064,
                3.5325516e-4,
                0.01416767,
                0.0047524604,
                0.0017260909,
                0.013213657,
                0.02273334,
                0.01695906,
                0.065319255,
                0.012412447,
                0.007438459,
                0.002752511,
                0.016216101,
                0.02344937,
                0.011804369,
                0.0064793467,
                5.142442e-4,
                0.0010169041,
                0.0020375645,
                0.037298393,
                0.02706372,
                0.024728835,
                0.025177117,
                0.007293199,
                0.048437353,
                0.1911908,
                0.034743737,
                0.061205104,
                0.0019259886,
              ],
            },
            {
              impact: -0.34234008,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.07522692,
                0.43882436,
                0.29830918,
                0.05054139,
                0.045586336,
                0.12724362,
                0.093223654,
                0.14348729,
                0.52180225,
                0.05383651,
                0.007782133,
                0.110132866,
                0.11638196,
                0.13108996,
                0.0028181276,
                0.029115995,
                0.025301551,
                0.023040809,
                0.012879478,
                0.0018358647,
                0.0063342224,
                0.022718575,
                0.0071129347,
                0.017426847,
                0.05987798,
                0.033467248,
                0.003818213,
                0.019170178,
                0.021527015,
                0.003861108,
                0.029431729,
                0.00487443,
                0.047191385,
                0.05571602,
              ],
            },
            {
              impact: 0.34160268,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.009596624,
                0.019997682,
                0.0014254848,
                0.010682036,
                0.0012526313,
                1.2059224e-4,
                0.008461743,
                0.008680237,
                0.0034786558,
                0.026912084,
                1.5003158e-4,
                0.0021771358,
                0.026177302,
                0.027027259,
                0.12490835,
                0.0026266642,
                0.018178541,
                0.045439407,
                0.017072525,
                0.05023397,
                0.09511455,
                0.083351836,
                0.037706617,
                0.032940984,
                0.032394562,
                0.05292742,
                0.26435933,
                0.4049142,
                0.038939506,
                0.06851953,
                0.09685727,
                0.06859941,
                0.39249545,
                0.061607953,
              ],
            },
            {
              impact: 0.2777678,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.009660289,
                0.004674593,
                0.1344731,
                0.005131194,
                0.004082752,
                0.0016631038,
                0.010644373,
                0.014691787,
                0.07051084,
                0.025898488,
                0.001331952,
                0.005959653,
                0.0067371884,
                0.088856265,
                5.326036e-4,
                0.038459606,
                0.009518368,
                0.010143328,
                0.0050345412,
                0.0029367316,
                0.011874971,
                0.013352487,
                0.0032068565,
                0.01589926,
                0.012530759,
                0.04359254,
                0.20232426,
                0.050150465,
                0.20625617,
                0.44506398,
                0.07274691,
                0.00239711,
                0.017688436,
                0.03479565,
              ],
            },
            {
              impact: 0.2283239,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                3.5171118e-4,
                0.009061377,
                0.010247005,
                0.0039522583,
                0.033267282,
                0.021248024,
                0.0042260643,
                0.014019331,
                0.023361327,
                0.011706194,
                0.0105938185,
                0.002662371,
                0.08804788,
                0.032580256,
                0.03720094,
                0.021005105,
                0.04852705,
                0.01060365,
                0.052062143,
                0.64512694,
                0.11911596,
                0.077784345,
                0.011752963,
                0.015993554,
                0.08370319,
                0.16799001,
                0.156696,
                0.17619185,
                0.0854301,
                0.1106235,
                0.033452056,
                0.001678869,
                0.0012567533,
                0.017351458,
              ],
            },
            {
              impact: -0.20248301,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.018569933,
                0.09951777,
                0.027323639,
                0.010993537,
                0.023561073,
                0.1712316,
                0.11296183,
                1.0,
                0.17783941,
                0.13945875,
                0.05179084,
                0.037209928,
                0.08943406,
                0.0051753772,
                0.054338664,
                0.013289522,
                0.020652857,
                0.015020295,
                0.053157534,
                0.030671172,
                0.01733964,
                0.034086797,
                0.015179403,
                0.05185101,
                0.018048948,
                0.004158486,
                0.015260999,
                0.0015870439,
                0.018286688,
                0.02589275,
                0.043207314,
                0.030010903,
                0.0101913465,
                9.31237e-4,
              ],
            },
            {
              impact: 0.17812735,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.032575358,
                0.02990747,
                0.03375534,
                0.02425182,
                5.459916e-4,
                0.032542113,
                0.0042064185,
                0.1308465,
                0.119075775,
                0.02955801,
                0.32376835,
                0.06955615,
                0.54097295,
                0.0025313697,
                0.016151443,
                0.016156878,
                0.0011414152,
                0.016463682,
                0.08180172,
                0.0064378665,
                0.01513486,
                0.042289425,
                0.005015596,
                0.037000384,
                0.022019425,
                9.938986e-4,
                7.769418e-4,
                0.0151113635,
                0.007353888,
                0.017849198,
                0.049465846,
                0.024940075,
                0.04930073,
                0.03783237,
              ],
            },
            {
              impact: 0.15780792,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.01881259,
                0.026627004,
                0.005886836,
                0.01841825,
                1.0456837e-4,
                0.008167776,
                0.005962227,
                0.014605959,
                0.007503869,
                0.01929606,
                0.014562916,
                0.021298066,
                0.017613985,
                0.026523601,
                0.084283985,
                0.21239442,
                0.28545317,
                0.015496776,
                0.038467295,
                0.018194593,
                0.055871066,
                0.053416774,
                0.021988897,
                0.05742046,
                0.034016363,
                0.0069934786,
                0.07569438,
                0.049680956,
                0.026427966,
                0.029510777,
                0.0028256152,
                0.0013598179,
                0.03264164,
                0.017226573,
              ],
            },
            {
              impact: -0.15469483,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.3261186,
                0.17004427,
                0.4486679,
                0.1457009,
                0.042735033,
                0.086093456,
                0.05438209,
                0.110837966,
                0.09866761,
                0.25331968,
                0.1095079,
                0.072993964,
                0.0717347,
                0.07037172,
                0.06775533,
                0.006878541,
                0.0064146533,
                0.02221053,
                0.01274379,
                0.025180083,
                0.00307654,
                0.0023048297,
                0.011548312,
                0.016354721,
                0.012643517,
                0.04014158,
                0.04136516,
                0.055814825,
                0.013370482,
                0.06097117,
                0.059838172,
                2.409812e-4,
                0.03345016,
                0.07023522,
              ],
            },
            {
              impact: -0.13880807,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.07491907,
                0.0119986255,
                0.07178104,
                0.039950427,
                0.025881454,
                0.012142023,
                0.0017470282,
                0.01802747,
                0.051600855,
                0.0141667565,
                0.027287535,
                0.0673601,
                0.033490848,
                0.020593546,
                0.01931172,
                0.0022062275,
                0.015671443,
                0.007839552,
                0.0048705735,
                0.10659906,
                0.024159811,
                0.03652365,
                0.0063904095,
                0.12400598,
                0.017740745,
                0.0075091855,
                0.12892205,
                0.012181688,
                0.053548347,
                0.06457438,
                0.0061339256,
                0.008706708,
                0.14123854,
                0.035276502,
              ],
            },
            {
              impact: 0.11253777,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.00207312,
                0.033854656,
                0.0063692727,
                0.016010102,
                0.0013780346,
                0.0022722017,
                0.0015176339,
                0.011877798,
                0.0040274663,
                9.032546e-4,
                0.01927714,
                0.027311167,
                0.020036597,
                0.007893116,
                0.0061403993,
                0.0123542845,
                0.007405291,
                0.004041622,
                0.029169051,
                0.006202962,
                0.057881556,
                0.07460173,
                0.003123955,
                0.0014316282,
                0.089826286,
                0.004237839,
                0.0086239735,
                0.0140401255,
                0.017777935,
                0.01884403,
                0.0123727005,
                7.299001e-4,
                0.05554902,
                0.017952645,
              ],
            },
            {
              impact: -0.10801137,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.031203896,
                0.031336907,
                0.05975536,
                0.02435333,
                0.11761703,
                0.35173112,
                0.16035706,
                0.18606694,
                0.24369031,
                0.080013335,
                0.0103656575,
                0.009576708,
                0.03867205,
                0.034229565,
                0.023314312,
                0.0015122304,
                0.00916995,
                0.04965613,
                0.03432648,
                0.09446369,
                0.057645936,
                0.07712332,
                0.026453748,
                0.11258943,
                0.042660963,
                0.022531724,
                0.018238772,
                0.0013525835,
                0.009971194,
                0.029329201,
                0.013110492,
                0.04312061,
                0.021331277,
                0.008300635,
              ],
            },
            {
              impact: -0.09485804,
              tokens: [
                "the",
                "location",
                "is",
                "sop",
                "on",
                ",",
                "a",
                "stones",
                "throw",
                "from",
                "the",
                "eiffel",
                "tower",
                "and",
                "if",
                "you",
                "get",
                "the",
                "le",
                "bus",
                "direct",
                "from",
                "the",
                "airport",
                ",",
                "the",
                "stop",
                "is",
                "also",
                "between",
                "tower",
                "and",
                "hotel",
                ".",
              ],
              weights: [
                0.007960452,
                0.2310078,
                0.24773274,
                0.33990002,
                0.0044250097,
                0.054225497,
                0.13219483,
                0.07832886,
                0.07843304,
                0.08667555,
                0.004606716,
                0.034286037,
                0.07539075,
                0.009605708,
                0.019808555,
                0.010986192,
                0.008036194,
                0.006813793,
                0.025703967,
                0.0019269898,
                0.014938735,
                0.027224747,
                0.0036683201,
                0.019270185,
                0.027552973,
                0.0028572886,
                0.009253679,
                0.0042964574,
                0.01329697,
                0.0138623705,
                6.8188645e-4,
                0.004603343,
                0.0064242454,
                0.014186515,
              ],
            },
          ],
          scores: [0.6669486165046692, 0.07929836958646774, 0.2537529766559601],
          subtokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "so",
            "##p",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "e",
            "##iff",
            "##el",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "neutral",
          textTokens: [
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
          ],
          tokens: [
            "[CLS]",
            "the",
            "location",
            "is",
            "sop",
            "on",
            ",",
            "a",
            "stones",
            "throw",
            "from",
            "the",
            "eiffel",
            "tower",
            "and",
            "if",
            "you",
            "get",
            "the",
            "le",
            "bus",
            "direct",
            "from",
            "the",
            "airport",
            ",",
            "the",
            "stop",
            "is",
            "also",
            "between",
            "tower",
            "and",
            "hotel",
            ".",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            "The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel.",
        },
        {
          alignment: [
            [0],
            [1],
            [2],
            [3],
            [4],
            [5],
            [6],
            [7],
            [8],
            [9],
            [10],
            [11],
            [12],
            [13],
            [14],
            [15],
            [16],
            [17],
            [18],
            [19],
            [20, 21],
            [22],
            [23],
            [24],
          ],
          aspect: "breakfast",
          aspectRepresentation: {
            tokens: [
              "breakfast",
              "was",
              "good",
              "enough",
              ",",
              "plenty",
              "of",
              "choice",
              "so",
              "you",
              "can",
              "'",
              "t",
              "go",
              "hungry",
              "unless",
              "you",
              "'",
              "re",
              "fussy",
            ],
            comeFrom: [
              1.0,
              0.30089372,
              0.20793003,
              0.06744072,
              0.006885311,
              0.019852512,
              0.13304202,
              0.18740162,
              0.03201734,
              0.021103505,
              0.03640202,
              0.007700381,
              0.026439335,
              0.031223161,
              0.010713875,
              0.037707523,
              0.0031418055,
              0.013137274,
              7.985376e-4,
              0.16584924,
            ],
            lookAt: [
              0.46561688,
              0.16017824,
              0.21161772,
              0.6406385,
              0.36355,
              0.062451344,
              0.0021063106,
              0.018152311,
              0.0077306055,
              0.008536025,
              0.0017812123,
              1.5193151e-4,
              0.004088915,
              0.0042577973,
              0.079493344,
              0.020425541,
              0.00395148,
              0.027433,
              0.06204449,
              1.0,
            ],
          },
          aspectTokens: ["breakfast"],
          patterns: [
            {
              impact: 1.0,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.9715693,
                0.087376535,
                0.11506605,
                0.99995947,
                0.05842521,
                0.075154305,
                0.006816506,
                0.036662746,
                0.023042724,
                0.023006922,
                0.0054980074,
                0.0036738564,
                0.004009219,
                0.007250792,
                0.05257966,
                0.0017576979,
                0.017258475,
                0.023744546,
                0.025308095,
                0.026233299,
              ],
            },
            {
              impact: -0.47021022,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.058832396,
                0.08426836,
                0.027204677,
                0.062626235,
                0.01691251,
                0.124839395,
                0.037720654,
                0.0663051,
                0.112267375,
                0.053043216,
                0.10554504,
                0.06829508,
                0.1572823,
                0.097416215,
                0.17017053,
                0.2497031,
                0.028036397,
                0.08257402,
                0.19429606,
                0.40415996,
              ],
            },
            {
              impact: 0.32588443,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.54564935,
                0.07281422,
                0.118259124,
                0.2358652,
                0.0014422953,
                0.044137552,
                0.0014782255,
                0.025365952,
                0.0028442934,
                0.026786022,
                0.0017140612,
                0.0015865603,
                0.0021475912,
                0.002137551,
                0.0018154774,
                0.0058821435,
                0.012063687,
                0.010435497,
                0.011129937,
                0.010723647,
              ],
            },
            {
              impact: -0.30725235,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.3072336,
                0.17608288,
                1.0,
                0.5672823,
                0.41881692,
                0.4743491,
                0.061561342,
                0.2363612,
                0.22933716,
                0.10000071,
                0.1988532,
                0.028893236,
                0.05541348,
                0.034218553,
                0.084431514,
                0.09914926,
                0.026214907,
                0.0037684762,
                0.015186781,
                0.005273787,
              ],
            },
            {
              impact: 0.15005547,
              tokens: [
                "breakfast",
                "was",
                "good",
                "enough",
                ",",
                "plenty",
                "of",
                "choice",
                "so",
                "you",
                "can",
                "'",
                "t",
                "go",
                "hungry",
                "unless",
                "you",
                "'",
                "re",
                "fussy",
              ],
              weights: [
                0.27005672,
                0.06307846,
                0.13210502,
                0.106598854,
                0.18283074,
                0.062405746,
                0.020939615,
                0.098134995,
                0.17758276,
                0.01198176,
                0.034626525,
                0.002206228,
                4.0544829e-4,
                0.016765885,
                0.013645851,
                0.0055505936,
                0.004610558,
                0.006364692,
                0.00875291,
                0.004325428,
              ],
            },
          ],
          scores: [
            0.04030167683959007,
            0.016151800751686096,
            0.9435465335845947,
          ],
          subtokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fuss",
            "##y",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          sentiment: "positive",
          textTokens: [
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
          ],
          tokens: [
            "[CLS]",
            "breakfast",
            "was",
            "good",
            "enough",
            ",",
            "plenty",
            "of",
            "choice",
            "so",
            "you",
            "can",
            "'",
            "t",
            "go",
            "hungry",
            "unless",
            "you",
            "'",
            "re",
            "fussy",
            "[SEP]",
            "breakfast",
            "[SEP]",
          ],
          text:
            "Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
        },
      ],
      scores: [0.3757074146562222, 0.20685919323870136, 0.4174333921050764],
      sentiment: "positive",
      text:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
    },
  ],
  text:
    "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you can't go hungry unless you're fussy",
}
